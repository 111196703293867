import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';
import PaymentIcon from '@material-ui/icons/Payment';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from 'antd';
import moment from 'moment';
import firebase from '../../../Firebase';


import '../../../../node_modules/antd/dist/antd.css';

const filter = createFilterOptions();

const useStyles = makeStyles(theme => ({
    appBar: {
        position: 'relative',
        background: "linear-gradient(to right , #438BEC, #57CBF2)",
        boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
    },
    root: {
        '& .MuiSelect-icon': {
            float: 'left',
            direction: 'ltr',
        },
        '& .MuiInputLabel-icon': {
            float: 'left',
            direction: 'ltr',
        },
        '& .MuiSelect-select': {
            direction: 'ltr',
            float: 'left',
            backgroundColor: 'transparent'
        },
        '& MuiSelect-selectMenu': {
            direction: 'ltr'
        },
        '.MuiTextField-root': {
            borderSize: 0,
            border: 1
        },
        '& .MuiAutocomplete-tag': {
            marginLeft: 90
        },
        deleteIcon: {
            marginLeft: 20
        }
    },
    content: {
        padding: 30
    },
    title: {
        fontFamily: "'Varela Round', sans-serif",
        float: 'left'
    },
    publish: {
        backgroundColor: 'white',
        color: 'black',
        padding: 8,
        borderRadius: 5
    },
    textField: {
        marginTop: 15,
        direction: 'ltr',
    },
    pageTitle: {
        textAlign: 'center',
        fontFamily: "'Varela Round', sans-serif",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
    errorMessage: {
        width: '100%',
        textAlign: 'center',
        float: 'center',
        color: 'red',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 10,
        direction: 'ltr',
        marginBottom: 0
    },
    formControl: {
        minWidth: 120,
        direction: 'ltr',
        width: '100%'
    },
    publishButton: {
        margin: `${theme.spacing(1)}px 0`,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
    cancelAddItemButton: {
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 25px -8px rgba(0, 0, 0, 0.5)',
        borderRadius: 50,
        fontFamily: "'Varela Round', sans-serif",
        color: '#c21ed6'
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const names = [
    'הריון ולקראת לידה',
    'הנקה',
    'מוזיקה ותנועה',
    'התפתחות הבייבי',
    'כושר ותזונה לאמא',
    'כיף עם הבייבי',
    'טיפים וכלים להורים',
    'תזונת תינוקות',
    'להכיר חברות חדשות'
];

const ageRangeArray = [
    { label: '0-3 חודשים', value: '0-3' },
    { label: '3-6 חודשים', value: '3-6' },
    { label: '6-9 חודשים', value: '6-9' },
    { label: '9-12 חודשים', value: '9-12' },
    { label: '12-24 חודשים', value: '12-24' },
    { label: '24+ חודשים', value: '24+' },
    { label: 'לאמא בהריון', value: 'הריון' },
    { label: 'לאמא ולבייבי', value: 'אמא ובייבי' },
    { label: 'לאמא', value: 'אמא' },
    { label: 'כולם', value: 'כולם' },
];


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
    return {
        fontWeight:
            tags.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function FullScreenDialog(props) {
    const classes = useStyles();
    const theme = useTheme();

    const [tags, setTags] = React.useState([]);
    const [ageRange, setAgeRange] = React.useState([]);
    const [errorTags, setErrorTags] = React.useState(false);
    const [errorDate, setErrorDate] = React.useState(false);
    const [errorAgeRange, setErrorAgeRange] = React.useState(false);
    const [errorImage, setErrorImage] = React.useState(false);
    const [errorLink, setErrorLink] = React.useState(false);
    const [errorText, setErrorText] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState(false);
    const [coverImage, setCoverImage] = React.useState(null);
    const [state, setState] = React.useState({ link: '', text: '', title: '' });
    const [date, setDate] = React.useState(null);

    const [withLink, setWithLink] = React.useState(false);
    const [formattedDate, setFormattedDate] = React.useState(null);


    const resetParams = () => {
        setTags([]);
        setAgeRange([]);
        setCoverImage(null);
        setState({ link: '' })
        setDate(null)
    }

    const handleChangeCheckbox = (event) => {
        if (event.target.name === 'withLink') {
            setWithLink(true)
        } else {
            setWithLink(false)
        }
    };

    const handleChange = (e, v) => {
        setTags(v)
    }

    const handleChangeAgeRange = event => {
        setAgeRange(event.target.value);
    };

    const publish = async () => {
        var allowToContinue = true
        setErrorTitle(false)
        setErrorTags(false)
        setErrorAgeRange(false)
        setErrorImage(false)
        setErrorText(false)
        // setErrorDescription(false)
        if (state.text === '') { setErrorText(true); allowToContinue = false }
        if (state.title === '') { setErrorTitle(true); allowToContinue = false }
        if (coverImage === null) { setErrorImage(true); allowToContinue = false }
        if (tags.length === 0) { setErrorTags(true); allowToContinue = false }
        if (ageRange.length === 0) { setErrorAgeRange(true); allowToContinue = false }
        // if (state.title === '') { setErrorTitle(true); allowToContinue = false }
        // if (state.link === '') { setErrorLink(true); allowToContinue = false }
        if (date === null) { setErrorDate(true); allowToContinue = false }

        if (allowToContinue) {
            console.log(date)
            // let timestamp = firebase.firestore.Timestamp.fromDate(date);
            var data = {
                title: state.title,
                description: state.text,
                link: state.link,
                interests: tags,
                ages: ageRange,
                promote: false,
                date: date,
                publish: false,
                status: 'approved',
                type: 'story'
            }

            console.log(data)
            saveDataInDatabase(data)
        }
    }

    const saveDataInDatabase = (data) => {
        props.main.setState({ adding: true })
        console.log(data)
        var form = new FormData();
        form.append('photos', coverImage, coverImage.name);

        const newData = JSON.stringify(data)

        form.append('data', newData);

        for (var key of form.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        // axios.post('http://localhost:8080/api/stories/create', form, {
        axios.post('https://jama-server.appspot.com/api/stories/create', form, {
            headers: {
                'content-type': 'multipart/form-data' // do not forget this 
            }
        }).then((response) => {
            props.main.setState({ adding: false, openNewStory: false })
            props.main.getStories()
            resetParams()
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const onChange = async e => {
        const files = Array.from(e.target.files)
        setCoverImage(files[0])
    }

    const onchangeDateTime = (e) => {
        // setDate(String(e._d))
        setFormattedDate(moment(e._d))
        setDate(firebase.firestore.Timestamp.fromDate(e._d))
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().startOf('day');
    }

    return (
        <div className={classes.root}>
            <Dialog fullScreen open={props.main.state.openNewStory} onClose={() => props.main.setState({ openNewStory: false })} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => props.main.setState({ openNewStory: false })} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" className={classes.pageTitle}>
                            העלאת סטורי
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <Grid container spacing={2} justify="center">
                        <Grid item sm={12} md={10} lg={8} xl={8}>

                            <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
                            <input className="textinput" type='file' id='single' onChange={onChange} style={{ paddingTop: 13, paddingRight: 20 }} />
                            {errorImage && (<div className={classes.errorMessage}>שדה חובה</div>)}

                            <div style={{ width: '100%' }}>
                                <FormControlLabel style={{ textAlign: 'right', direction: 'rtl', float: 'right' }}
                                    control={
                                        <Checkbox
                                            checked={withLink}
                                            onChange={(e) => handleChangeCheckbox(e)}
                                            name="withLink"
                                        />
                                    }
                                    label="עם לינק"
                                />
                            </div>
                            <div style={{ width: '100%' }}>
                                <FormControlLabel style={{ textAlign: 'right', direction: 'rtl', float: 'right' }}
                                    control={
                                        <Checkbox
                                            checked={!withLink}
                                            onChange={(e) => handleChangeCheckbox(e)}
                                            name="withoutLink"
                                        />
                                    }
                                    label="ללא לינק"
                                />
                            </div>
                            {withLink && (
                                <div>
                                    <div className={classes.inputTitle}>לינק:</div>
                                    <input className="textinput" onChange={(e) => setState({ ...state, link: e.target.value })} />
                                </div>
                            )}
                            <div className={classes.inputTitle}>כותרת</div>
                            <input className="textarea_box" onChange={(e) => setState({ ...state, title: e.target.value })} />
                            {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div className={classes.inputTitle}>טקסט</div>
                            <textarea className="textarea_box" onChange={(e) => setState({ ...state, text: e.target.value })} cols="40" rows="5"/>
                            {errorText && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div>
                                <div className={classes.inputTitle}>יום ושעה</div>
                                <DatePicker
                                    renderExtraFooter={() => <div style={{ height: 0 }}><div style={{ backgroundColor: 'white', width: '50%', position: 'relative', top: 10, height: 50 }}></div></div>}
                                    // minuteStep={15}
                                    format={'DD/MM/YYYY HH:mm'}
                                    hideDisabledOptions={true}
                                    placeholder="יש לבחור תאריך ושעה"
                                    onChange={onchangeDateTime}
                                    clearIcon={(<div></div>)}
                                    disabledDate={disabledDate}
                                    popupStyle={{ zIndex: 99999 }}
                                    showToday={false}
                                    showNow={false}
                                    value={formattedDate}
                                    id='date-and-time'
                                    disabledHours={() => [0, 1, 2, 3, 4, 5]}
                                    className="textinput"
                                    style={{ borderRadius: 25, border: '1px solid #438BEC' }}
                                    showTime />
                            </div>
                            {errorDate && (<div className={classes.errorMessage}>שדה חובה</div>)}


                            <div style={{ width: '100%' }}></div>
                            <div className={classes.inputTitle}>קטגוריה:</div>
                            <Autocomplete
                                style={{ border: 'solid 1px rgba(73, 155, 234, 1)', borderRadius: 30, height: 55, paddingLeft: 20, paddingRight: 8, paddingTop: 12, width: '100%', marginTop: 50 }}
                                multiple
                                id="tags-filled"
                                options={names.map(option => option)}
                                freeSolo
                                onChange={handleChange}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} style={{ padding: 8 }} />
                                    ))
                                }
                                renderInput={params => (
                                    <TextField {...params} className="textinput" InputProps={{ ...params.InputProps, disableUnderline: true }} label="" placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)" style={{ minHeight: 55, borderSize: 0, marginTop: -5 }} />
                                )}
                            />
                            {errorTags && (<div className={classes.errorMessage}>שדה חובה</div>)}


                            <div className={classes.inputTitle}>טווחי גילאים:</div>
                            <FormControl className={classes.formControl} style={{ border: 'solid 1px rgba(73, 155, 234, 1)', borderRadius: 30, height: 55, paddingLeft: 20, paddingRight: 8, paddingTop: 12, width: '100%' }}>
                                <Select
                                    labelId="demo-mutiple-chip-label"
                                    disableUnderline
                                    id="demo-mutiple-chip"
                                    multiple
                                    value={ageRange}
                                    onChange={handleChangeAgeRange}
                                    input={<Input id="select-multiple-chip" style={{ direction: 'rtl', marginTop: -10, minHeight: 50 }} />}
                                    renderValue={selected => (
                                        <div className={classes.chips}>
                                            {selected.map(value => (
                                                <Chip key={value} label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {ageRangeArray.map(step => (
                                        <MenuItem key={step.value} value={step.value} style={getStyles(step.value, ageRangeArray, theme)}>
                                            {step.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {errorAgeRange && (<div className={classes.errorMessage}>שדה חובה</div>)}
                        </Grid>
                        <Grid item sm={12} md={12} lg={12} xl={12}>
                            <center>
                                <Button className={'done-button'} onClick={() => publish()}>
                                    {props.main.state.adding ? <CircularProgress size={25} /> : 'העלאת סטורי'}
                                </Button>
                            </center>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div >
    );
}
