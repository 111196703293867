import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const categories = [
  { title: "הריון ולקראת לידה" },
  { title: "הנקה" },
  { title: "מוזיקה ותנועה" },
  { title: "התפתחות הבייבי" },
  { title: "טיפים וכלים להורים" },
  { title: "תזונת תינוקות" },
  { title: "להכיר חברות חדשות" },
  { title: "כולם" },
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
  },
  root: {
    "& .MuiSelect-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiInputLabel-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiSelect-select": {
      direction: "ltr",
      float: "left",
      backgroundColor: "transparent",
    },
    "& MuiSelect-selectMenu": {
      direction: "ltr",
    },
    ".MuiTextField-root": {
      borderSize: 0,
      border: 1,
    },
    "& .MuiAutocomplete-tag": {
      marginLeft: 90,
    },
    deleteIcon: {
      marginLeft: 20,
    },
  },
  content: {
    padding: 30,
  },
  title: {
    fontFamily: "'Varela Round', sans-serif",
    float: "left",
  },
  publish: {
    backgroundColor: "white",
    color: "black",
    padding: 8,
    borderRadius: 5,
  },
  textField: {
    marginTop: 15,
    direction: "ltr",
  },
  pageTitle: {
    textAlign: "center",
    fontFamily: "'Varela Round', sans-serif",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
  formControl: {
    minWidth: 120,
    direction: "ltr",
    width: "100%",
  },
  publishButton: {
    margin: `${theme.spacing(1)}px 0`,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
  "כולם",
];

// const ageRangeArray = [
//   'הריון',
//   '0-6',
//   '6-12',
//   '12-24',
//   '24+',
//   'כולם'
// ];
const ageRangeArray = [
  { label: "0-3 חודשים", value: "0-3" },
  { label: "3-6 חודשים", value: "3-6" },
  { label: "6-9 חודשים", value: "6-9" },
  { label: "9-12 חודשים", value: "9-12" },
  { label: "12-24 חודשים", value: "12-24" },
  { label: "24+ חודשים", value: "24+" },
  { label: "לאמא בהריון", value: "הריון" },
  { label: "לאמא ולבייבי", value: "אמא ובייבי" },
  { label: "לאמא", value: "אמא" },
  { label: "כולם", value: "כולם" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [tags, setTags] = React.useState([]);
  const [ageRange, setAgeRange] = React.useState([]);
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorVideo, setErrorVideo] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorTags, setErrorTags] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);
  // const [errorOpenTags, setErrorOpenTags] = React.useState(false);
  const [state, setState] = React.useState({
    title: "",
    description: "",
    videoLink: "",
  });
  const [supplier, setSupplier] = React.useState(null);
  const [errorSupplier, setErrorSupplier] = React.useState(false);

  React.useEffect(() => {});

  const resetParams = () => {
    setTags([]);
    setAgeRange([]);
    // setOpenTags([]);
    setSupplier(null);
    setState({ title: "", description: "", link: "" });
  };

  const handleChangeSupplier = (event, v) => {
    setSupplier(v);
  };

  // const handleChange = (event) => {
  //   setTags(event.target.value);
  // };

  // const handleChangeAgeRange = (event) => {
  //   setAgeRange(event.target.value);
  // };

  const publish = () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorDescription(false);
    setErrorVideo(false);
    setErrorTags(false);
    setErrorAgeRange(false);
    setErrorSupplier(false);

    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }
    if (Object.keys(interest).every((k) => !interest[k])) {
      setErrorTags(true);
      allowToContinue = false;
    }
    if (Object.keys(agesNew).every((k) => !agesNew[k])) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }
    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }
    if (state.videoLink === "") {
      setErrorVideo(true);
      allowToContinue = false;
    }
    if (supplier === null) {
      setErrorSupplier(true);
      allowToContinue = false;
    }

    if (allowToContinue) {
      var data = {
        link: state.videoLink,
        title: state.title,
        description: state.description,
        interests: [],
        ages: [],
        //   openTags: openTags,
        publisher: supplier,
        promote: false,
        publish: false,

        relNursing,
        relFitNutrition,
        relMeetNewFriends,
        relTipsTools,
        relFunWithBaby,
        relBabyDev,
        relInfantNutrition,
        relPregnancyBirth,

        AFForEveryone,
        AFForMomBaby,
        AFForMoms,
        AFNineToTwelve,
        AFPregnancy,
        AFSixToNine,
        AFThreeToSix,
        AFTwelveToTwentyFour,
        AFTwentyFourPlus,
        AFZeroToSix,
        AFZeroToThree,
        AFZeroToTwelve,
      };
      saveDataInDatabase(data);
    }
  };

  const saveDataInDatabase = (data) => {
    props.main.setState({ adding: true });
    axios
      .post("https://jama-server.appspot.com/api/videos/create", data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        resetParams();
        props.main.setState({ adding: false, openNewVideo: false });
        props.main.getVideos();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleChangeTags = (e, v) => {
  //   setOpenTags(v);
  // };

  ///

  const [interest, setInterest] = React.useState({
    relNursing: false,
    relFitNutrition: false,
    relMeetNewFriends: false,
    relTipsTools: false,
    relFunWithBaby: false,
    relBabyDev: false,
    relInfantNutrition: false,
    relPregnancyBirth: false,
  });

  const {
    relNursing,
    relFitNutrition,
    relMeetNewFriends,
    relTipsTools,
    relFunWithBaby,
    relBabyDev,
    relInfantNutrition,
    relPregnancyBirth,
  } = interest;

  const handleChangeCheckBox = (event) => {
    setInterest({
      ...interest,
      [event.target.name]: event.target.checked,
    });
  };

  ///

  const [agesNew, setAgesNew] = React.useState({
    AFForEveryone: false,
    AFForMomBaby: false,
    AFForMoms: false,
    AFNineToTwelve: false,
    AFPregnancy: false,
    AFSixToNine: false,
    AFThreeToSix: false,
    AFTwelveToTwentyFour: false,
    AFTwentyFourPlus: false,
    AFZeroToSix: false,
    AFZeroToThree: false,
    AFZeroToTwelve: false,
  });

  const {
    AFForEveryone,
    AFForMomBaby,
    AFForMoms,
    AFNineToTwelve,
    AFPregnancy,
    AFSixToNine,
    AFThreeToSix,
    AFTwelveToTwentyFour,
    AFTwentyFourPlus,
    AFZeroToSix,
    AFZeroToThree,
    AFZeroToTwelve,
  } = agesNew;

  const handleChangeCheckBoxAges = (event) => {
    setAgesNew({
      ...agesNew,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen
        open={props.main.state.openNewVideo}
        onClose={() => props.main.setState({ openNewVideo: false })}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.main.setState({ openNewVideo: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.pageTitle}>
              וידאו חדש
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container spacing={2} justify="center">
            <Grid item sm={12} md={10} lg={8} xl={8}>
              <div className={classes.inputTitle}>בחר מפרסם (ג׳אמה או ספק)</div>
              <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                  textAlign: "right",
                  direction: "rtl",
                }}
                options={props.main.state.options}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeSupplier}
                id="tags-filled"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="בחר מפרסם"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              />
              {errorSupplier && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>לינק לוידאו מיוטיוב</div>
              <input
                className="textinput"
                onChange={(e) =>
                  setState({ ...state, videoLink: e.target.value })
                }
              />
              {errorVideo && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>כותרת</div>
              <input
                className="textinput"
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
              {errorTitle && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>תקציר</div>
              <textarea
                className="textarea_box"
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
                cols="40"
                rows="5"
              />
              {errorDescription && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              {/* <div className={classes.inputTitle}>תחומי עניין</div>
              <FormControl
                className={classes.formControl}
                style={{
                  border: 'solid 1px rgba(73, 155, 234, 1)',
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: '100%',
                }}
              >
                <Select
                  labelId='demo-mutiple-chip-label'
                  disableUnderline
                  id='demo-mutiple-chip'
                  multiple
                  value={tags}
                  onChange={handleChange}
                  input={
                    <Input
                      id='select-multiple-chip'
                      style={{ direction: 'rtl', marginTop: -10, minHeight: 50 }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem key={name} value={name} style={getStyles(name, tags, theme)}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorTags && <div className={classes.errorMessage}>שדה חובה</div>} */}
              <div className={classes.inputTitle}>תחומי עניין</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relNursing}
                      onChange={handleChangeCheckBox}
                      name="relNursing"
                    />
                  }
                  label="הנקה"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFitNutrition}
                      onChange={handleChangeCheckBox}
                      name="relFitNutrition"
                    />
                  }
                  label="כושר ותזונה לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relMeetNewFriends}
                      onChange={handleChangeCheckBox}
                      name="relMeetNewFriends"
                    />
                  }
                  label="להכיר חברות חדשות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relTipsTools}
                      onChange={handleChangeCheckBox}
                      name="relTipsTools"
                    />
                  }
                  label="טיפים וכלים להורים"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFunWithBaby}
                      onChange={handleChangeCheckBox}
                      name="relFunWithBaby"
                    />
                  }
                  label="כיף עם הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relBabyDev}
                      onChange={handleChangeCheckBox}
                      name="relBabyDev"
                    />
                  }
                  label="התפתחות הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relInfantNutrition}
                      onChange={handleChangeCheckBox}
                      name="relInfantNutrition"
                    />
                  }
                  label="תזונת תינוקות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relPregnancyBirth}
                      onChange={handleChangeCheckBox}
                      name="relPregnancyBirth"
                    />
                  }
                  label="הריון ולקראת לידה"
                />
              </FormGroup>
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
                multiple
                id="tags-filled"
                options={categories.map((option) => option.title)}
                freeSolo
                onChange={handleChangeTags}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      style={{ padding: 8 }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="הוספת תגית"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              /> */}
              {/* {errorOpenTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
              <div className={classes.inputTitle}>טווחי גילאים</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForEveryone}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForEveryone"
                    />
                  }
                  label="לכולם"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMomBaby}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMomBaby"
                    />
                  }
                  label="לאמא ולבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMoms}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMoms"
                    />
                  }
                  label="לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFPregnancy}
                      onChange={handleChangeCheckBoxAges}
                      name="AFPregnancy"
                    />
                  }
                  label="לאמא בהריון"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToThree}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToThree"
                    />
                  }
                  label="0-3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToSix"
                    />
                  }
                  label="0-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToTwelve"
                    />
                  }
                  label="0-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFThreeToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFThreeToSix"
                    />
                  }
                  label="3-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFSixToNine}
                      onChange={handleChangeCheckBoxAges}
                      name="AFSixToNine"
                    />
                  }
                  label="6-9"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFNineToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFNineToTwelve"
                    />
                  }
                  label="9-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwelveToTwentyFour}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwelveToTwentyFour"
                    />
                  }
                  label="12-24"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwentyFourPlus}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwentyFourPlus"
                    />
                  }
                  label="24+"
                />
              </FormGroup>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={ageRange}
                  onChange={handleChangeAgeRange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "rtl",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ageRangeArray.map((step) => (
                    <MenuItem
                      key={step.value}
                      value={step.value}
                      style={getStyles(step.value, ageRangeArray, theme)}
                    >
                      {step.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <center>
                <Button
                  data-trans="signin-done-button"
                  className={"done-button"}
                  onClick={() => publish()}
                >
                  {props.main.state.adding ? (
                    <CircularProgress size={25} />
                  ) : (
                    "פרסום וידאו"
                  )}
                </Button>
              </center>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
