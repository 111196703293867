import React, { useState, useEffect } from "react";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SumCard from "./Cards/SumCard";
import CardUsers from "./Cards/CardUsers";
import CardActivities from "./Cards/CardActivities";
import IframeCard from "./Cards/IframeCard";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

// const text = {
//   users: "משתמשים",
//   suppliers: "ספקים",
//   activities: "פעילויות",
//   signups: "רשומים לפעילויות",
// };

function Main() {
  const [totalUsers, setTotalUsers] = useState("");
  const [totalUsersMaterna, setTotalUsersMaterna] = useState("");
  const [totalSuppliers, setTotalSuppliers] = useState("");
  const [totalActivities, setTotalActivities] = useState("");
  const [totalSignups, setTotalSignups] = useState("");

  const getUsers = () => {
    axios
      .get("https://jama-server.appspot.com/api/statics/total-users-new")
      .then((response) => {
        const users = response.data;
        setTotalUsers(users.number);
      })
      .catch((error) => {
        setTotalUsers("");
        console.log(error);
      });
  };

  const getUsersMaterna = () => {
    axios
      .get("https://jama-server.appspot.com/api/statics/total-users-materna")
      .then((response) => {
        const usersMaterna = response.data;
        setTotalUsersMaterna(usersMaterna.number);
      })
      .catch((error) => {
        setTotalUsersMaterna("");
        console.log(error);
      });
  };

  const getVendors = () => {
    axios
      .get("https://jama-server.appspot.com/api/statics/total-suppliers-new")
      .then((response) => {
        const users = response.data;
        setTotalSuppliers(users.number);
      })
      .catch((error) => {
        setTotalSuppliers("");
        console.log(error);
      });
  };

  const getActivities = () => {
    axios
      .get("https://jama-server.appspot.com/api/statics/total-activities-new")
      .then((response) => {
        const activities = response.data;
        setTotalActivities(activities.number);
      })
      .catch((error) => {
        setTotalActivities("");
        console.log(error);
      });
  };

  const getActivitiesSignups = () => {
    axios
      .get("https://jama-server.appspot.com/api/statics/total-signups-new")
      .then((response) => {
        const signups = response.data;
        setTotalSignups(signups.number);
      })
      .catch((error) => {
        setTotalSignups("");
        console.log(error);
      });
  };

  useEffect(() => {
    getUsers();
    getUsersMaterna();
    getVendors();
    getActivities();
    getActivitiesSignups();
  }, []);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <CardUsers
              totalUsers={totalUsers}
              totalUsersMaterna={totalUsersMaterna}
            ></CardUsers>
          </Grid>
          <Grid item xs={4}>
            <CardActivities
              totalActivities={totalActivities}
              totalSignups={totalSignups}
            ></CardActivities>
          </Grid>
          <Grid item xs={4}>
            <SumCard totalSuppliers={totalSuppliers}></SumCard>
          </Grid>
          <Grid item xs={12}>
            <IframeCard />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Main;
