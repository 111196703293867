import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider, useSnackbar } from "notistack";
import { MaternaInfo } from "./Sections/MaternaInfo";
import Grid from "@material-ui/core/Grid";
import TableUsers from "./Sections/TableUsers";
import TableActivities from "./Sections/TableActivities";
import Main from "../Main/Main";

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100vw",
    height: "100vh",
    fontFamily: "'Varela Round', sans-serif",
  },
  success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
  staticsBox: {
    backgroundColor: "white",
    minWidth: "60%",
    height: "60%",
    boxShadow: "0px 0px 12px -8px rgba(0, 0, 0, 0.5)",
    borderRadius: 20,
    minHeight: 120,
    paddingTop: 5,
    paddingLeft: 10,
    paddingRight: 10,
  },
  boxTitle: {
    textAlign: "left",
    fontSize: "1.0em",
  },
  number: {
    fontSize: "3.5em",
    color: "#99a1a9",
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 110,
    float: "left",
    textAlign: "left",
  },
});

class Articles extends React.Component {
  state = {
    isLoading: false,
    dataLoadedCounter: 0,
    users: [],
    usersMaterna: [],
    totalUsers: [],
    totalUsersMaterna: [],
    activeUsers: [],
    registeredUsers: [],
    vendors: [],
    activities: [],
    totalSignups: [],
    sortBy: "היום",
    today_signups: [],
    today_registrations: [],
    today_registrations_materna: [],
    yesterday_signups: [],
    yesterday_registrations: [],
    yesterday_registrations_materna: [],
    last_week_signups: [],
    last_week_registrations: [],
    last_week_registrations_materna: [],
    last_month_registrations: [],
    last_month_signups: [],
    last_month_registrations_materna: [],
  };

  componentDidMount() {
    this.getVendors();
    this.getUsers();
    this.getUsersMaterna();
    this.getActivities();
    this.getActivitiesSignups();
  }

  componentDidUpdate() {
    if (this.state.dataLoadedCounter === 3) {
      this.setState({ isLoading: false, dataLoadedCounter: 0 });
    }
  }

  getData = (forceRefresh) => {
    this.setState({ isLoading: true }, () => {});
    if (forceRefresh) {
      this.getUsers();
      this.getActivities();
      this.getVendors();
    } else {
      const users = localStorage.getItem("statics_users");
      let temp_users = new Array(Number(users));

      const activeUsers = localStorage.getItem("statics_active_users");
      let temp_activeUsers = new Array(Number(activeUsers));
      const registeredUsers = localStorage.getItem("statics_registered_users");
      let temp_registeredUsers = new Array(Number(registeredUsers));

      const vendors = localStorage.getItem("statics_vendors");
      let temp_vendors = new Array(Number(vendors));
      const activities = localStorage.getItem("statics_activities");
      let temp_activities = new Array(Number(activities));

      this.setState({
        totalUsers: temp_users,
        totalUsersMaterna: temp_users,
        activeUsers: temp_activeUsers,
        registeredUsers: temp_registeredUsers,
        vendors: temp_vendors,
        activities: temp_activities,
        dataLoadedCounter: 3,
      });
    }
  };

  getUsers = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/statics/total-users-new"
        // "http://localhost:8081/api/statics/total-users-new"
      )
      .then((response) => {
        const users = response.data;
        this.setState({
          totalUsers: users.number,
          users: users.number,
        });
        let usersData;
        console.log(users);
        axios
          .get(
            "https://jama-server.appspot.com/api/Users"
            // "http://localhost:8081/api/Users"
          )
          .then((response) => {
            usersData = response.data;

            this.getRegisteredUsers(usersData, "today");
            this.getRegisteredUsers(usersData, "yesterday");
            this.getRegisteredUsers(usersData, "last_week");
            this.getRegisteredUsers(usersData, "last_month");

            this.setState({ usersData: usersData });
          })
          .catch((err) => console.log(err));

        this.setState({
          totalUsers: users.number,
          users: users.number,
          dataLoadedCounter: this.state.dataLoadedCounter + 1,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getUsersMaterna = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/statics/total-users-materna"
        // "http://localhost:8081/api/statics/total-users-materna"
      )
      .then((response) => {
        const usersMaterna = response.data;
        let usersDataMaterna;
        console.log(usersMaterna);
        axios
          .get(
            "https://jama-server.appspot.com/api/Users/usersMaterna"
            // "http://localhost:8081/api/Users/usersMaterna"
          )
          .then((response) => {
            usersDataMaterna = response.data;

            this.getRegisteredUsersMaterna(usersDataMaterna, "today");
            this.getRegisteredUsersMaterna(usersDataMaterna, "yesterday");
            this.getRegisteredUsersMaterna(usersDataMaterna, "last_week");
            this.getRegisteredUsersMaterna(usersDataMaterna, "last_month");

            this.setState({ usersDataMaterna: usersDataMaterna });
          })
          .catch((err) => console.log(err));

        this.setState({
          totalUsersMaterna: usersMaterna.number,
          usersMaterna: usersMaterna.number,
          dataLoadedCounter: this.state.dataLoadedCounter + 1,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getVendors = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/statics/total-suppliers-new"
        // "http://localhost:8081/api/statics/total-suppliers-new"
      )
      .then((response) => {
        const users = response.data;
        console.log(users);
        this.setState({
          vendors: users.number,
          dataLoadedCounter: this.state.dataLoadedCounter + 1,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getActivities = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/statics/total-activities-new"
        // "http://localhost:8081/api/statics/total-activities-new"
      )
      .then((response) => {
        const activities = response.data;
        console.log(activities);
        this.setState({
          activities: activities.number,
          dataLoadedCounter: this.state.dataLoadedCounter + 1,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  getActivitiesSignups = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/statics/total-signups-new"
        // "http://localhost:8081/api/statics/total-signups-new"
      )
      .then((response) => {
        const signups = response.data;
        console.log(signups);

        axios
          .get("https://jama-server.appspot.com/api/Activities/signups")
          .then((response) => {
            const signupsData = response.data;

            this.getSignedupUsers(signupsData, "today");
            this.getSignedupUsers(signupsData, "yesterday");
            this.getSignedupUsers(signupsData, "last_week");
            this.getSignedupUsers(signupsData, "last_month");
          })
          .catch((err) => console.log(err));
        this.setState({
          totalSignups: signups.number,
          dataLoadedCounter: this.state.dataLoadedCounter + 1,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  handleChangeSortBy = (event) => {
    this.setState({ sortBy: event.target.value }, () => {
      this.getActiveUsers(this.state.users);
      this.getRegisteredUsers(this.state.users);
      // this.getData();
    });
  };

  getActiveUsers = (users) => {
    const activeUsers = users.filter((user) => {
      if (user.lastVisitedAt) {
        let res = this.handleTimeCalc(user.lastVisitedAt._seconds);
        if (res) return res;
      }
    });
    localStorage.setItem("statics_users", JSON.stringify(users.length));
    localStorage.setItem(
      "statics_active_users",
      JSON.stringify(activeUsers.length)
    );
    this.setState({ activeUsers: activeUsers });
  };

  getSignedupUsers = (users, when) => {
    const registeredUsers = users.filter((user) => {
      if (user.signedInAt) {
        let res = this.handleTimeCalc(user.signedInAt._seconds, when);
        if (res) return res;
      }
    });
    // localStorage.setItem('statics_users', JSON.stringify(users.length))
    // localStorage.setItem('statics_registered_users', JSON.stringify(registeredUsers.length))
    if (when === "today") {
      this.setState({ today_signups: registeredUsers.length });
    } else if (when === "yesterday") {
      this.setState({ yesterday_signups: registeredUsers.length });
    } else if (when === "last_week") {
      this.setState({ last_week_signups: registeredUsers.length });
    } else if (when === "last_month") {
      this.setState({ last_month_signups: registeredUsers.length });
    }
  };

  getRegisteredUsers = (users, when) => {
    const registeredUsers = users.filter((user) => {
      if (user.registeredAt) {
        let res = this.handleTimeCalc(user.registeredAt._seconds, when);
        if (res) return res;
      }
    });
    // localStorage.setItem('statics_users', JSON.stringify(users.length))
    // localStorage.setItem('statics_registered_users', JSON.stringify(registeredUsers.length))
    if (when === "today") {
      this.setState({ today_registrations: registeredUsers.length });
    } else if (when === "yesterday") {
      this.setState({ yesterday_registrations: registeredUsers.length });
    } else if (when === "last_week") {
      this.setState({ last_week_registrations: registeredUsers.length });
    } else if (when === "last_month") {
      this.setState({ last_month_registrations: registeredUsers.length });
    }
  };

  getRegisteredUsersMaterna = (users, when) => {
    const registeredUsersMaterna = users.filter((user) => {
      if (user.registeredAt) {
        let res = this.handleTimeCalc(user.registeredAt._seconds, when);
        if (res) return res;
      }
    });
    if (when === "today") {
      this.setState({
        today_registrations_materna: registeredUsersMaterna.length,
      });
    } else if (when === "yesterday") {
      this.setState({
        yesterday_registrations_materna: registeredUsersMaterna.length,
      });
    } else if (when === "last_week") {
      this.setState({
        last_week_registrations_materna: registeredUsersMaterna.length,
      });
    } else if (when === "last_month") {
      this.setState({
        last_month_registrations_materna: registeredUsersMaterna.length,
      });
    }
  };

  handleTimeCalc = (date, when) => {
    // const { sortBy } = this.state;
    let ts = Math.round(new Date().getTime() / 1000);
    let limit;
    switch (when) {
      case "today":
        limit = ts - 24 * 3600;
        break;
      case "yesterday":
        limit = ts - 48 * 3600;
        ts = ts - 24 * 3600;
        break;
      case "last_week":
        limit = ts - 168 * 3600;
        break;
      case "last_month":
        limit = ts - 720 * 3600;
        break;
      default:
        limit = ts - 24 * 3600;
    }
    if (date < ts && date > limit) return true;
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        {this.state.isLoading == true ? (
          <div className="loading">
            <CircularProgress style={{ marginTop: "calc(50vh - 50px)" }} />
            <div>
              <div
                style={{
                  fontFamily: "arial",
                  fontSize: mobile ? 25 : 20,
                  padding: mobile ? 20 : 0,
                  color: "black",
                  margin: 20,
                  fontWeight: "bold",
                }}
              ></div>
            </div>
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <Grid
              container
              spacing={mobile ? 2 : 4}
              style={{ marginRight: 20 }}
            >
              {/* <Grid sx={{ border: 1 }}> */}
              {/*<Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>סה"כ משתמשים</div>
                    <div className={classes.number}>
                      {this.state.totalUsers > 0 ? (
                        <div>{this.state.totalUsers}</div>
                      ) : (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      נרשמו ב- 24 ש' האחרונות
                    </div>
                    <div className={classes.number}>
                      {this.state.today_registrations}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      נרשמו ב- 48 ש' האחרונות
                    </div>
                    <div className={classes.number}>
                      {this.state.yesterday_registrations}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו השבוע </div>
                    <div className={classes.number}>
                      {this.state.last_week_registrations}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו החודש</div>
                    <div className={classes.number}>
                      {this.state.last_month_registrations}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid sx={{ border: 1 }}>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>משתמשים - מטרנה</div>
                    <div className={classes.number}>
                      {this.state.totalUsersMaterna > 0 ? (
                        <div>{this.state.totalUsersMaterna}</div>
                      ) : (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      נרשמו ב- 24 ש' האחרונות - מטרנה
                    </div>
                    <div className={classes.number}>
                      {this.state.today_registrations_materna}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      נרשמו ב- 48 ש' האחרונות - מטרנה
                    </div>
                    <div className={classes.number}>
                      {this.state.yesterday_registrations_materna}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו השבוע - מטרנה</div>
                    <div className={classes.number}>
                      {this.state.last_week_registrations_materna}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו החודש - מטרנה</div>
                    <div className={classes.number}>
                      {this.state.last_month_registrations_materna}
                    </div>
                  </div>
                </Grid>
              </Grid> */}
              {/* <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: '100%' }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      משתמשים פעילים
               </div>
                    <div className={classes.number}>
                     {this.state.activeUsers.length}
                      -
                    </div>
                  </div>
                </Grid> */}
              {/* <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: '100%' }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      משתמשים רשומים
               </div>
                    <div className={classes.number}>
                      {this.state.registeredUsers.length}
                      -
                    </div>
                  </div>
                </Grid> */}
              {/* <Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>הרשמות לפעילויות</div>
                    <div className={classes.number}>
                      {this.state.totalSignups > 0 ? (
                        <div>{this.state.totalSignups}</div>
                      ) : (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>

                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו היום לפעילות</div>
                    <div className={classes.number}>
                      {this.state.today_signups}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו אתמול לפעילות</div>
                    <div className={classes.number}>
                      {this.state.yesterday_signups}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו השבוע לפעילות</div>
                    <div className={classes.number}>
                      {this.state.last_week_signups}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>נרשמו החודש לפעילות</div>
                    <div className={classes.number}>
                      {this.state.last_month_signups}
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>ספקים</div>
                    <div className={classes.number}>
                      {this.state.vendors > 0 ? (
                        <div>{this.state.vendors}</div>
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>פעילויות</div>
                    <div className={classes.number}>
                      {this.state.activities > 0 ? (
                        <div>{this.state.activities}</div>
                      ) : (
                        <div>
                          <CircularProgress />
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid item style={{ width: "100%", margin: 10 }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>הכנסות</div>
                    <div className={classes.number}>-</div>
                  </div>
                </Grid>
              </Grid> */}
              {/* <div style={{ width: "100%" }}></div> */}
              {/* <Grid item sm={12} md={6} lg={6} xl={6} style={{ width: '100%' }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      פילוח גילאים
                 </div>
                    <Ages />
                  </div>
                </Grid>
                <Grid item sm={12} md={6} lg={6} xl={6} style={{ width: '100%' }}>
                  <div className={classes.staticsBox}>
                    <div className={classes.boxTitle}>
                      פילוח גיאוגרפי
                 </div>
                    <Ages />
                  </div>
                </Grid> */}
              {/* <Grid item sm={12} md={4} lg={4} xl={3}></Grid> */}
              {/* </Grid> */}
              <Grid item style={{ width: "100%", margin: 10 }}>
                <MaternaInfo />
              </Grid>
              <Grid item style={{ width: "100%", margin: 10 }}>
                <div>
                  <TableUsers
                    totalUsersMaterna={
                      this.state.totalUsersMaterna >= "0" ? (
                        this.state.totalUsersMaterna
                      ) : (
                        <CircularProgress />
                      )
                    }
                    today_registrations_materna={
                      this.state.today_registrations_materna >= "0" ? (
                        this.state.today_registrations_materna
                      ) : (
                        <CircularProgress />
                      )
                    }
                    yesterday_registrations_materna={
                      this.state.yesterday_registrations_materna >= "0" ? (
                        this.state.yesterday_registrations_materna
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_week_registrations_materna={
                      this.state.last_week_registrations_materna >= "0" ? (
                        this.state.last_week_registrations_materna
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_month_registrations_materna={
                      this.state.last_month_registrations_materna >= "0" ? (
                        this.state.last_month_registrations_materna
                      ) : (
                        <CircularProgress />
                      )
                    }
                    totalUsers={
                      this.state.totalUsers >= "0" ? (
                        this.state.totalUsers
                      ) : (
                        <CircularProgress />
                      )
                    }
                    today_registrations={
                      this.state.today_registrations >= "0" ? (
                        this.state.today_registrations
                      ) : (
                        <CircularProgress />
                      )
                    }
                    yesterday_registrations={
                      this.state.yesterday_registrations >= "0" ? (
                        this.state.yesterday_registrations
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_week_registrations={
                      this.state.last_week_registrations >= "0" ? (
                        this.state.last_week_registrations
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_month_registrations={
                      this.state.last_month_registrations >= "0" ? (
                        this.state.last_month_registrations
                      ) : (
                        <CircularProgress />
                      )
                    }
                  ></TableUsers>
                </div>
              </Grid>
              <Grid item style={{ width: "100%", margin: 10 }}>
                <div>
                  <TableActivities
                    totalSignups={
                      this.state.totalSignups >= "0" ? (
                        this.state.totalSignups
                      ) : (
                        <CircularProgress />
                      )
                    }
                    today_signups={
                      this.state.today_signups >= "0" ? (
                        this.state.today_signups
                      ) : (
                        <CircularProgress />
                      )
                    }
                    yesterday_signups={
                      this.state.yesterday_signups >= "0" ? (
                        this.state.yesterday_signups
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_week_signups={
                      this.state.last_week_signups >= "0" ? (
                        this.state.last_week_signups
                      ) : (
                        <CircularProgress />
                      )
                    }
                    last_month_signups={
                      this.state.last_month_signups >= "0" ? (
                        this.state.last_month_signups
                      ) : (
                        <CircularProgress />
                      )
                    }
                  ></TableActivities>
                </div>
              </Grid>
            </Grid>
          </SnackbarProvider>
        )}
      </div>
    );
  }
}

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Articles));
