import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTheme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ReactCodeInput from 'react-verification-code-input';


const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CodeInputDialog(props) {

  const theme = useTheme()

  const handleClose = () => {
    props.main.setState({ openCodeInput: false })
  };

  const onChangeCode = (code) => {
    props.main.setState({ code: code })
  }

  const finishedTypingCode = (code) => {
    props.main.setState({ checkCode: true })
    props.main.checkCode(code)
  }

  return (
    <div>
      <Dialog
        open={props.main.state.openCodeInput}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ padding: 40 }}>
          <DialogTitle id="alert-dialog-slide-title"
            style={{
              alignSelf: 'baseline',
              color: theme.palette.primary.dark,
              fontFamily: theme.fontFamily,
              padding: 0,
              paddingBottom: 8
            }}>{"Enter the verification code"}
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <DialogContentText id="alert-dialog-slide-description">
              <ReactCodeInput
                values={props.main.state.code.split()}
                fieldWidth={36} fieldHeight={36}
                fields={6}
                loading={props.main.state.checkCode}
                autoFocus={false}
                onChange={onChangeCode}
                onComplete={finishedTypingCode}
              />
              <div style={{ color: 'red', textAlign: 'left', width: '100%', fontSize: 12, marginTop: 12 }}>{props.main.state.errorCodeMessage}</div>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}
