import * as React from "react";
import { withRouter } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import axios from "axios";
import { alpha } from "@mui/material/styles";
import Skeleton from "./Skeleton";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import DoneIcon from "@mui/icons-material/Done";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { visuallyHidden } from "@mui/utils";
import './OrdersVod.css';

// To Do: delete functionality + BE routes and integration.

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100vw",
    height: "100vh",
  },
  success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
  h2: {
    float: "left",
    textWeight: "bold",
    display: "inline-block",
    textAlign: "left",
    color: "rgb(100,100,100)",
    fontSize: 20,
  },
  fab: {
    position: "fixed",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: "left",
    textAlign: "left",
  },
});

function createData(name, calories, fat, carbs, protein, paid) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    paid,
  };
}

const rows = [
  createData("Cupcake", 305, 3.7, 67, 4.3, true),
  createData("Donut", 452, 25.0, 51, 4.9, true),
  createData("Eclair", 262, 16.0, 24, 6.0, true),
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0, true),
  createData("Gingerbread", 356, 16.0, 49, 3.9, false),
  createData("Honeycomb", 408, 3.2, 87, 6.5, false),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Jelly Bean", 375, 0.0, 94, 0.0, true),
  createData("KitKat", 518, 26.0, 65, 7.0, false),
  createData("Lollipop", 392, 0.2, 98, 0.0, false),
  createData("Marshmallow", 318, 0, 81, 2.0, true),
  createData("Nougat", 360, 19.0, 9, 37.0, false),
  createData("Oreo", 437, 18.0, 63, 4.0, true),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "companyName",
    numeric: false,
    disablePadding: true,
    label: "Company name",
  },
  {
    id: "totalPrice",
    numeric: true,
    disablePadding: false,
    label: "Total price",
  },
  {
    id: "payerName",
    numeric: false,
    disablePadding: false,
    label: "Payer name",
  },
  {
    id: "payerEmail",
    numeric: false,
    disablePadding: false,
    label: "Payer email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone number",
  },

  {
    id: "employeesEmail",
    numeric: false,
    disablePadding: false,
    label: "Orders email",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "Subscriptions email",
  },
  {
    id: "paid",
    numeric: false,
    disablePadding: false,
    label: "Type",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Date purchased",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, activateOrders, deleteOrders } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <>
          <Tooltip title="Paid" onClick={activateOrders}>
            <IconButton>
              <DoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" onClick={deleteOrders}>
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class OrdersVod extends React.Component {
  state = {
    order: "asc",
    orderBy: "calories",
    selected: [],
    page: 0,
    dense: false,
    rowsPerPage: 100,
    orders: [],
    orderUpdate: {
      loading: false,
      status: "error",
    },
    isLoading: false,
  };
  formatCreatedAt = (timestamp) => {
    const createdAtDate = new Date(
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000
    );
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(createdAtDate);
  };

  getOrders = () => {
    const ordersPromise = axios.get(
      "https://jama-server.appspot.com/api/orders/"
    );
    const subscriptionsPromise = axios.get(
      "https://jama-server.appspot.com/api/subscriptions/"
    );

    Promise.all([ordersPromise, subscriptionsPromise])
      .then((responses) => {
        const orders = responses[0].data;
        console.log("orders", orders);
        const subscriptions = responses[1].data;
        console.log("subscriptions", subscriptions);
        const mergedData = [
          ...orders.map(order => ({...order, isOrder: true})),
          ...subscriptions.map(sub => ({...sub, isOrder: false}))
        ];

        this.setState({ orders: mergedData, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  setActiveOrders = () => {
    axios
      .post("https://jama-server.appspot.com/api/orders/activate-orders", {
        orders: this.state.selected,
      })
      .then((response) => {
        console.log(
          "https://jama-server.appspot.com/api/orders/activate-orders",
          response.data
        );
        const status = response.data.status;
        this.setState(() => ({
          orderUpdate: {
            loading: true,
            status: status,
          },
          isLoading: true,
          selected: [],
        }));
        this.getOrders();
        // var orders = response.data;

        // this.setState({ orders: orders, isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  setDeleteOrders = async () => {
    const ordersIds = this.state.selected.filter(id => this.state.orders.some(order => order.id === id && order.isOrder));
    const subsIds = this.state.selected.filter(id => !this.state.orders.some(order => order.id === id && order.isOrder));
    let status = 'success';
    try {
      if (ordersIds.length > 0) {
        await axios
        .post('https://jama-server.appspot.com/api/orders/delete-orders', {
          orders: ordersIds,
        })
      }
      if (subsIds.length > 0) {
        axios
        .post('https://jama-server.appspot.com/api/subscriptions/delete-subscriptions', {
          orders: ordersIds,
        })
      }
    } catch (e) {
      status = 'error';
    }
    this.setState(() => ({
      orderUpdate: {
        loading: true,
        status: status,
      },
      isLoading: true,
      selected: [],
    }));
    this.getOrders();
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({ order: isAsc ? "desc" : "asc", orderBy: property });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = this.state.orders.map((n) => n.id);
      this.setState((state) => ({ selected: newSelected }));
      return;
    }
    this.setState((state) => ({ selected: [] }));
  };

  handleClick = (event, id) => {
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    console.log("newSelected", newSelected);
    this.setState(() => ({ selected: newSelected }));
  };

  handleChangePage = (event, newPage) => {
    this.setState(() => ({ page: newPage }));
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(() => ({
      page: 0,
      rowsPerPage: parseInt(event.target.value, 100),
    }));
  };

  handleChangeDense = (event) => {
    this.setState((state) => ({ dense: !this.state.dense }));
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  componentDidMount() {
    this.getOrders();
  }

  activateOrders = () => {
    this.setState(() => ({
      isLoading: true,
    }));
    this.setActiveOrders();
  };

  deleteOrders = () => {
    this.setState(() => ({
      isLoading: true,
    }));
    this.setDeleteOrders();
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows =
    this.page > 0
      ? Math.max(
          0,
          (1 + this.page) * this.state.rowsPerPage - this.state.orders.length
        )
      : 0;
  render() {
    const { classes, theme } = this.props;
    return (
      <Box sx={{ width: "calc(100% + 100px)",  marginRight: '-110px'}}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          {this.state.orderUpdate.loading &&
            (this.state.orderUpdate.status === "success" ? (
              <Alert severity="success">
                This is a success alert — <strong>check it out!</strong>
              </Alert>
            ) : (
              <Alert severity="error">
                This is an error alert — <strong>check it out!</strong>
              </Alert>
            ))}
          {this.state.selected.length !== 0 && (
            <EnhancedTableToolbar
              numSelected={this.state.selected.length}
              activateOrders={this.activateOrders}
              deleteOrders={this.deleteOrders}
            />
          )}
          {this.state.isLoading === true ? (
            <div>
              <Skeleton />
            </div>
          ) : (
            <>
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={this.state.dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={this.state.selected.length}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    onSelectAllClick={this.handleSelectAllClick}
                    onRequestSort={this.handleRequestSort}
                    rowCount={this.state.orders.length}
                  />

                  <TableBody>
                    {stableSort(
                      this.state.orders,
                      getComparator(this.state.order, this.state.orderBy)
                    )
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map((row, index) => {
                        const isItemSelected = this.isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => this.handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            className='orders-table-row'
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {row.companyName}
                            </TableCell>
                            <TableCell align="center">
                              {row.totalPrice}
                            </TableCell>
                            <TableCell align="center">
                              {row.payerName}
                            </TableCell>
                            <TableCell align="center">{row.payer}</TableCell>
                            <TableCell align="center">
                              {row.phoneNumber}
                            </TableCell>
                            {
                              <TableCell align="center">
                                {Array.isArray(row.employeesEmail)
                                  ? row.employeesEmail.length === 1
                                    ? row.employeesEmail[0]
                                    : `${row.employeesEmail.length} emails`
                                  : row.employeesEmail}
                              </TableCell>
                            }
                            {
                              <TableCell align="center">
                                {Array.isArray(row.user)
                                  ? row.user.length === 1
                                    ? row.user[0]
                                    : `${row.user.length} emails`
                                  : row.user}
                              </TableCell>
                            }

                            {
                              <TableCell align="center">
                                {row.buyingOption}
                              </TableCell>
                            }
                            {/* <TableCell align="center" padding="checkbox">
                              {row.paid}
                            </TableCell> */}
                            {
                              <TableCell align="center">
                                {this.formatCreatedAt(row.createdAt)}
                              </TableCell>
                            }
                          </TableRow>
                        );
                      })}
                    {this.emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (this.state.dense ? 23 : 53) * this.emptyRows,
                        }}
                      >
                        <TableCell colSpan={10} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={this.state.orders.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </>
          )}
        </Paper>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.dense}
              onChange={this.handleChangeDense}
            />
          }
          label="Dense padding"
        />
      </Box>
    );
  }
}

OrdersVod.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(OrdersVod));
