import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import * as firebase from "firebase";
// import FormGroup from "@material-ui/core/FormGroup";
// import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
// import Checkbox from "@material-ui/core/Checkbox";
import PaymentIcon from "@material-ui/icons/Payment";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker } from "antd";
import moment from "moment";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

import "../../../../node_modules/antd/dist/antd.css";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
  },
  root: {
    "& .MuiSelect-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiInputLabel-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiSelect-select": {
      direction: "ltr",
      float: "left",
      backgroundColor: "transparent",
    },
    "& MuiSelect-selectMenu": {
      direction: "ltr",
    },
    ".MuiTextField-root": {
      borderSize: 0,
      border: 1,
    },
    "& .MuiAutocomplete-tag": {
      marginLeft: 90,
    },
    deleteIcon: {
      marginLeft: 20,
    },
  },
  content: {
    padding: 30,
  },
  title: {
    fontFamily: "'Varela Round', sans-serif",
    float: "left",
  },
  publish: {
    backgroundColor: "white",
    color: "black",
    padding: 8,
    borderRadius: 5,
  },
  textField: {
    marginTop: 15,
    direction: "ltr",
  },
  pageTitle: {
    textAlign: "center",
    fontFamily: "'Varela Round', sans-serif",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
  formControl: {
    minWidth: 120,
    direction: "ltr",
    width: "100%",
  },
  publishButton: {
    margin: `${theme.spacing(1)}px 0`,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  cancelAddItemButton: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 25px -8px rgba(0, 0, 0, 0.5)",
    borderRadius: 50,
    fontFamily: "'Varela Round', sans-serif",
    color: "#c21ed6",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

const default_options = [
  { title: "מחיר כרטיס" },
  { title: "מספר מפגשים" },
  { title: "הרצאה" },
];

const ageRangeArray = [
  { label: "0-3 חודשים", value: "0-3" },
  { label: "3-6 חודשים", value: "3-6" },
  { label: "6-9 חודשים", value: "6-9" },
  { label: "9-12 חודשים", value: "9-12" },
  { label: "12-24 חודשים", value: "12-24" },
  { label: "24+ חודשים", value: "24+" },
  { label: "לאמא בהריון", value: "הריון" },
  { label: "לאמא ולבייבי", value: "אמא ובייבי" },
  { label: "לאמא", value: "אמא" },
  { label: "כולם", value: "כולם" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [tags, setTags] = React.useState([]);
  const [ageRange, setAgeRange] = React.useState([]);
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorTags, setErrorTags] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [price, setPrice] = React.useState(null);
  const [errorProducts, setErrorProducts] = React.useState(false);
  const [date, setDate] = React.useState(0);
  const [prices, setPrices] = React.useState([]);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [errorLocation, setErrorLocation] = React.useState(false);
  const [errorDate, setErrorDate] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [state, setState] = React.useState({
    location: "",
    title: "",
    description: "",
    imageTitle: "",
    withDateActivity: true,
    onlineActivity: false,
    isLimited: true,
    limit: null,
  });
  const [supplier, setSupplier] = React.useState(null);
  const [withoutDate, setWithoutDate] = React.useState(false);
  const [itemPrice, setItemPrice] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("מחיר");
  const [newId, setNewId] = React.useState(101);
  const [showFields, setShowFields] = React.useState(true);
  const [errorSupplier, setErrorSupplier] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [errorInvalidLocation, setErrorInvalidLocation] = React.useState(false);

  const resetParams = () => {
    setTags([]);
    setAgeRange([]);
    setPrices([]);
    setCoverImage(null);
    setState({
      location: "",
      title: "",
      description: "",
      imageTitle: "",
      withDateActivity: true,
      onlineActivity: false,
      limit: null,
      isLimited: true,
    });
    setUpdate(!update);
    setPrice(0);
    setSupplier(null);
    setDate(null);
  };

  const handleChangeCheckbox = (event, pOrN) => {
    setErrorInvalidLocation(false);
    if (pOrN === "p") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: !event.target.checked });
    }
  };

  const handleChangeLimitCheckbox = (event, pOrN) => {
    if (pOrN === "p") {
      setState({ ...state, [event.target.name]: !event.target.checked });
    } else {
      setState({
        ...state,
        limit: null,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleChange = (e, v) => {
    setTags(v);
  };

  const handleChangeSupplier = (event, v) => {
    setSupplier(v);
  };

  const handleChangeAgeRange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleChangeNoDate = (event, field, data) => {
    setWithoutDate(event.target.checked);
  };

  const publish = async () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorInvalidLocation(false);
    setErrorTags(false);
    setErrorAgeRange(false);
    setErrorImage(false);
    setErrorDate(false);
    setErrorProducts(false);
    setErrorDescription(false);
    setErrorLocation(false);
    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }
    if (coverImage === null) {
      setErrorImage(true);
      allowToContinue = false;
    }
    if (Object.keys(interest).every((k) => !interest[k])) {
      setErrorTags(true);
      allowToContinue = false;
    }
    if (Object.keys(agesNew).every((k) => !agesNew[k])) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }
    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }
    if (prices.length === 0) {
      setErrorProducts(true);
      allowToContinue = false;
    }
    if (supplier === null) {
      setErrorSupplier(true);
      allowToContinue = false;
    }
    if (state.withDateActivity) {
      if (date === 0) {
        setErrorDate(true);
        allowToContinue = false;
      } else {
      }
    }

    if (!state.onlineActivity) {
      if (state.location === "" || state.location.length < 2) {
        setErrorLocation(true);
        allowToContinue = false;
      } else {
      }
    }

    if (allowToContinue) {
      var datetime = null;
      var data = {
        title: state.title,
        imageTitle: state.imageTitle,
        description: state.description,
        withLimitation: state.isLimited,
        interests: [],
        ages: [],
        promote: false,
        items: prices,
        publish: false,
        withoutDate: !state.withDateActivity,
        status: "approved",
        publisher: supplier,

        relNursing,
        relFitNutrition,
        relMeetNewFriends,
        relTipsTools,
        relFunWithBaby,
        relBabyDev,
        relInfantNutrition,
        relPregnancyBirth,

        AFForEveryone,
        AFForMomBaby,
        AFForMoms,
        AFNineToTwelve,
        AFPregnancy,
        AFSixToNine,
        AFThreeToSix,
        AFTwelveToTwentyFour,
        AFTwentyFourPlus,
        AFZeroToSix,
        AFZeroToThree,
        AFZeroToTwelve,
      };
      if (state.isLimited) {
        data.limit = state.limit;
      }
      if (state.withDateActivity) {
        // datetime = date.replace(':0 ', ':00 ') + ':00.000+0300'
        var tempDate = date;
        datetime = tempDate.replace(":0 ", ":00 ");
        data.date = datetime;
      }
      if (state.onlineActivity) {
        data.type = "online";
        data.location = "Zoom";
      } else {
        data.type = "frontal";
        data.location = state.location;
        var response = await getCoordinates(state.location);
        if (response !== false) {
          data.locationData = response;
        }
      }
      console.log(data);
      saveDataInDatabase(data);
    }
  };

  const handleGetCoords = (address) => {
    setState({
      ...state,
      location: address,
    });
    getCoordinates(address);
  };

  const getCoordinates = async (address) => {
    setErrorInvalidLocation(false);
    try {
      var link =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        encodeURI(address) +
        "&key=AIzaSyBhR-rD1My1pncnY-GcDBgd28AHcLWVVTY";
      const response = await axios.get(link);
      const resAddress = response.data.results[0];
      if (
        response.data.results.length > 0 &&
        (response.data.results[0].types.includes("route") ||
          response.data.results[0].types.includes("street_address"))
      ) {
        console.log("yes! Good address!");
        const userAddress = {
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng,
          formattedAddress: response.data.results[0].formatted_address,
        };
        return userAddress;
      } else {
        if (address === "") {
          setErrorInvalidLocation(false);
        } else {
          setErrorInvalidLocation(true);
        }
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addItem = () => {
    var tempPrices = prices;
    tempPrices.push({
      price: itemPrice,
      item: itemDescription,
      id: handleNewId(),
    });
    setPrices(tempPrices);
    setItemPrice("");
    setItemDescription("מחיר");
    setShowFields(false);
  };

  const handleNewId = () => {
    setNewId(newId + 1);
    return newId;
  };

  const deleteItemById = (id) => {
    var tempPrices = prices;
    tempPrices = tempPrices.filter((i) => i.id !== id);
    setPrices(tempPrices);
    setUpdate(true);
    if (tempPrices.length === 0) {
      setShowFields(true);
    }
  };

  const deleteItemByIndex = (index) => {
    var tempPrices = prices;
    tempPrices.splice(index, 1);
    setPrices(tempPrices);
    setUpdate(true);
  };

  const saveDataInDatabase = (data) => {
    props.main.setState({ adding: true });
    var form = new FormData();
    form.append("photos", coverImage, coverImage.name);
    const newData = JSON.stringify(data);
    form.append("data", newData);

    axios
      .post("https://jama-server.appspot.com/api/activities/create", form, {
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
      })
      .then((response) => {
        props.main.setState({ adding: false, openNewActivity: false });
        props.main.getActivities();
        resetParams();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const handleShowFields = () => {
    setShowFields(true);
  };

  const onchangeDateTime = (e) => {
    setDate(String(e._d));
    setFormattedDate(moment(e._d));
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const [interest, setInterest] = React.useState({
    relNursing: false,
    relFitNutrition: false,
    relMeetNewFriends: false,
    relTipsTools: false,
    relFunWithBaby: false,
    relBabyDev: false,
    relInfantNutrition: false,
    relPregnancyBirth: false,
  });

  const {
    relNursing,
    relFitNutrition,
    relMeetNewFriends,
    relTipsTools,
    relFunWithBaby,
    relBabyDev,
    relInfantNutrition,
    relPregnancyBirth,
  } = interest;

  const handleChangeCheckBox = (event) => {
    setInterest({
      ...interest,
      [event.target.name]: event.target.checked,
    });
  };

  ///

  const [agesNew, setAgesNew] = React.useState({
    AFForEveryone: false,
    AFForMomBaby: false,
    AFForMoms: false,
    AFNineToTwelve: false,
    AFPregnancy: false,
    AFSixToNine: false,
    AFThreeToSix: false,
    AFTwelveToTwentyFour: false,
    AFTwentyFourPlus: false,
    AFZeroToSix: false,
    AFZeroToThree: false,
    AFZeroToTwelve: false,
  });

  const {
    AFForEveryone,
    AFForMomBaby,
    AFForMoms,
    AFNineToTwelve,
    AFPregnancy,
    AFSixToNine,
    AFThreeToSix,
    AFTwelveToTwentyFour,
    AFTwentyFourPlus,
    AFZeroToSix,
    AFZeroToThree,
    AFZeroToTwelve,
  } = agesNew;

  const handleChangeCheckBoxAges = (event) => {
    setAgesNew({
      ...agesNew,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen
        open={props.main.state.openNewActivity}
        onClose={() => props.main.setState({ openNewActivity: false })}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.main.setState({ openNewActivity: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.pageTitle}>
              העלאת פעילות/שירות
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container spacing={2} justify="center">
            <Grid item sm={12} md={10} lg={8} xl={8}>
              <div className={classes.inputTitle}>בחר מפרסם (ג׳אמה או ספק)</div>
              <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                  textAlign: "right",
                  direction: "rtl",
                }}
                options={props.main.state.options}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeSupplier}
                id="tags-filled"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="בחר מפרסם"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              />
              {errorSupplier && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
              <div
                style={{ direction: "rtl", paddingBottom: 16, fontSize: 12 }}
              >
                <div>האותיות הקטנות:</div>
                <div>* תמונות איכותיות מתוך הפעילות שלך יתקבלו באהבה!</div>
                <div>* בבקשה ללא טשטושי פנים (זה הרבה פחות מזמין)</div>
                <div>* בבקשה לא לצרף לוגו עם טלפון או מייל</div>
                <div>
                  * אם אין ברשותך תמונות איכותיות צרי קשר ואנחנו נסייע לך
                </div>
              </div>
              <input
                className="textinput"
                type="file"
                id="single"
                onChange={onChange}
                style={{ paddingTop: 13, paddingRight: 20 }}
              />
              {errorImage && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>כותרת הפעילות/שירות:</div>
              <input
                className="textinput"
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
              {errorTitle && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>תיאור הפעילות/שירות:</div>
              <textarea
                className="textarea_box"
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
                cols="40"
                rows="5"
              />
              {errorDescription && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>סוג פעילות</div>
              <div style={{ width: "100%" }}>
                <FormControlLabel
                  style={{
                    textAlign: "right",
                    direction: "rtl",
                    float: "right",
                  }}
                  control={
                    <Checkbox
                      checked={!state.onlineActivity}
                      onChange={(e) => handleChangeCheckbox(e, "מ")}
                      name="onlineActivity"
                    />
                  }
                  label="פעילות פרונטלית"
                />
              </div>
              <div style={{ width: "100%" }}>
                <FormControlLabel
                  style={{
                    textAlign: "right",
                    direction: "rtl",
                    float: "right",
                  }}
                  control={
                    <Checkbox
                      checked={state.onlineActivity}
                      onChange={(e) => handleChangeCheckbox(e, "p")}
                      name="onlineActivity"
                    />
                  }
                  label="פעילות אונליין"
                />
              </div>
              <div className={classes.inputTitle}>
                מיקום: מקום להכניס כתובת | מקום לסמן אם פעילות אונליין
              </div>
              {!state.onlineActivity ? (
                <input
                  className="textinput"
                  placeholder="כתובת"
                  onChange={(e) => handleGetCoords(e.target.value)}
                  value={state.location}
                />
              ) : (
                // <input className="textinput" placeholder="כתובת" onChange={(e) => setState({ ...state, location: e.target.value })} value={state.location} />
                <input className="textinput" disabled value="אונליין" />
              )}
              {errorLocation && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              {errorInvalidLocation && (
                <div className={classes.errorMessage}>כתובת לא תקינה</div>
              )}
              <div className={classes.inputTitle}>הגבלת משתמשים</div>
              <div style={{ width: "100%" }}>
                <FormControlLabel
                  style={{
                    textAlign: "right",
                    direction: "rtl",
                    float: "right",
                  }}
                  control={
                    <Checkbox
                      checked={state.isLimited}
                      onChange={(e) => handleChangeLimitCheckbox(e, "l")}
                      name="isLimited"
                    />
                  }
                  label="עם הגבלה"
                />
              </div>
              <div style={{ width: "100%" }}>
                <FormControlLabel
                  style={{
                    textAlign: "right",
                    direction: "rtl",
                    float: "right",
                  }}
                  control={
                    <Checkbox
                      checked={!state.isLimited}
                      onChange={(e) => handleChangeLimitCheckbox(e, "p")}
                      name="isLimited"
                    />
                  }
                  label="ללא הגבלה"
                />
              </div>
              <div className={classes.inputTitle}>כמות משתתפות מקסימלי</div>
              {state.isLimited ? (
                <input
                  type="number"
                  min="0"
                  className="textinput"
                  placeholder="מספר משתתפות"
                  onChange={(e) =>
                    setState({ ...state, limit: e.target.value })
                  }
                  value={state.limit ? state.limit : ""}
                />
              ) : (
                <input className="textinput" disabled value="ללא הגבלה" />
              )}
              {errorLocation && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              {showFields && (
                <>
                  <div className={classes.inputTitle}>אופציות לבחירה:</div>
                  <div className={classes.inputTitle}>
                    מחיר פעילות/שירות: מקום להכניס מחיר | להזין 0 לפעילות חינם
                  </div>
                  <input
                    value={itemPrice}
                    type="text"
                    className="textinput"
                    onChange={(e) => setItemPrice(e.target.value)}
                  />
                  <div className={classes.inputTitle}>
                    תיאור מחיר (לדוגמה - מחיר ליחיד/לזוג, מחיר למספר מפגשים וכו)
                  </div>
                  <input
                    value={itemDescription ? itemDescription : "מחיר"}
                    type="text"
                    className="textinput"
                    onChange={(e) => setItemDescription(e.target.value)}
                  />
                  {prices.length > 0 && (
                    <Button
                      className={classes.cancelAddItemButton}
                      style={{ marginTop: 12, marginRight: 12 }}
                      onClick={() => setShowFields(false)}
                    >
                      ביטול הוספת אופציה
                    </Button>
                  )}
                  <Button
                    className={"done-button"}
                    style={{ marginTop: 12 }}
                    onClick={() => addItem()}
                  >
                    הוספת אופציה
                  </Button>
                </>
              )}
              <div className={classes.inputTitle}>מחירים שהתווספו</div>
              {prices.length === 0 ? (
                <div
                  style={{ textAlign: "right", width: "100%", fontSize: 18 }}
                >
                  אין מחירים
                </div>
              ) : (
                <div>
                  <List
                    style={{
                      direction: "rtl",
                      textAlign: "right",
                      width: "100%",
                    }}
                  >
                    {prices.map((item, index) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar>
                            <PaymentIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={item.item}
                          secondary={
                            item.price === "0" || item.price === ""
                              ? "חינם"
                              : item.price + " ש״ח "
                          }
                        />
                        <IconButton
                          onClick={() => deleteItemById(item.id)}
                          edge="start"
                          aria-label="delete"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
              {!showFields && (
                <Button
                  className={"done-button"}
                  style={{ marginTop: 12, float: "right" }}
                  onClick={handleShowFields}
                >
                  הוספת מחיר נוסף
                </Button>
              )}
              {errorProducts && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>מתי?</div>
              <div style={{ height: 100 }}>
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    style={{
                      textAlign: "right",
                      direction: "rtl",
                      float: "right",
                    }}
                    control={
                      <Checkbox
                        checked={!state.withDateActivity}
                        onChange={(e) => handleChangeCheckbox(e, "n")}
                        name="withDateActivity"
                      />
                    }
                    label="בתיאום מראש"
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    style={{
                      textAlign: "right",
                      direction: "rtl",
                      float: "right",
                    }}
                    control={
                      <Checkbox
                        checked={state.withDateActivity}
                        onChange={(e) => handleChangeCheckbox(e, "p")}
                        name="withDateActivity"
                      />
                    }
                    label="יום ושעה"
                  />
                </div>
              </div>
              {state.withDateActivity && (
                <div>
                  <div className={classes.inputTitle}>יום ושעה</div>
                  <DatePicker
                    renderExtraFooter={() => (
                      <div style={{ height: 0 }}>
                        <div
                          style={{
                            backgroundColor: "white",
                            width: "50%",
                            position: "relative",
                            top: 10,
                            height: 50,
                          }}
                        ></div>
                      </div>
                    )}
                    minuteStep={15}
                    format={"DD/MM/YYYY HH:mm"}
                    hideDisabledOptions={true}
                    placeholder="יש לבחור תאריך ושעה"
                    onChange={onchangeDateTime}
                    clearIcon={<div></div>}
                    disabledDate={disabledDate}
                    popupStyle={{ zIndex: 99999 }}
                    showToday={false}
                    showNow={false}
                    value={formattedDate}
                    id="date-and-time"
                    disabledHours={() => [0, 1, 2, 3, 4, 5]}
                    className="textinput"
                    style={{ borderRadius: 25, border: "1px solid #438BEC" }}
                    showTime
                  />
                </div>
              )}
              {errorDate && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>תחומי עניין</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relNursing}
                      onChange={handleChangeCheckBox}
                      name="relNursing"
                    />
                  }
                  label="הנקה"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFitNutrition}
                      onChange={handleChangeCheckBox}
                      name="relFitNutrition"
                    />
                  }
                  label="כושר ותזונה לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relMeetNewFriends}
                      onChange={handleChangeCheckBox}
                      name="relMeetNewFriends"
                    />
                  }
                  label="להכיר חברות חדשות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relTipsTools}
                      onChange={handleChangeCheckBox}
                      name="relTipsTools"
                    />
                  }
                  label="טיפים וכלים להורים"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFunWithBaby}
                      onChange={handleChangeCheckBox}
                      name="relFunWithBaby"
                    />
                  }
                  label="כיף עם הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relBabyDev}
                      onChange={handleChangeCheckBox}
                      name="relBabyDev"
                    />
                  }
                  label="התפתחות הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relInfantNutrition}
                      onChange={handleChangeCheckBox}
                      name="relInfantNutrition"
                    />
                  }
                  label="תזונת תינוקות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relPregnancyBirth}
                      onChange={handleChangeCheckBox}
                      name="relPregnancyBirth"
                    />
                  }
                  label="הריון ולקראת לידה"
                />
              </FormGroup>
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
                multiple
                id="tags-filled"
                options={names.map((option) => option)}
                freeSolo
                onChange={handleChange}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      style={{ padding: 8 }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              />
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
              <div className={classes.inputTitle}>טווחי גילאים</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForEveryone}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForEveryone"
                    />
                  }
                  label="לכולם"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMomBaby}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMomBaby"
                    />
                  }
                  label="לאמא ולבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMoms}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMoms"
                    />
                  }
                  label="לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFPregnancy}
                      onChange={handleChangeCheckBoxAges}
                      name="AFPregnancy"
                    />
                  }
                  label="לאמא בהריון"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToThree}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToThree"
                    />
                  }
                  label="0-3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToSix"
                    />
                  }
                  label="0-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToTwelve"
                    />
                  }
                  label="0-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFThreeToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFThreeToSix"
                    />
                  }
                  label="3-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFSixToNine}
                      onChange={handleChangeCheckBoxAges}
                      name="AFSixToNine"
                    />
                  }
                  label="6-9"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFNineToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFNineToTwelve"
                    />
                  }
                  label="9-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwelveToTwentyFour}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwelveToTwentyFour"
                    />
                  }
                  label="12-24"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwentyFourPlus}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwentyFourPlus"
                    />
                  }
                  label="24+"
                />
              </FormGroup>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={ageRange}
                  onChange={handleChangeAgeRange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "rtl",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ageRangeArray.map((step) => (
                    <MenuItem
                      key={step.value}
                      value={step.value}
                      style={getStyles(step.value, ageRangeArray, theme)}
                    >
                      {step.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <center>
                <Button
                  data-trans="signin-done-button"
                  className={"done-button"}
                  onClick={() => publish()}
                >
                  {props.main.state.adding ? (
                    <CircularProgress size={25} />
                  ) : (
                    "שליחת פעילות לאישור"
                  )}
                </Button>
              </center>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
