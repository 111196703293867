import React from "react";
import Iframe from "react-iframe";

function IframeCard() {
  return (
    <>
      <Iframe
        width="1300"
        height="1000"
        src="https://datastudio.google.com/embed/reporting/51b7ff35-ad1b-496e-a27c-dde11d7c3c37/page/p_21lu13ibwc"
        frameborder="0"
        style="border:0"
        allowfullscreen
      ></Iframe>
    </>
  );
}

export default IframeCard;
