import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import axios from "axios";
import { withRouter } from "react-router";
import firebase from "../../Firebase";
import clsx from "clsx";

// Material
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import Cookies from "universal-cookie";
import Card from "@material-ui/core/Card";
import Collapse from "@material-ui/core/Collapse";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import Person from "@material-ui/icons/Person";
import Lock from "@material-ui/icons/Lock";
import Email from "@material-ui/icons/Email";
// Components
import LoginTypes from "./LoginTypes";
import CodeInput from "./CodeInput";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(8),
    padding: 10,
    "& button": {
      fontFamily: theme.fontFamily,
    },
    "& .MuiTextField-root": {
      margin: `${theme.spacing(2)}px 0`,
      height: 50,
      width: "100%",
    },
    "& .MuiInputLabel-root": {
      color: theme.palette.primary.main,
      fontFamily: theme.fontFamily,
      fontWeight: "bold",
    },
    "& .MuiInputLabel-formControl": {
      right: theme.direction === "rtl" ? 0 : "auto",
      left: theme.direction === "rtl" ? "auto" : 0,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "50px",
      borderColor: theme.palette.primary.main,
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  title: {
    alignSelf: "baseline",
    color: theme.palette.primary.dark,
    // float: 'left',
    marginTop: 0,
    marginBottom: theme.spacing(4),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    fontFamily: theme.fontFamily,
  },
  signInContainer: {
    padding: `0 ${theme.spacing(1)}px`,
  },
  signinButton: {
    width: "100%",
    margin: `${theme.spacing(1)}px 0`,
    marginTop: 30,
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontWeight: "bold",
  },
  passForgotButton: {
    alignSelf: "baseline",
    display: "inline-block",
    color: theme.palette.primary.dark,
    fontFamily: theme.fontFamily,
    textDecoration: "underline",
    margin: "20px 0",
    cursor: "pointer",
  },
});

class Login extends React.Component {
  state = {
    username: "",
    password: "",
    email: "",
    fullName: "",
    showLoginAnimation: false,
    errorLogin: false,
    errorMsg: "",
    errorUsername: "",
    errorPassword: "",
    errorEmail: "",
    errorFullnmame: "",
    screen: "login",
    phone: "",
    errorPhone: false,
    errorPhoneCode: "",
    openCodeInput: false,
    checkCode: false,
    errorCodeMessage: "",
    code: "",
    countryCode: "IL",
    phoneCode: "+972",
    country: "Israel",
  };

  validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  componentDidMount() {
    firebase.auth().useDeviceLanguage();
    window.appVerifier = new firebase.auth.RecaptchaVerifier("sign-in-button", {
      size: "invisible",
      // badge: 'inline',
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        this.resetErrors();
        this.handleCodeSignIn();
      },
      "expired-callback": () => {
        console.log("expired");
      },
    });
    window.appVerifier.render();
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value }, () => {});
  };

  resetErrors = () => {
    this.setState({
      errorPhone: false,
      errorCodeMessage: "",
      errorMessage: "",
    });
  };

  authenticateUser = () => {
    console.log(this.state.phoneCode);
    console.log(this.state.phone);

    const appVerifier = window.appVerifier;

    firebase
      .auth()
      .signInWithPhoneNumber(
        this.state.phoneCode + this.state.phone,
        appVerifier
      )
      .then((confirmationResult) => {
        console.log(confirmationResult);
        window.confirmationResult = confirmationResult;
        this.setState({ openCodeInput: true, code: "" });
      })
      .catch((error) => {
        console.log(error);
        window.appVerifier.render().then(function (widgetId) {
          window.appVerifier.reset(widgetId);
        });
        this.setState({ errorPhone: true, errorMessage: error.message });
      });
  };

  getTypeOfUser = async () => {
    // axios.post('https://jama-server.appspot.com/api/adminUsers/get-user-by-phone', {phone: this.state.phone},
    return axios
      .post(
        "https://jama-server.appspot.com/api/adminUsers/get-user-by-phone",
        { phone: this.state.phone },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        console.log(response.data);
        var users = response.data;
        if (users.length > 0) {
          return { exists: true, type: "admin" };
        } else {
          // check if there is supplier related to this phone
          return axios
            .post(
              "https://jama-server.appspot.com/api/suppliers/get-user-by-phone",
              { phone: this.state.phone },
              { headers: { "Content-Type": "application/json" } }
            )
            .then((response) => {
              console.log(response.data);
              var users = response.data;
              if (users.length > 0) {
                return { exists: true, type: "supplier" };
              } else {
                return { exists: true };
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  checkCode = (code) => {
    window.confirmationResult
      .confirm(code)
      .then(async (result) => {
        // User signed in successfully.
        console.log("successfully");
        const userExist = await this.getTypeOfUser();
        if (userExist.exists === false) {
          this.setState({
            errorMessage: "אין משתמש שמקושר למספר הטלפון שהוכנס",
            errorPhone: true,
            openCodeInput: false,
            checkCode: false,
          });
        } else {
          console.log(result);
          this.setState({
            openCodeInput: false,
            errorMessage: "",
            errorPhone: false,
            checkCode: false,
          });
          if (userExist.type === "admin") {
            this.props.history.push("/main");
          } else {
            // this.props.history.push('/supplier/profile')
            this.props.history.push("/");
          }
        }
      })
      .catch((error) => {
        this.setState({
          errorCodeMessage: "Error verification code",
          checkCode: false,
          code: "",
        });
      });
  };

  handleCodeSignIn = () => {
    this.resetErrors();
    var canSignUp = true;
    const phone = this.state.phone;
    if (phone.length < 5) {
      this.setState({ errorPhone: true, errorMessage: "מספר טלפון לא תקין" });
      canSignUp = false;
    }
    if (canSignUp) {
      this.authenticateUser();
    } else {
      window.appVerifier.render().then(function (widgetId) {
        window.appVerifier.reset(widgetId);
      });
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        {this.state.openCodeInput && <CodeInput main={this} />}
        <Grid
          container
          className={classes.root}
          direction="column"
          alignItems="center"
        >
          {
            // <Grid item xs={12} sm={12} md={12}>
            //   <img src={require('../../Assets/Images/logo.png')} style={{height: 40}}/>
            // </Grid>
          }
          <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
            <form style={{ maxWidth: "100%", width: "100%" }}>
              <div>
                <h1 className={classes.title}>ברוכה הבאה ל-Jama!</h1>
              </div>
              <div className={classes.title} style={{ fontSize: 16 }}>
                כמה פעולות קצרות ותגיעי לאזור האישי שלך :)
              </div>
              <div className={classes.inputTitle}>הכניסי מספר טלפון:</div>
              <TextField
                required
                id="outlined-phone-input"
                label=""
                style={{ textAlign: "left", direction: "ltr" }}
                type="phone"
                autoComplete="current-phone"
                variant="outlined"
                onChange={this.handleChange("phone")}
                error={this.state.errorPhone}
                helperText={
                  this.state.errorPhone ? this.state.errorMessage : ""
                }
                InputLabelProps={{
                  shrink: false,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {this.state.phoneCode}
                    </InputAdornment>
                  ),
                }}
              />
              <div
                style={{
                  paddingTop: 0,
                  textAlign: "center",
                  float: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  id="sign-in-button"
                  type="submit"
                  className={"done-button " + classes.signinButton}
                >
                  לחצי לקבלת קוד אימות
                </Button>
              </div>
              <br />
            </form>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Login));
