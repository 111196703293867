import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';


import axios from 'axios';

import FileViewer from 'react-file-viewer';
const doc = require('../../../Assets/Docs/contract.docx')

const useStyles = makeStyles(theme => ({

    tosText: {
        textAlign: '-webkit-auto',
        padding: '0 5px',
        fontFamily: theme.fontFamily,
        color: theme.palette.primary.dark,
        '& button': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            textDecoration: 'none'
        },
    },
    agreeButton: {

        width: 220,
        height: 30,
        background: 'linear-gradient(180deg, #FCA6BC 0%, #FF8FAC 100%)',
        borderRadius: 7,
        color: '#ffffff',
        fontFamily: 'Varela Round',
        padding: 0,
        margin: '20px auto'
    },
    disableButton: {
        width: 220,
        height: 30,
        background: 'linear-gradient(180deg, #FCA6BC 0%, #FF8FAC 100%)',
        borderRadius: 7,
        color: '#ffffff',
        fontFamily: 'Varela Round',
        padding: 0,
        margin: '20px auto',
        opacity: '0.8'
    },
    '@global': {
        'div.pg-viewer,div.document-container': {
            width: '100% !important',
        },
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContractPopup(props) {
    const theme = useTheme();
    const classes = useStyles();
    const [isApproved, setIsApproved] = React.useState(false);

    const [enableButton, setEnable] = React.useState(false);

    const handleClose = () => {
        if (!isApproved) return;
        props.setOpenContractPopup(false)
    };

    const handleApprove = () => {
        saveDataInDatabase();
    }

    const saveDataInDatabase = () => {
        const userId = props.userData.id;
        const data = {
            approvedContract: true
        }

        axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            console.log(response.data);
            window.location.reload()
            setIsApproved(true)
            handleClose();
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleScroll = (e) => {
        if (e.target.scrollHeight - e.target.clientHeight > e.target.scrollTop / 2) {
            setEnable(true);
            setEnable(true);
        }
    }


    return (
        <div>
            <Dialog
                open={props.openContractPopup}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                style={{ direction: "rtl" }}
                onScroll={handleScroll}
            >

                <DialogTitle>{"אנא אשרו את פרטי השימוש על מנת להמשיך"}</DialogTitle>

                <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <FileViewer
                        fileType={'docx'}
                        filePath={doc}
                    />
                </DialogContent>
                <DialogActions style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button disabled={!enableButton} onClick={handleApprove} className={enableButton ? classes.agreeButton : classes.disableButton}>
                        קראתי ומאשר/מאשרת
                    </Button>
                    <span style={{ fontSize: 12, marginBottom: 8 }}>יש לגלול על סוף העמוד על מנת לאשר את החוזה</span>
                </DialogActions>
            </Dialog>
        </div>
    );
}
