import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Chip from '@material-ui/core/Chip';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';

import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import Autocomplete from '@material-ui/lab/Autocomplete';

import '../../../../node_modules/antd/dist/antd.css';
const names = [
    'הריון ולקראת לידה',
    'הנקה',
    'מוזיקה ותנועה',
    'התפתחות הבייבי',
    'כושר ותזונה לאמא',
    'כיף עם הבייבי',
    'טיפים וכלים להורים',
    'תזונת תינוקות',
    'להכיר חברות חדשות'
];

const ageRangeArray = [
    { label: '0-3 חודשים', value: '0-3' },
    { label: '3-6 חודשים', value: '3-6' },
    { label: '6-9 חודשים', value: '6-9' },
    { label: '9-12 חודשים', value: '9-12' },
    { label: '12-24 חודשים', value: '12-24' },
    { label: '24+ חודשים', value: '24+' },
    { label: 'לאמא בהריון', value: 'הריון' },
    { label: 'לאמא ולבייבי', value: 'אמא ובייבי' },
    { label: 'לאמא', value: 'אמא' },
    { label: 'כולם', value: 'כולם' },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, tags, theme) {
    return {
        fontWeight:
            tags.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
}));


export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [tags, setTags] = React.useState([]);
    const [errorTags, setErrorTags] = React.useState(false);

    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({ interests: [], link: '' });
    const [errorLink, setErrorLink] = React.useState(false);
    const [errorAgeRange, setErrorAgeRange] = React.useState(false);
    const [ageRange, setAgeRange] = React.useState([]);
    const [withLink, setWithLink] = React.useState(false);



    const handleChangeAgeRange = event => {
        setAgeRange(event.target.value);
    };

    React.useEffect(() => {
        // Update the document title using the browser API
        console.log(props.storyData)
        setState({ ...state, interests: props.storyData.interests, link: props.storyData.link })
        setTags(props.storyData.interests)
        setAgeRange(props.storyData.ages)
        if (props.storyData.link) {
            setWithLink(true)
        }
    }, []);

    const handleClose = () => {
        props.setOpenEdit(false)
    };

    const handleChange = (e, v) => {
        setTags(v)
        setState({ ...state, interests: v })
    }

    const edit = () => {
        setLoading(true)
        let data = state
        console.log(data)
        // axios.post('http://localhost:8080/api/stories/' + props.id + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            axios.post('https://jama-server.appspot.com/api/stories/' + props.id + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            setLoading(false)
            props.getStories()
            props.setOpenEdit(false)
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleChangeCheckbox = (event) => {
        if (event.target.name === 'withLink') {
            setWithLink(true)
        } else {
            setWithLink(false)
        }
    };

    return (
        <React.Fragment>
            <Dialog
                fullWidth={'sm'}
                maxWidth={'sm'}
                open={props.openEdit}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'right' }}>עריכת סטורי</DialogTitle>
                <DialogContent>


                    <div style={{ width: '100%', height: 50 }}>
                        <FormControlLabel style={{ textAlign: 'right', direction: 'rtl', float: 'right' }}
                            control={
                                <Checkbox
                                    checked={withLink}
                                    onChange={(e) => handleChangeCheckbox(e)}
                                    name="withLink"
                                />
                            }
                            label="עם לינק"
                        />
                    </div>
                    <div style={{ width: '100%', height: 50 }}>
                        <FormControlLabel style={{ textAlign: 'right', direction: 'rtl', float: 'right' }}
                            control={
                                <Checkbox
                                    checked={!withLink}
                                    onChange={(e) => handleChangeCheckbox(e)}
                                    name="withoutLink"
                                />
                            }
                            label="ללא לינק"
                        />
                    </div>
                    {withLink && (
                        <div>
                            <div className={classes.inputTitle}>לינק:</div>
                            <input className="textinput" value={state.link} onChange={(e) => setState({ ...state, link: e.target.value })} />
                        </div>
                    )}


                    {errorLink && (<div className={classes.errorMessage}>שדה חובה</div>)}
                    <div className={classes.inputTitle}>קטגוריה:</div>
                    <Autocomplete
                        style={{ border: 'solid 1px rgba(73, 155, 234, 1)', borderRadius: 30, height: 55, paddingLeft: 20, paddingRight: 8, paddingTop: 12, width: '100%', marginTop: 50 }}
                        multiple
                        id="tags-filled"
                        options={names.map(option => option)}
                        freeSolo
                        value={state.interests}
                        onChange={handleChange}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} style={{ padding: 8 }} />
                            ))
                        }
                        renderInput={params => (
                            <TextField {...params} className="textinput" InputProps={{ ...params.InputProps, disableUnderline: true }} label="" placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)" style={{ minHeight: 55, borderSize: 0, marginTop: -5 }} />
                        )}
                    />
                    {errorTags && (<div className={classes.errorMessage}>שדה חובה</div>)}



                    <div className={classes.inputTitle}>טווחי גילאים:</div>
                    <FormControl className={classes.formControl} style={{ border: 'solid 1px rgba(73, 155, 234, 1)', borderRadius: 30, height: 55, paddingLeft: 20, paddingRight: 8, paddingTop: 12, width: '100%' }}>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            disableUnderline
                            id="demo-mutiple-chip"
                            multiple
                            value={ageRange}
                            onChange={handleChangeAgeRange}
                            input={<Input id="select-multiple-chip" style={{ direction: 'rtl', marginTop: -10, minHeight: 50 }} />}
                            renderValue={selected => (
                                <div className={classes.chips}>
                                    {selected.map(value => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {ageRangeArray.map(step => (
                                <MenuItem key={step.value} value={step.value} style={getStyles(step.value, ageRangeArray, theme)}>
                                    {step.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {errorAgeRange && (<div className={classes.errorMessage}>שדה חובה</div>)}


                    <center>
                        <Button className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => edit()}>
                            {loading ? <CircularProgress size={25} /> : 'עריכת סטורי'}
                        </Button>
                    </center>
                </DialogContent>
                <DialogActions style={{ width: '100%', textAlign: 'left' }}>
                    <Button onClick={handleClose} style={{ float: 'left' }} color="primary">
                        סגור
          </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
