import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';


const columns = [
  {
    id: 'profileImage',
    label: 'תמונת פרופיל',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'name',
    label: 'שם מלא',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'phone',
    label: 'טלפון',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'email',
    label: 'מייל',
    minWidth: 80,
    maxWidth: 80,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'status',
    label: 'סטטוס',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'details',
    label: 'פרטים',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'engagement',
    label: 'התקשרות',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  }
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 25,
    boxShadow: "0px 8px 20px -2px rgba(0,0,0,0.10)",
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.5)',
  },
  tableWrapper: {
    maxHeight: '100%',
    overflow: 'auto',
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.main.state.sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === 'status' && (
                          <div>
                            {value === 'active' ? (
                              <Button style={{ textTransform: 'none', color: 'rgb(135,202,185)', backgroundColor: 'rgb(221,249,237)', width: 70, height: 35, borderRadius: 17.5, fontSize: 14 }}>Active</Button>
                            ) : (
                                <Button style={{ textTransform: 'none', color: 'rgb(239,121,114)', backgroundColor: 'rgb(255,221,225)', width: 70, height: 35, borderRadius: 17.5, fontSize: 14 }}>Inactive</Button>
                              )}
                          </div>
                        )}
                        {column.id === 'profileImage' && (
                          <div>
                            {(value !== null && value !== 'N/A') ? (
                              <img alt='' src={value} width="40px" height="40px" style={{ borderRadius: 20, objectFit: 'cover' }} />
                            ) : (
                                <div style={{ width: 40, height: 40, backgroundColor: 'white', borderRadius: 20 }}>
                                  <img alt='' src={require('../../../Assets/Images/profileImage.png')} width="40px" height="40px" style={{ borderRadius: 20 }} />
                                </div>
                              )}
                          </div>
                        )}
                        {column.id === 'details' && (
                          <Button onClick={() => props.main.setState({ orderDetails: row }, () => { props.main.setState({ paymentsHistory: true }); props.main.handleSelectedVendor(row) })} style={{ textTransform: 'none', color: 'rgb(255,255,255)', background: "linear-gradient(to right , #FCA6BC, #FF8FAC)", width: 80, height: 35, borderRadius: 17.5, fontSize: 14 }}>פרטים</Button>
                        )}
                        {(column.id !== "details" && column.id !== "status" && column.id !== "profileImage") && (
                          <div>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </div>
                        )}
                        {(column.id === "engagement") && (
                          <Button onClick={() => props.main.handleSelectedEngagement(row)} style={{ textTransform: 'none', color: 'rgb(255,255,255)', background: "linear-gradient(to right , #FCA6BC, #FF8FAC)", width: 80, height: 35, borderRadius: 17.5, fontSize: 14 }}>התקשרויות</Button>

                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={props.main.state.sortedUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
