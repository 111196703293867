import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {Button, Select, FormControl, Input, MenuItem } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";

import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "../../../../node_modules/antd/dist/antd.css";
const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const ageRangeArray = [
    { label: "0-3 חודשים", value: "0-3" },
    { label: "3-6 חודשים", value: "3-6" },
    { label: "6-9 חודשים", value: "6-9" },
    { label: "9-12 חודשים", value: "9-12" },
    { label: "12-24 חודשים", value: "12-24" },
    { label: "24+ חודשים", value: "24+" },
    { label: "לאמא בהריון", value: "הריון" },
    { label: "לאמא ולבייבי", value: "אמא ובייבי" },
    { label: "לאמא", value: "אמא" },
    { label: "כולם", value: "כולם" },
  ];

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [tags, setTags] = useState([]);
  const [errorTags, setErrorTags] = useState(false);
  const [supplier, setSupplier] = useState();
  const [errorCatalogNumber, setErrorCatalogNumber] = useState(false);
  const [errorImage, setErrorImage] = useState(false);
  const [errorBrand, setErrorBrand] = useState(false);
  const [coverImage, setCoverImage] = useState();
  const [loading, setLoading] = useState(false);
  const [ageRange, setAgeRange] = useState([]);
  const [state, setState] = useState({
    title: "",
    description: "",
    interests: [],
    price: "",
    link: "",
    priceAfterDiscount: "",
    brand: "",
    catalogNumber: "",
    supplier: {},
    ages: []
  });
  const [errorTitle, setErrorTitle] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorLink, setErrorLink] = useState(false);
  const [errorPriceAfterDiscount, setErrorPriceAfterDiscount] = useState(false);
  const [errorAgeRange, setErrorAgeRange] = useState(false);
  const theme = useTheme();


  const text = {
    chosePublisher: "בחר מפרסם (ג׳אמה או ספק)",
    catalogNumber: 'מק"ט:',
    mainPicture: "העלאת תמונה ראשית:",
    title: "כותרת המוצר:",
    brand: "מותגספק:",
    description: "תיאור המוצר:",
    price: "מחיר:",
    priceAfterDiscount: "מחיר לאחר הנחה:",
    link: "לינק לרכישה:",
    productInterests: "תחומי מוצר",
    ages: "טווחי גילאים",
  };

  function getStyles(name, tags, theme) {
    return {
      fontWeight:
        tags.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChangeAgeRange = (event) => {
    setState({...state, ages: event.target.value})
  };

  React.useEffect(() => {
    // Update the document title using the browser API
    console.log(props.productData);
    setState({
      ...state,
      title: props.productData.title,
      description: props.productData.description,
      interests: props.productData.interests,
      price: props.productData.price,
      link: props.productData.link,
      priceAfterDiscount: props.productData.priceAfterDiscount,
      brand: props.productData.brand,
      catalogNumber: props.productData.catalogNumber,
      supplier: props.productData.supplier,
      ages: props.productData.ages
    });
    setTags(props.productData.interests);
  }, []);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const handleChange = (e, v) => {
    setTags(v);
    setState({ ...state, interests: v });
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const handleChangeSupplier = (event, v) => {
    console.log("v", v);
    setState({ ...state, supplier: v });
  };

  const edit = () => {
    setLoading(true);
    let data = state;
    console.log(data);
    // axios.post('http://localhost:8080/api/products/' + props.id + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
    axios
      .post(
        "https://jama-server.appspot.com/api/products/" + props.id + "/update",
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        setLoading(false);
        props.getProducts();
        props.setOpenEdit(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={props.openEdit}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right" }}>
          עריכת מוצר
        </DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>בחר מפרסם (ג׳אמה או ספק)</div>
          <Autocomplete
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
              textAlign: "right",
              direction: "rtl",
            }}
            value={state.supplier}
            options={props.suppliers}
            getOptionLabel={(option) => option.name}
            onChange={handleChangeSupplier}
            id="tags-filled"
            renderInput={(params) => (
              <TextField
                {...params}
                className="textinput"
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
              />
            )}
          />
          <div className={classes.inputTitle}>מק"ט:</div>
          <input
            value={state.catalogNumber}
            className="textinput"
            onChange={(e) =>
              setState({ ...state, catalogNumber: e.target.value })
            }
          />
          {errorCatalogNumber && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}
          <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
          <input
            className="textinput"
            type="file"
            id="single"
            onChange={onChange}
            style={{ paddingTop: 13, paddingRight: 20 }}
          />
          {errorImage && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>כותרת המוצר:</div>
          <input
            className="textinput"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          {errorTitle && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>מותג\ספק:</div>
          <input
            value={state.brand}
            className="textinput"
            onChange={(e) => setState({ ...state, brand: e.target.value })}
          />
          {errorBrand && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>תיאור המוצר:</div>
          <textarea
            className="textarea_box"
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            cols="40"
            rows="5"
          />
          {errorDescription && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}
          <div className={classes.inputTitle}>מחיר:</div>
          <input
            className="textinput"
            value={state.price}
            onChange={(e) => setState({ ...state, price: e.target.value })}
            cols="40"
            rows="5"
          />
          {errorPrice && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>מחיר לאחר הנחה:</div>
          <input
            value={state.priceAfterDiscount}
            className="textinput"
            onChange={(e) =>
              setState({ ...state, priceAfterDiscount: e.target.value })
            }
          />
          {errorPriceAfterDiscount && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}
          <div className={classes.inputTitle}>לינק לרכישה:</div>
          <input
            className="textinput"
            value={state.link}
            onChange={(e) => setState({ ...state, link: e.target.value })}
            cols="40"
            rows="5"
          />
          {errorLink && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>תחומי מוצר:</div>
          <Autocomplete
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: "100%",
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
            multiple
            id="tags-filled"
            options={names.map((option) => option)}
            freeSolo
            value={state.interests}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  style={{ padding: 8 }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="textinput"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                label=""
                placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                style={{ minHeight: 55, borderSize: 0, marginTop: -5, height: '100%' }}
              />
            )}
          />
          {errorTags && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>טווחי גילאים</div>
              <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={state.ages}
                  onChange={handleChangeAgeRange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "rtl",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ageRangeArray.map((step) => (
                    <MenuItem
                      key={step.value}
                      value={step.value}
                      style={getStyles(step.value, ageRangeArray, theme)}
                    >
                      {step.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

          <center>
            <Button
              className={"done-button"}
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => edit()}
            >
              {loading ? <CircularProgress size={25} /> : "עריכת מוצר"}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: "100%", textAlign: "left" }}>
          <Button
            onClick={handleClose}
            style={{ float: "left" }}
            color="primary"
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
