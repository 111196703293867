import React from "react";
import { withStyles, Tab, Badge, Tabs, Paper } from "@material-ui/core";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";

// Material
import { SnackbarProvider } from "notistack";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// Components
import Product from "./Product";
import NewProduct from "./NewProduct";
import Skeleton from "./Skeleton";
import DeleteDialog from "./DeleteDialog";

import InfiniteScroll from "react-infinite-scroller";

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100vw",
    height: "100vh",
  },
  success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
  h2: {
    float: "left",
    textWeight: "bold",
    display: "inline-block",
    textAlign: "left",
    color: "rgb(100,100,100)",
    fontSize: 20,
  },
  fab: {
    position: "fixed",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: "left",
    textAlign: "left",
  },
});

class Products extends React.Component {
  state = {
    isLoading: true,
    openNewProduct: false,
    products: [],
    openPreviewProduct: false,
    openEditProduct: false,
    openDeleteDialog: false,
    currentProduct: { components: [] },
    currentProductId: null,
    deleting: false,
    adding: false,
    updating: false,
    previewLocal: false,
    openRegistrations: true,
    options: [],
    alreadyUpdated: null,
    type: 1,
    productsToShow: [],
    productsCounter: 0,
    sortBy: "",
    sortData: "",
    sortedProducts: [],
    formattedProducts: [],
    pending: [],
    approved: [],
  };

  componentWillMount() {
    if (
      localStorage.getItem("suppliers") === undefined ||
      localStorage.getItem("suppliers") === null
    ) {
      axios
        .get("https://jama-server.appspot.com/api/suppliers")
        .then((response) => {
          const arr = response.data;
          const searchArray = [];
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            searchArray.push({
              name:
                element.firstName.toLowerCase() +
                " " +
                element.lastName.toLowerCase(),
              id: element.id,
              type: "supplier",
              profileImage: element.profileImage,
            });
          }
          searchArray.push({ name: "Jama", type: "jama" });
          searchArray.reverse();
          this.setState({ options: searchArray });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } else {
      const arr = JSON.parse(localStorage.getItem("suppliers"));
      const searchArray = [];
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        searchArray.push({
          name:
            element.firstName.toLowerCase() +
            " " +
            element.lastName.toLowerCase(),
          id: element.id,
          type: "supplier",
          profileImage: element.profileImage,
        });
      }
      searchArray.push({ name: "Jama", type: "jama" });
      searchArray.reverse();
      this.setState({ options: searchArray });
    }
  }

  handleChangeType = (event, newValue) => {
    if (newValue == undefined) {
      newValue = 1;
    }
    this.setState({
      type: newValue,
      productsToShow: [],
      productsCounter: 0,
      sortData: "",
      sortBy: "",
    });
    if (newValue === 0) {
      this.setState({ sortedProducts: this.state.pending });
      this.setState({ type: newValue });
    } else if (newValue === 1) {
      this.setState({ sortedProducts: this.state.approved });
      this.setState({ type: newValue });
    }
  };

  getProducts = () => {
    if (this.state.alreadyUpdated !== null) return;
    axios
      .get("https://jama-server.appspot.com/api/products")
      .then((response) => {
        var products = response.data;
        products.sort(
          (a, b) =>
            new Date(b.createdAt._seconds * 1000).getTime() -
            new Date(a.createdAt._seconds * 1000).getTime()
        );
        products.reverse();
        this.setState({ products: products });
        let tempPendingArr = [];
        let tempApprovedArr = [];
        products.map((product) => {
          if (product.status === "pending") {
            tempPendingArr.push(product);
          } else if (product.status === "approved") {
            tempApprovedArr.push(product);
          }
        });
        this.setState({
          products: products,
          sortedProducts: products,
          isLoading: false,
          approved: tempApprovedArr,
          pending: tempPendingArr,
        });
        this.handleChangeType();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  componentDidMount() {
    this.getProducts();
  }

  openPreviewProduct = (data, local) => {
    this.setState({ currentProduct: data, previewLocal: local }, () => {
      this.setState({ openPreviewProduct: true });
    });
  };

  closePreviewProduct = () => {
    this.setState({ openPreviewProduct: false, previewLocal: false });
  };

  deleteProduct = () => {
    this.setState({ deleting: true });
    axios
      .post(
        "https://jama-server.appspot.com/api/Products/" +
          this.state.currentProductId +
          "/delete"
      )
      .then((response) => {
        this.setState({ deleting: false, openDeleteDialog: false });
        this.getProducts();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  updateProduct = (data, field, value) => {
    console.log("update the product", data, field, value);

    if (field !== "publish") {
      const products = [...this.state.products];
      const pendingProducts = [];
      const approvedProducts = [];
      products.forEach((product, index) => {
        if (product.id === data.id) {
          products[index][field] = value;
          this.setState({ products: products }, () => {
            products.map((product) => {
              if (product.status === "approved") approvedProducts.push(product);
              else if (product.status === "pending")
                pendingProducts.push(product);
            });
          });
        }
        this.setState({
          approved: approvedProducts,
          pending: pendingProducts,
          sortedProducts: pendingProducts,
        });
      });
    }

    axios
      .post(
        "https://jama-server.appspot.com/api/products/" + data.id + "/update",
        { [field]: value },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        console.log("res", response);
        this.getProducts();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  setSelectedProduct = (data, typeOfAction) => {
    if (typeOfAction === "preview") {
      this.setState({ openPreviewProduct: true, currentProduct: data });
    } else {
      this.setState({ openEditProduct: true, currentProduct: data });
    }
  };

  loadMoreProducts = () => {
    if (!this.state.productsToShow) return;
    this.setState({
      productsToShow: [
        ...this.state.productsToShow,
        ...this.state.sortedProducts.slice(
          this.state.productsCounter,
          this.state.productsCounter + 10
        ),
      ],
      productsCounter: this.state.productsCounter + 10,
    });
  };

  handleChangeSortBy = (event) => {
    this.setState({ sortBy: event.target.value, sortData: "" });
  };

  handleChangeText = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    if (name === "sortData") {
      this.sortData(event.target.value);
    }
  };

  sortData = (text) => {
    if (!text) {
      this.setState({ sortedProducts: this.state.products });
      return;
    }

    const data = this.state.products;
    let sortedArray = [];

    switch (this.state.sortBy) {
      case "שם ספק":
        sortedArray = data.filter((product) => {
          return product.supplier.name.includes(text);
        });
        break;
      case "שם מוצר":
        sortedArray = data.filter((product) => {
          return product.title.includes(text);
        });
        break;
      case "שם מותג":
        sortedArray = data.filter((product) => {
          return (
            product.brand.toLowerCase().includes(text) ||
            product.brand.includes(text)
          );
        });
        break;
    }
    this.setState({ sortedProducts: sortedArray });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        <NewProduct main={this} />
        <DeleteDialog main={this} />
        {this.state.isLoading === true ? (
          <div>
            <Skeleton />
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", marginTop: 0, marginBottom: 10 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    מיין לפי
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sortBy}
                    onChange={this.handleChangeSortBy}
                  >
                    <MenuItem value={"שם ספק"}>שם ספק</MenuItem>
                    <MenuItem value={"שם מוצר"}>שם מוצר</MenuItem>
                    <MenuItem value={"שם מותג"}>שם מותג</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="standard-basic"
                  label={
                    this.state.sortBy === "" ? "בחר ערך" : this.state.sortBy
                  }
                  className={classes.formControl}
                  onChange={this.handleChangeText("sortData")}
                  value={this.state.sortData}
                />
              </div>
              <Paper
                className={classes.root}
                elevation={0}
                style={{
                  background: "none",
                  marginBottom: 20,
                  flexGrow: 1,
                  minWidth: 100,
                  width: "100%",
                  height: 50,
                }}
              >
                <Tabs
                  value={this.state.type}
                  onChange={this.handleChangeType}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.pending.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          ממתין לאישור
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.approved.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          עברו אישור
                        </div>
                      </Badge>
                    }
                  />
                </Tabs>
              </Paper>
            </div>
            <InfiniteScroll
              pageStart={this.state.productsCounter}
              loadMore={this.loadMoreProducts}
              hasMore={
                this.state.productsCounter < this.state.sortedProducts.length
              }
              style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
              loader={<div className="loader"></div>}
            >
              <Grid container spacing={mobile ? 2 : 4}>
                <div style={{ width: "100%" }}></div>
                {this.state.sortedProducts.map((product, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Product
                      getProducts={this.getProducts}
                      data={product}
                      setSelectedProduct={this.setSelectedProduct}
                      main={this}
                    />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </SnackbarProvider>
        )}
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => this.setState({ openNewProduct: true })}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

Products.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Products));
