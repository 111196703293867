import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const text = {
  activities: "פעילויות",
  signups: "רשומים לפעילויות",
};

function CardActivities(data) {
  const { totalActivities, totalSignups } = data;
  return (
    <>
      <Card>
        <CardContent align="right">
          <Typography variant="h6" component="div">
            <span
              style={{
                fontSize: "1.2em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              פעילויות
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontSize: "1em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
              }}
            >
              {text.activities}:{" "}
            </span>
            <span
              style={{
                fontSize: "1.2em",
                color: "#99a1a9",
              }}
            >
              {totalActivities ? totalActivities : <CircularProgress />}
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontSize: "1em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
              }}
            >
              {text.signups}:{" "}
            </span>
            <span
              style={{
                fontSize: "1.2em",
                color: "#99a1a9",
              }}
            >
              {totalSignups ? totalSignups : <CircularProgress />}
            </span>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default CardActivities;
