import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import "../../../../node_modules/antd/dist/antd.css";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
  },
  root: {
    "& .MuiSelect-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiInputLabel-icon": {
      float: "left",
      direction: "ltr",
    },
    "& .MuiSelect-select": {
      direction: "ltr",
      float: "left",
      backgroundColor: "transparent",
    },
    "& MuiSelect-selectMenu": {
      direction: "ltr",
    },
    ".MuiTextField-root": {
      borderSize: 0,
      border: 1,
    },
    "& .MuiAutocomplete-tag": {
      marginLeft: 90,
    },
    deleteIcon: {
      marginLeft: 20,
    },
  },
  content: {
    padding: 30,
  },
  title: {
    fontFamily: "'Varela Round', sans-serif",
    float: "left",
  },
  publish: {
    backgroundColor: "white",
    color: "black",
    padding: 8,
    borderRadius: 5,
  },
  textField: {
    marginTop: 15,
    direction: "ltr",
  },
  pageTitle: {
    textAlign: "center",
    fontFamily: "'Varela Round', sans-serif",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
  formControl: {
    minWidth: 120,
    direction: "ltr",
    width: "100%",
  },
  publishButton: {
    margin: `${theme.spacing(1)}px 0`,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  cancelAddItemButton: {
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 25px -8px rgba(0, 0, 0, 0.5)",
    borderRadius: 50,
    fontFamily: "'Varela Round', sans-serif",
    color: "#c21ed6",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

const ageRangeArray = [
  { label: "0-3 חודשים", value: "0-3" },
  { label: "3-6 חודשים", value: "3-6" },
  { label: "6-9 חודשים", value: "6-9" },
  { label: "9-12 חודשים", value: "9-12" },
  { label: "12-24 חודשים", value: "12-24" },
  { label: "24+ חודשים", value: "24+" },
  { label: "לאמא בהריון", value: "הריון" },
  { label: "לאמא ולבייבי", value: "אמא ובייבי" },
  { label: "לאמא", value: "אמא" },
  { label: "כולם", value: "כולם" },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [catalogNumber, setCatalogNumber] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [ageRange, setAgeRange] = React.useState([]);
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorBrand, setErrorBrand] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorTags, setErrorTags] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [errorCatalogNumber, setErrorCatalogNumber] = React.useState(false);
  const [errorPrice, setErrorPrice] = React.useState(false);
  const [errorPriceAfterDiscount, setErrorPriceAfterDiscount] = React.useState(
    false
  );
  const [errorLink, setErrorLink] = React.useState(false);
  const [price, setPrice] = React.useState(0);
  const [priceAfterDiscount, setPriceAfterDiscount] = React.useState(0);
  const [coverImage, setCoverImage] = React.useState(null);
  const [brand, setBrand] = React.useState("");
  const [state, setState] = React.useState({
    title: "",
    description: "",
    link: "",
  });
  const [supplier, setSupplier] = React.useState(null);

  const resetParams = () => {
    setTags([]);
    setAgeRange([]);
    setCoverImage(null);
    setState({ title: "", description: "", link: "" });
    setPrice(0);
  };

  const handleChange = (e, v) => {
    setTags(v);
  };

  const handleChangeAgeRange = (event) => {
    setAgeRange(event.target.value);
  };

  const publish = async () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorTags(false);
    setErrorAgeRange(false);
    setErrorImage(false);
    setErrorDescription(false);
    setErrorPrice(false);
    setErrorPriceAfterDiscount(false);
    setErrorCatalogNumber(false);
    setErrorBrand(false);
    if (catalogNumber === "" || !catalogNumber) {
      setErrorCatalogNumber(true);
      allowToContinue = false;
    }
    if (coverImage === null) {
      setErrorImage(true);
      allowToContinue = false;
    }
    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }
    if (brand === "" || !brand) {
      setErrorBrand(true);
      allowToContinue = false;
    }
    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }
    if (tags.length === 0) {
      setErrorTags(true);
      allowToContinue = false;
    }
    if (ageRange.length === 0) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }
    if (state.link === "") {
      setErrorLink(true);
      allowToContinue = false;
    }
    if (price === 0) {
      setErrorPrice(true);
      allowToContinue = false;
    }
    if (priceAfterDiscount === 0) {
      setErrorPriceAfterDiscount(true);
      allowToContinue = false;
    }

    if (allowToContinue) {
      var data = {
        brand: brand,
        title: state.title,
        description: state.description,
        link: state.link,
        interests: tags,
        ages: ageRange,
        promote: false,
        price: price,
        priceAfterDiscount: priceAfterDiscount,
        publish: false,
        status: "pending",
        catalogNumber: catalogNumber,
        supplier: supplier,
      };
      saveDataInDatabase(data);
    }
  };

  const handleChangeSupplier = (event, v) => {
    setSupplier(v);
  };

  const saveDataInDatabase = (data) => {
    props.main.setState({ adding: true });
    var form = new FormData();
    form.append("photos", coverImage, coverImage.name);

    const newData = JSON.stringify(data);

    form.append("data", newData);

    for (var key of form.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    // axios.post('http://localhost:8080/api/products/create', form, {
    axios
      .post("https://jama-server.appspot.com/api/products/create", form, {
        headers: {
          "content-type": "multipart/form-data", // do not forget this
        },
      })
      .then((response) => {
        props.main.setState({ adding: false, openNewProduct: false });
        props.main.getProducts();
        resetParams();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen
        open={props.main.state.openNewProduct}
        onClose={() => props.main.setState({ openNewProduct: false })}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.main.setState({ openNewProduct: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.pageTitle}>
              העלאת מוצר
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container spacing={2} justify="center">
            <Grid item sm={12} md={10} lg={8} xl={8}>
              <div className={classes.inputTitle}>בחר מפרסם (ג׳אמה או ספק)</div>
              <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                  textAlign: "right",
                  direction: "rtl",
                }}
                options={props.main.state.options}
                getOptionLabel={(option) => option.name}
                onChange={handleChangeSupplier}
                id="tags-filled"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="בחר מפרסם"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              />
              <div className={classes.inputTitle}>מק"ט:</div>
              <input
                className="textinput"
                onChange={(e) => setCatalogNumber(e.target.value)}
              />
              {errorCatalogNumber && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
              <input
                className="textinput"
                type="file"
                id="single"
                onChange={onChange}
                style={{ paddingTop: 13, paddingRight: 20 }}
              />
              {errorImage && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>כותרת המוצר:</div>
              <input
                className="textinput"
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
              {errorTitle && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>מותג\ספק:</div>
              <input
                className="textinput"
                onChange={(e) => setBrand(e.target.value)}
              />
              {errorBrand && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>תיאור המוצר:</div>
              <textarea
                className="textarea_box"
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
                cols="40"
                rows="5"
              />
              {errorDescription && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>מחיר:</div>
              <input
                className="textinput"
                onChange={(e) => setPrice(e.target.value)}
              />
              {errorPrice && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>מחיר לאחר הנחה:</div>
              <input
                className="textinput"
                onChange={(e) => setPriceAfterDiscount(e.target.value)}
              />
              {errorPriceAfterDiscount && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>לינק לרכישה:</div>
              <input
                className="textinput"
                onChange={(e) => setState({ ...state, link: e.target.value })}
              />
              {errorLink && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}

              <div className={classes.inputTitle}>תחומי מוצר</div>
              <Autocomplete
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
                multiple
                id="tags-filled"
                options={names.map((option) => option)}
                freeSolo
                onChange={handleChange}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                      style={{ padding: 8 }}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="textinput"
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    label=""
                    placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                    style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
                  />
                )}
              />
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>טווחי גילאים</div>
              <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={ageRange}
                  onChange={handleChangeAgeRange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "rtl",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ageRangeArray.map((step) => (
                    <MenuItem
                      key={step.value}
                      value={step.value}
                      style={getStyles(step.value, ageRangeArray, theme)}
                    >
                      {step.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <center>
                <Button className={"done-button"} onClick={() => publish()}>
                  {props.main.state.adding ? (
                    <CircularProgress size={25} />
                  ) : (
                    "העלאת מוצר"
                  )}
                </Button>
              </center>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
