import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

// Components
import Video from './Video';
import NewVideo from './NewVideo';
import Skeleton from './Skeleton';
import DeleteDialog from './DeleteDialog';

import InfiniteScroll from 'react-infinite-scroller';

const mobile =
  typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
  },
  success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
  h2: {
    float: 'left',
    textWeight: 'bold',
    display: 'inline-block',
    textAlign: 'left',
    color: 'rgb(100,100,100)',
    fontSize: 20,
  },
  newVideo: {
    background: 'linear-gradient(to right , #438BEC, #57CBF2)',
    color: 'white',
  },
  fab: {
    position: 'fixed',
    background: 'linear-gradient(to right , #438BEC, #57CBF2)',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: 'left',
    textAlign: 'left',
  },
});

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000);

  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  return (
    datevalues[2] +
    '/' +
    datevalues[1] +
    '/' +
    datevalues[0] +
    ' ' +
    datevalues[3] +
    ':' +
    datevalues[4]
  );
};

class Videos extends React.Component {
  state = {
    isLoading: true,
    openNewVideo: false,
    videos: [],
    openDeleteDialog: false,
    deleting: false,
    adding: false,
    updating: false,
    previewLocal: false,
    currentVideoId: null,
    options: [],
    videosToShow: [],
    videosCounter: 0,
    sortBy: '',
    sortData: '',
    sortedVideos: [],
    formattedVideos: [],
  };

  componentWillMount() {
    // const arr = JSON.parse(localStorage.getItem("suppliers"))
    // const searchArray = []
    // for (let index = 0; index < arr.length; index++) {
    //   const element = arr[index];
    //   searchArray.push({name: element.firstName.toLowerCase() + ' ' + element.lastName.toLowerCase(), id: element.id, type: 'supplier', profileImage: element.profileImage})
    // }
    // searchArray.push({name: 'Jama', type: 'jama'})
    // searchArray.reverse()
    // this.setState({'options': searchArray})
    if (
      localStorage.getItem('suppliers') === undefined ||
      localStorage.getItem('suppliers') === null
    ) {
      axios
        .get('https://jama-server.appspot.com/api/suppliers')
        .then((response) => {
          const arr = response.data;
          const searchArray = [];
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            searchArray.push({
              name: element.firstName.toLowerCase() + ' ' + element.lastName.toLowerCase(),
              id: element.id,
              type: 'supplier',
              profileImage: element.profileImage,
            });
          }
          searchArray.push({ name: 'Jama', type: 'jama' });
          searchArray.reverse();
          this.setState({ options: searchArray });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } else {
      const arr = JSON.parse(localStorage.getItem('suppliers'));
      const searchArray = [];
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        searchArray.push({
          name: element.firstName.toLowerCase() + ' ' + element.lastName.toLowerCase(),
          id: element.id,
          type: 'supplier',
          profileImage: element.profileImage,
        });
      }
      searchArray.push({ name: 'Jama', type: 'jama' });
      searchArray.reverse();
      this.setState({ options: searchArray });
    }
  }

  getVideos = () => {
    axios
      .get('https://jama-server.appspot.com/api/videos')
      .then((response) => {
        console.log(response.data);
        let videos = response.data;
        videos.sort(
          (a, b) =>
            new Date(b.createdAt._seconds * 1000).getTime() -
            new Date(a.createdAt._seconds * 1000).getTime()
        );
        this.setState({
          videos: videos,
          isLoading: false,
          sortedVideos: videos,
          formattedVideos: videos,
        });
        console.log(this.state.sortedVideos, 'sortedVideos');
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  componentDidMount() {
    this.getVideos();
  }

  deleteVideo = () => {
    this.setState({ deleting: true });
    console.log(this.state.currentVideoId);
    axios
      .post('https://jama-server.appspot.com/api/videos/' + this.state.currentVideoId + '/delete')
      .then((response) => {
        console.log(response.data);
        this.setState({ deleting: false, openDeleteDialog: false });
        this.getVideos();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  updateVideo = (id, field, value) => {
    const videos = this.state.videos;
    videos.forEach((video, index) => {
      if (video.id === id) {
        videos[index][field] = value;
        this.setState({ videos: videos });
      }
    });

    axios
      .post(
        'https://jama-server.appspot.com/api/videos/' + id + '/update',
        { [field]: value },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  loadMoreVideos = () => {
    if (!this.state.videosToShow) return;
    this.setState({
      videosToShow: [
        ...this.state.videosToShow,
        ...this.state.sortedVideos.slice(this.state.videosCounter, this.state.videosCounter + 10),
      ],
      videosCounter: this.state.videosCounter + 10,
    });
  };

  handleChangeSortBy = (event) => {
    this.setState({
      sortBy: event.target.value,
      sortData: '',
      sortedVideos: this.state.formattedVideos,
    });
  };

  handleChangeText = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    if (name === 'sortData') {
      this.sortData(event.target.value);
    }
  };

  sortData = (text) => {
    if (text === '') {
      this.setState({
        sortedVideos: this.state.formattedVideos,
        videosCounter: 0,
        videosToShow: [],
      });
      return;
    }
    const sortTypeText = this.state.sortBy;
    var sortId = '';
    if (sortTypeText === 'תאריך') {
      sortId = 'date';
    } else if (sortTypeText === 'כותרת') {
      sortId = 'title';
    } else if (sortTypeText === 'תיאור') {
      sortId = 'description';
    } else if (sortTypeText === 'שם מפעילה') {
      sortId = 'name';
    }
    const data = this.state.formattedVideos;
    const sortedArray = [];
    for (var i = 0; i < data.length; i++) {
      let field;
      if (sortId === 'date') {
        const dateAsString = getDate(data[i].createdAt);
        field = dateAsString.toLowerCase();
      } else if (sortId === 'name') {
        field =
          typeof data[i]['publisher'][sortId] === 'string'
            ? data[i]['publisher'][sortId].toLowerCase()
            : toString(data[i]['publisher'][sortId]);
      } else {
        field =
          typeof data[i][sortId] === 'string'
            ? data[i][sortId].toLowerCase()
            : toString(data[i][sortId]);
      }
      if (field.includes(text.toLowerCase())) {
        sortedArray.push(data[i]);
      }
    }
    this.setState({ sortedVideos: sortedArray, videosCounter: 0, videosToShow: [] });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className='root'>
        <NewVideo main={this} />
        <DeleteDialog main={this} />
        {this.state.isLoading === true ? (
          <div>
            <Skeleton />
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <div style={{ width: '100%', marginTop: 0, marginBottom: 10 }}>
              <FormControl className={classes.formControl}>
                <InputLabel id='demo-simple-select-label'>מיין לפי</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={this.state.sortBy}
                  onChange={this.handleChangeSortBy}
                >
                  <MenuItem value={'שם מפעילה'}>שם מפעילה</MenuItem>
                  <MenuItem value={'כותרת'}>כותרת</MenuItem>
                  <MenuItem value={'תיאור'}>תיאור</MenuItem>
                  <MenuItem value={'תאריך'}>תאריך</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id='standard-basic'
                label={this.state.sortBy === '' ? 'בחר ערך' : this.state.sortBy}
                className={classes.formControl}
                onChange={this.handleChangeText('sortData')}
                value={this.state.sortData}
              />
            </div>

            <InfiniteScroll
              pageStart={this.state.videosCounter}
              loadMore={this.loadMoreVideos}
              hasMore={this.state.videosCounter < this.state.sortedVideos.length}
              style={{ width: '100%' }}
              loader={<div className='loader'></div>}
            >
              <Grid container spacing={mobile ? 2 : 4}>
                <div style={{ width: '100%' }}></div>
                {this.state.videosToShow.map((video, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                    <Video data={video} main={this} />
                  </Grid>
                ))}
              </Grid>
            </InfiniteScroll>
          </SnackbarProvider>
        )}
        <Fab
          color='primary'
          aria-label='add'
          className={classes.fab}
          onClick={() => this.setState({ openNewVideo: true })}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

Videos.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Videos));
