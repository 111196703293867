import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as firebase from 'firebase';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
  },
  root: {
    '& .MuiSelect-icon': {
      float: 'left',
      direction: 'ltr',
    },
    '& .MuiInputLabel-icon': {
      float: 'left',
      direction: 'ltr',
    },
    '& .MuiSelect-select': {
      direction: 'ltr',
      float: 'left',
      backgroundColor: 'transparent'
    },
    '& MuiSelect-selectMenu': {
      direction: 'ltr'
    },
    '.MuiTextField-root': {
      borderSize: 0,
      border: 1
    },
    '& .MuiAutocomplete-tag': {
      marginLeft: 90
    },
    deleteIcon: {
      marginLeft: 20
    }
  },
  content: {
    padding: 30
  },
  title: {
    fontFamily: "'Varela Round', sans-serif",
    float: 'left'
  },
  publish: {
    backgroundColor: 'white',
    color: 'black',
    padding: 8,
    borderRadius: 5
  },
  textField: {
    marginTop: 15,
    direction: 'ltr',
  },
  pageTitle: {
    textAlign: 'center',
    fontFamily: "'Varela Round', sans-serif",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  inputTitle: {
      width: '100%',
      textAlign: 'left',
      float: 'left',
      color: 'rgba(73, 155, 234, 1)',
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: 'bold',
      marginTop: 20,
      direction: 'ltr',
      marginBottom: 10
  },
  errorMessage: {
      width: '100%',
      textAlign: 'center',
      float: 'center',
      color: 'red',
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: 'bold',
      marginTop: 10,
      direction: 'ltr',
      marginBottom: 0
  },
  formControl: {
    minWidth: 120,
    direction: 'ltr',
    width: '100%'
  },
  publishButton: {
      margin: `${theme.spacing(1)}px 0`,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'הריון ולקראת לידה',
  'הנקה',
  'מוזיקה ותנועה',
  'התפתחות הבייבי',
  'כושר ותזונה לאמא',
  'כיף עם הבייבי',
  'טיפים וכלים להורים',
  'תזונת תינוקות',
  'להכיר חברות חדשות',
  'כולם'
];


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [state , setState] = React.useState({ firstName: '', lastNAme: '', phone: '', email: '', description: ''});

  React.useEffect(() => {
    // action on update of movies
  });

  const resetParams = () => {
    setCoverImage(null);
    setState({ firstName: '', lastNAme: '', phone: '', email: '', description: ''})
  }

  const publish = () => {
    var allowToContinue = true
    setErrorImage(false)
    setErrorFirstName(false)
    setErrorLastName(false)
    setErrorEmail(false)
    setErrorPhone(false)
    setErrorDescription(false)
    if (state.firstName === '') {setErrorFirstName(true); allowToContinue = false}
    if (state.lastName === '') {setErrorLastName(true); allowToContinue = false}
    if (state.email === '') {setErrorEmail(true); allowToContinue = false}
    if (state.phone === '') {setErrorPhone(true); allowToContinue = false}
    if (state.description === '') {setErrorDescription(true); allowToContinue = false}

    if (allowToContinue) {
      var data = {
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.phone,
        description: state.description,
        email: state.email,
        isNewSupplier: true,
        status: 'active',
        paymentDetails: {},
      }
      saveDataInDatabase(data)
    }
  }

  const saveDataInDatabase = (data) => {
    props.main.setState({adding: true})
    var form = new FormData();
    if (coverImage !== null) {
        form.append('photos', coverImage, coverImage.name);
    } else {
        // form.append('photos', null, null);
    }
    for (const prop in data) {
      form.append(prop, data[prop]);
    }

    form.append('data', JSON.stringify(data));

    console.log(form);
    
    axios.post('https://jama-server.appspot.com/api/suppliers/create', form, {
        headers: {
       'content-type': 'multipart/form-data' // do not forget this 
      }}).then((response) => {
        props.main.setState({adding: false, openNewVendor: false})
        props.main.getUsers(true)
        resetParams()
      })
      .catch( (error) => {
        console.log(error);
      });
  }

  const onChange = async e => {
    const files = Array.from(e.target.files)
    setCoverImage(files[0])
  }

  return (
    <div className={classes.root}>
      <Dialog fullScreen open={props.main.state.openNewVendor} onClose={()=> props.main.setState({openNewVendor: false})} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={()=> props.main.setState({openNewVendor: false})} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.pageTitle}>
              ספק חדש
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container spacing={2} justify="center">
            <Grid item sm={12} md={10} lg={8} xl={8}>
              <div className={classes.inputTitle}>תמונת פרופיל</div>
              <input className="textinput" type='file' id='single' onChange={onChange} style={{paddingTop: 13, paddingRight: 20}} />
              {errorImage && (<div className={classes.errorMessage}>שדה חובה</div>)}
              <div className={classes.inputTitle}>שם פרטי</div>
              <input className="textinput" onChange={(e) => setState({ ...state, firstName: e.target.value })}/>
              {errorFirstName && (<div className={classes.errorMessage}>שם משפחה</div>)}
              <div className={classes.inputTitle}>שם משפחה</div>
              <input className="textinput" onChange={(e) => setState({ ...state, lastName: e.target.value })}/>
              {errorLastName && (<div className={classes.errorMessage}>שם משפחה</div>)}
              <div className={classes.inputTitle}>מספר נייד</div>
              <input className="textinput" onChange={(e) => setState({ ...state, phone: e.target.value })}/>
              {errorPhone && (<div className={classes.errorMessage}>שדה חובה</div>)}
              <div className={classes.inputTitle}>אימייל</div>
              <input className="textinput" onChange={(e) => setState({ ...state, email: e.target.value })}/>
              {errorEmail && (<div className={classes.errorMessage}>שדה חובה</div>)}
              <div className={classes.inputTitle}>תיאור שירות הספק</div>
              <textarea className="textarea_box" onChange={(e) => setState({ ...state, description: e.target.value })} cols="40" rows="5"/>
              {errorDescription && (<div className={classes.errorMessage}>שדה חובה</div>)}
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <center>
                <Button data-trans="signin-done-button" className={'done-button'} onClick={()=> publish()}>
                  {props.main.state.adding ? <CircularProgress size={25} /> : 'הוספת ספק'}
                </Button>
              </center>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
