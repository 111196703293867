import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

const columns = [
  {
    id: 'name',
    label: 'שם',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toLocaleString(),
  },
  {
    id: 'phone',
    label: 'טלפון',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'permissions',
    label: 'הרשאות',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'status',
    label: 'סטטוס',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toFixed(2),
  },
  {
    id: 'edit',
    label: 'עריכה',
    minWidth: 120,
    maxWidth: 120,
    align: 'left',
    format: value => value.toLocaleString(),
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 25,
    boxShadow: "0px 8px 20px -2px rgba(0,0,0,0.10)",
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.5)',
  },
  tableWrapper: {
    maxHeight: '100%',
    overflow: 'auto',
  },
});

export default function StickyHeadTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.main.state.sortedUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                  {columns.map(column => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.id === 'status' && (
                          <div>
                            {value === 'Active' ? (
                              <Button style={{textTransform: 'none', color: 'rgb(135,202,185)', backgroundColor: 'rgb(221,249,237)', width: 70, height: 35, borderRadius: 17.5, fontSize: 14}}>Active</Button>
                            ) : (
                              <Button style={{textTransform: 'none', color: 'rgb(239,121,114)', backgroundColor: 'rgb(255,221,225)', width: 70, height: 35, borderRadius: 17.5, fontSize: 14}}>Inactive</Button>
                            )}
                          </div>
                        )}
                        {column.id === 'permissions' && (
                            <div>
                                {value.map((item, index) => (
                                    <Chip label={item} id={index} style={{margin: 4}}/>
                                ))}
                            </div>
                        )}
                        {/* {column.id === 'edit' && (
                          <Button onClick={() => props.main.setState({orderDetails: row}, () => { props.main.setState({paymentsHistory: true}); props.main.getPayments()} )} style={{textTransform: 'none', color: 'rgb(255,255,255)', background: "linear-gradient(to right , #FCA6BC, #FF8FAC)", width: 80, height: 35, borderRadius: 17.5, fontSize: 14}}>עריכה</Button>
                        )} */}
                        {(column.id !== "edit" && column.id !== "id" && column.id !== "permissions" && column.id !== "status") && (
                          <div>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </div>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={props.main.state.sortedUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}