import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import axios from "axios";

import EditDialog from "./EditDialog";
// import CopyDialog from './CopyDialog'

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    textAlign: "left",
    boxShadow: "0px 0px 12px -8px rgba(0, 0, 0, 0.5)",
    fontFamily: "'Varela Round', sans-serif",
  },
  media: {
    height: 190,
  },
  registrationsButton: {
    color: "#438BEC",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  approveButton: {
    color: "#23CE6B",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  rejectButton: {
    color: "#FF3366",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  deleteButton: {
    color: "red",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  previewButton: {
    float: "left",
    fontFamily: "'Varela Round', sans-serif",
  },
  tag: {
    fontSize: 12,
    height: 25,
    marginTop: 10,
    marginRight: 5,
    fontFamily: "'Varela Round', sans-serif",
  },
  miniTitle: {
    marginTop: 10,
    fontSize: 14,
  },
  root: {
    fontFamily: "Varela Round",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
    "& .MuiFormControlLabel-root": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
  },
});

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000);
  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  if (
    typeof datevalues[4] === "number" &&
    datevalues[4] >>> 0 === datevalues[4] % 10
  ) {
    datevalues[4] = "0" + datevalues[4];
  }
  return (
    datevalues[2] +
    "/" +
    datevalues[1] +
    "/" +
    datevalues[0] +
    " " +
    datevalues[3] +
    ":" +
    datevalues[4]
  );
};

export default function MediaCard(props) {
  const classes = useStyles();
  const [openTable, setOpenTable] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleChangePromote = (event, field, data) => {
    props.main.updateStory(data.id, field, event.target.checked);
  };

  const handleChangePublish = (event, field, data) => {
    props.main.updateStory(data.id, field, event.target.checked);
  };

  const approve = () => {
    props.main.updateStory(props.data.id, "status", "approved");
  };

  const reject = () => {
    props.main.updateStory(props.data.id, "status", "rejected");
  };

  return (
    <div className={classes.root}>
      {openEdit && (
        <EditDialog
          getStories={props.getStories}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          id={props.data.id}
          storyData={props.data}
        />
      )}
      {/* {openCopy && (
                <CopyDialog getProducts={props.getProducts} openCopy={openCopy} setOpenCopy={setOpenCopy} id={props.data.id} productData={props.data} />
            )} */}
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={props.data.images[0]} />
        <CardContent>
          <div className={classes.miniTitle}>{props.data.description}</div>

          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            לינק:
          </div>
          <div className={classes.miniTitle}>{props.data.link}</div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            תאריך:
          </div>
          <div className={classes.miniTitle}>
            {getDate(props.data.createdAt)}
          </div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            תחומי עניין:
          </div>
          {props.data.interests.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            גילאים:
          </div>
          {props.data.ages.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.publish}
                  onChange={(e) =>
                    handleChangePublish(e, "publish", props.data)
                  }
                  name="publish"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"פרסם"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.promote}
                  onChange={(e) =>
                    handleChangePublish(e, "promote", props.data)
                  }
                  name="promote"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"קדם"}
            />
          </FormGroup>
        </CardContent>
        {props.data.status === "approved" ||
        props.data.status === "active" ||
        !props.data.status ? (
          <CardActions style={{ width: "100%" }}>
            {/* <Button size="medium" color="primary" className={classes.registrationsButton} onClick={() => setOpenCopy(true)}>
                            שיכפול
                        </Button> */}
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.deleteButton}
              onClick={() =>
                props.main.setState({
                  openDeleteDialog: true,
                  currentStoryId: props.data.id,
                })
              }
            >
              מחיקה
            </Button>
          </CardActions>
        ) : (
          <CardActions style={{ width: "100%" }}>
            <Button
              size="medium"
              color="primary"
              className={classes.approveButton}
              onClick={() => approve()}
            >
              אישור
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.rejectButton}
              onClick={() => reject()}
            >
              דחייה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
