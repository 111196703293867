import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import axios from "axios";
import { Link } from "@material-ui/core";

import RegisrationsDialog from "./RegisrationsDialog";
import EditDialog from "./EditDialog";
import CopyDialog from "./CopyDialog";

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    textAlign: "left",
    boxShadow: "0px 0px 12px -8px rgba(0, 0, 0, 0.5)",
    fontFamily: "'Varela Round', sans-serif",
    position: "relative",
  },
  index: {
    position: "absolute",
    top: 12,
    left: 12,
    width: 35,
    height: 35,
    borderRadius: 17.5,
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
    paddingTop: 6,
    fontSize: 16,
  },
  media: {
    height: 190,
  },
  registrationsButton: {
    color: "#438BEC",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  approveButton: {
    color: "#23CE6B",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  rejectButton: {
    color: "#FF3366",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  deleteButton: {
    color: "red",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  previewButton: {
    float: "left",
    fontFamily: "'Varela Round', sans-serif",
  },
  tag: {
    fontSize: 12,
    height: 25,
    marginTop: 10,
    marginRight: 5,
    fontFamily: "'Varela Round', sans-serif",
  },
  miniTitle: {
    marginTop: 10,
    fontSize: 14,
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  miniTitleValue: {
    marginTop: 10,
    fontSize: 14,
  },
  root: {
    fontFamily: "Varela Round",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
    "& .MuiFormControlLabel-root": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
  },
});

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000);
  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  if (
    typeof datevalues[4] === "number" &&
    datevalues[4] >>> 0 === datevalues[4] % 10
  ) {
    datevalues[4] = "0" + datevalues[4];
  }
  return (
    datevalues[2] +
    "/" +
    datevalues[1] +
    "/" +
    datevalues[0] +
    " " +
    datevalues[3] +
    ":" +
    datevalues[4]
  );
};

function createData(name, phone, email, status, button) {
  return { name, phone, email, status, button };
}

export default function MediaCard(props) {
  const jamaURL = "https://app.jama.co.il/";
  const classes = useStyles();
  const [signups, setSignups] = React.useState([]);
  const [registrations, setRegistrations] = React.useState([]);
  const [openTable, setOpenTable] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const { refreshData } = props;

  React.useEffect(() => {
    if (refreshData) {
      getActivitiesSignups();
      props.handleRefresh();
    }
  }, [refreshData]);
  React.useEffect(() => {
    getActivitiesSignups();
  }, []);

  const handleChangePromote = (event, field, data) => {
    props.main.updateActivity(data.id, field, event.target.checked);
  };

  const handleChangePublish = (event, field, data) => {
    props.main.updateActivity(data.id, field, event.target.checked);
  };

  const approve = () => {
    props.main.updateActivity(props.data.id, "status", "approved");
  };

  const reject = () => {
    props.main.updateActivity(props.data.id, "status", "rejected");
  };

  const getActivitiesSignups = () => {
    axios
      .get(
        "https://jama-server.appspot.com/api/activities/" +
          props.data.id +
          "/get-signups"
      )
      .then((response) => {
        setSignups(response.data.length);
        var users = [];
        response.data.forEach((signup) => {
          users.push(
            createData(
              signup.userData.firstName + " " + signup.userData.lastName,
              signup.userData.phone,
              signup.userData.email,
              signup.status,
              ""
            )
          );
        });
        setRegistrations(users);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatPrice = (price) => {
    let trimmed = price.trim();
    if (trimmed === "0" || trimmed === "חינם") return "חינם";
    return `${price} שקלים`;
  };

  let interestsArray = [];
  let agesArray = [];

  const pushToInterestsArray = (expression) => {
    switch (expression) {
      case 1:
        interestsArray.push("התפתחות הבייבי");
        break;
      case 2:
        interestsArray.push("כושר ותזונה לאמא");
        break;
      case 3:
        interestsArray.push("כיף עם הבייבי");
        break;
      case 4:
        interestsArray.push("תזונת תינוקות");
        break;
      case 5:
        interestsArray.push("להכיר חברות חדשות");
        break;
      case 6:
        interestsArray.push("הנקה");
        break;
      case 7:
        interestsArray.push("הריון ולקראת לידה");
        break;
      case 8:
        interestsArray.push("טיפים וכלים להורים");
        break;
    }
  };

  const pushToAgesArray = (expression) => {
    switch (expression) {
      case 1:
        agesArray.push("כולם");
        break;
      case 2:
        agesArray.push("לאמא ולבייבי");
        break;
      case 3:
        agesArray.push("לאמא");
        break;
      case 4:
        agesArray.push("9-12");
        break;
      case 5:
        agesArray.push("לאמא בהריון");
        break;
      case 6:
        agesArray.push("6-9");
        break;
      case 7:
        agesArray.push("3-6");
        break;
      case 8:
        agesArray.push("12-24");
        break;
      case 9:
        agesArray.push("24+");
        break;
      case 10:
        agesArray.push("0-6");
        break;
      case 11:
        agesArray.push("0-3");
        break;
      case 12:
        agesArray.push("0-12");
        break;
    }
  };

  return (
    <div className={classes.root}>
      {openTable && (
        <RegisrationsDialog
          openTable={openTable}
          setOpenTable={setOpenTable}
          tableData={registrations}
          id={props.data.id}
          activityData={props.data}
        />
      )}
      {openEdit && (
        <EditDialog
          getActivities={props.getActivities}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          id={props.data.id}
          activityData={props.data}
        />
      )}
      {openCopy && (
        <CopyDialog
          getActivities={props.getActivities}
          openCopy={openCopy}
          setOpenCopy={setOpenCopy}
          id={props.data.id}
          activityData={props.data}
        />
      )}
      <Card className={classes.card}>
        <div className={classes.index}>{props.index + 1}</div>
        <CardMedia className={classes.media} image={props.data.images[0]} />
        <CardContent>
          <div
            style={{
              fontFamily: "'Varela Round', sans-serif",
              fontSize: 22,
              marginBottom: 10,
              verticalAlign: "top",
            }}
          >
            <img
              alt=""
              src={props.data.publisher.profileImage}
              style={{
                verticalAlign: "center",
                width: 38,
                height: 38,
                borderRadius: 19,
                objectFit: "cover",
                marginTop: 0,
                display: "inline-block",
              }}
            />
            <div
              style={{
                verticalAlign: "center",
                display: "inline-block",
                minHeight: 38,
                marginRight: 8,
                marginTop: 6,
                position: "absolute",
              }}
            >
              {props.data.publisher.name}
            </div>
          </div>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            {props.data.title}
          </Typography>
          <div className={classes.miniTitleValue}>{props.data.description}</div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            יום ושעה:
          </div>
          {props.data.withoutDate ? (
            <div className={classes.miniTitle}>בתיאום מראש</div>
          ) : (
            <div className={classes.miniTitleValue}>
              {getDate(props.data.date)}
            </div>
          )}
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            מיקום:
          </div>
          <div className={classes.miniTitleValue}>{props.data.location}</div>

          <div className={classes.miniTitle}>ביקשו להירשם:</div>
          <div className={classes.miniTitleValue}>{registrations.length}</div>

          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            מחיר:
          </div>
          {props.data.price !== null && props.data.price !== undefined ? (
            <div className={classes.miniTitleValue}>
              {formatPrice(props.data.price)}
            </div>
          ) : (
            <>
              {props.data.items ? (
                <div className={classes.miniTitleValue}>
                  {props.data.items[0].item} -{" "}
                  {formatPrice(props.data.items[0].price)}
                </div>
              ) : (
                <div className={classes.miniTitleValue}>חינם</div>
              )}
            </>
          )}
          {/* <div className={classes.miniTitle}>מזהה מערכת</div>
          <div className={classes.miniTitle}>{props.data.id}</div> */}
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            תחומי עניין:
          </div>

          {props.data.relBabyDev ? pushToInterestsArray(1) : null}
          {props.data.relFitNutrition ? pushToInterestsArray(2) : null}
          {props.data.relFunWithBaby ? pushToInterestsArray(3) : null}
          {props.data.relInfantNutrition ? pushToInterestsArray(4) : null}
          {props.data.relMeetNewFriends ? pushToInterestsArray(5) : null}
          {props.data.relNursing ? pushToInterestsArray(6) : null}
          {props.data.relPregnancyBirth ? pushToInterestsArray(7) : null}
          {props.data.relTipsTools ? pushToInterestsArray(8) : null}

          <div>
            {interestsArray.map((title) => (
              <Chip label={title} className={classes.tag} />
            ))}
          </div>

          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            גילאים:
          </div>

          {props.data.AFForEveryone ? pushToAgesArray(1) : null}
          {props.data.AFForMomBaby ? pushToAgesArray(2) : null}
          {props.data.AFForMoms ? pushToAgesArray(3) : null}
          {props.data.AFNineToTwelve ? pushToAgesArray(4) : null}
          {props.data.AFPregnancy ? pushToAgesArray(5) : null}
          {props.data.AFSixToNine ? pushToAgesArray(6) : null}
          {props.data.AFThreeToSix ? pushToAgesArray(7) : null}
          {props.data.AFTwelveToTwentyFour ? pushToAgesArray(8) : null}
          {props.data.AFTwentyFourPlus ? pushToAgesArray(9) : null}
          {props.data.AFZeroToSix ? pushToAgesArray(10) : null}
          {props.data.AFZeroToThree ? pushToAgesArray(11) : null}
          {props.data.AFZeroToTwelve ? pushToAgesArray(12) : null}

          {agesArray.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle}>
            <a href={jamaURL + `activity/${props.data.id}`} target="_blank">
              קישור
            </a>
          </div>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.publish}
                  onChange={(e) =>
                    handleChangePublish(e, "publish", props.data)
                  }
                  name="publish"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"פרסם"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.promote}
                  onChange={(e) =>
                    handleChangePublish(e, "promote", props.data)
                  }
                  name="promote"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"קדם"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props?.data?.popular ? true : false}
                  onChange={(e) =>
                    handleChangePublish(e, "popular", props.data)
                  }
                  name="popular"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"פופולרי"}
            />
          </FormGroup>
        </CardContent>
        {props.data.status === "approved" ||
        props.data.status === "active" ||
        !props.data.status ? (
          <CardActions style={{ width: "100%" }}>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenTable(true)}
            >
              רשומות
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenCopy(true)}
            >
              שיכפול
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.deleteButton}
              onClick={() =>
                props.main.setState({
                  openDeleteDialog: true,
                  currentActivityId: props.data.id,
                })
              }
            >
              מחיקה
            </Button>
          </CardActions>
        ) : (
          <CardActions style={{ width: "100%" }}>
            <Button
              size="medium"
              color="primary"
              className={classes.approveButton}
              onClick={() => approve()}
            >
              אישור
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.rejectButton}
              onClick={() => reject()}
            >
              דחייה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
