import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

export default function Variants() {

    const getSkelatonComponent = () => (
        <div>
            <Skeleton variant="rect" width={'100%'} height={190} />
            <Skeleton variant="text" width={'85%'} height={40} />
            <Skeleton variant="text" width={'70%'} height={40} />
            <Skeleton variant="text" width={'95%'} height={20} />
            <Skeleton variant="text" width={'95%'} height={20} />
            <Skeleton variant="text" width={'95%'} height={20} />
            <Skeleton variant="text" width={'85%'} height={20} />
        </div>
    )

    return (
        <Grid container spacing={mobile ? 2 : 4}>
            <div style={{width: '100%'}}></div>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                {getSkelatonComponent()}
            </Grid>
        </Grid>
    );
}