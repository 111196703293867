import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const text = {
  users: "משתמשים (כללי)",
  users_materna: "משתמשים (מטרנה)",
};

function CardUsers(data) {
  const { totalUsers, totalUsersMaterna } = data;
  return (
    <>
      <Card>
        <CardContent align="right">
          <Typography variant="h6" component="div">
            <span
              style={{
                fontSize: "1.2em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
                fontWeight: "bold",
              }}
            >
              משתמשים
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontSize: "1em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
              }}
            >
              {text.users}:{" "}
            </span>
            <span
              style={{
                fontSize: "1.2em",
                color: "#99a1a9",
              }}
            >
              {totalUsers ? totalUsers : <CircularProgress />}
            </span>
          </Typography>
          <Typography>
            <span
              style={{
                fontSize: "1em",
                fontFamily: "'Varela Round', sans-serif",
                textAlign: "left",
              }}
            >
              {text.users_materna}:{" "}
            </span>
            <span
              style={{
                fontSize: "1.2em",
                color: "#99a1a9",
              }}
            >
              {totalUsersMaterna ? totalUsersMaterna : <CircularProgress />}
            </span>
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}

export default CardUsers;
