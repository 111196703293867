import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";

export const MaternaInfo = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [maternaData, setMaternaData] = useState();

  useEffect(() => {
    console.log(startDate);
    console.log(endDate);
  }, [startDate, endDate]);

  const handleStartDate = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmitTotalCompletedRegistrations = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `https://jama-server.appspot.com/api/statics/getMaternaInfo-byDate?startDate=${startDate}&endDate=${endDate}`
      );
      setMaternaData(response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        // border: "1px solid black",
        backgroundColor: "#FFFFFF",
        borderRadius: 2,
        boxShadow: 1,
        p: 2,
      }}
    >
      <Box sx={{ p: 1 }}>
        <Typography sx={{ fontWeight: "bold", fontSize: 22 }}>
          Materna Statistics
        </Typography>
      </Box>
      <Box>
        <Typography>Registrations</Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <input
          type="date"
          id="maternaSignupFinishedStart"
          onChange={handleStartDate}
        />
        <input
          type="date"
          id="maternaSignupFinishedEnd"
          onChange={handleEndDate}
        />
      </Box>
      <Box sx={{ m: 1 }}>
        <Button
          variant="contained"
          size="small"
          onClick={handleSubmitTotalCompletedRegistrations}
          disabled={!startDate || !endDate}
        >
          Submit
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          m: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {isLoading ? (
          "...Loading"
        ) : (
          <>
            <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
              {maternaData?.totalRegistered &&
                `Total Users Registered during this time: ${maternaData?.totalRegistered}`}
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
              {maternaData?.totalCompleted &&
                `Total Users who completed Registration: ${maternaData?.totalCompleted}`}
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
              {maternaData?.totalUnfinished &&
                `
              Total Users who began the process but did not finish: ${maternaData?.totalUnfinished}`}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};
