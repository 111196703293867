import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider, useSnackbar } from "notistack";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

// Components
import Article from "./Article";
import NewArticle from "./NewArticle";
import EditArticle from "./EditArticle/EditArticle";
import Skeleton from "./Skeleton";
import PreviewArticle from "./PreviewArticle";
import DeleteDialog from "./DeleteDialog";

import InfiniteScroll from "react-infinite-scroller";

import * as firebase from "firebase";

const mobile = typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
   list: {
      width: 250,
   },
   fullList: {
      width: "auto",
   },
   root: {
      width: "100vw",
      height: "100vh",
   },
   success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
   h2: {
      float: "left",
      textWeight: "bold",
      display: "inline-block",
      textAlign: "left",
      color: "rgb(100,100,100)",
      fontSize: 20,
   },
   newArticle: {
      background: "linear-gradient(to right , #438BEC, #57CBF2)",
      color: "white",
   },
   fab: {
      position: "fixed",
      background: "linear-gradient(to right , #438BEC, #57CBF2)",
      bottom: theme.spacing(4),
      right: theme.spacing(4),
   },
   formControl: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      minWidth: 120,
      float: "left",
      textAlign: "left",
   },
});

class Articles extends React.Component {
   state = {
      isLoading: true,
      openNewArticle: false,
      articles: [],
      openPreviewArticle: false,
      openEditArticle: false,
      openDeleteDialog: false,
      currentArticle: { components: [] },
      currentArticleId: null,
      deleting: false,
      adding: false,
      updating: false,
      previewLocal: false,
      articlesCounter: 0,
      articlesToShow: [],
      sortBy: "כותרת",
      sortData: "",
      sortedTips: [],
   };

   getArticles = () => {
      this.setState({ openNewArticle: false });
      axios
         .get("https://jama-server.appspot.com/api/articles")
         .then((response) => {
            var articles = response.data;
            articles.sort(
               (a, b) =>
                  new Date(b.createdAt._seconds * 1000).getTime() - new Date(a.createdAt._seconds * 1000).getTime()
            );
            this.setState({
               articles: articles,
               isLoading: false,
               sortedArticles: articles,
               articlesToShow: articles.slice(0, 10),
            });
         })
         .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
         });
   };

   componentDidMount() {
      this.getArticles();
   }

   handleChangeSortBy = (event) => {
      this.setState({
         sortBy: event.target.value,
         sortData: "",
         sortedArticles: this.state.articles,
      });
   };

   handleChangeText = (name) => (event) => {
      this.setState({ [name]: event.target.value });
      if (name === "sortData") {
         this.sortData(event.target.value);
      }
   };

   getDate = (timestamp) => {
      var date = new Date(timestamp._seconds * 1000);

      var datevalues = [
         date.getFullYear(),
         date.getMonth() + 1,
         date.getDate(),
         date.getHours(),
         date.getMinutes(),
         date.getSeconds(),
      ];
      return datevalues[2] + "/" + datevalues[1] + "/" + datevalues[0] + " " + datevalues[3] + ":" + datevalues[4];
   };

   sortData = (text) => {
      if (text === "") {
         this.setState({
            sortedArticles: this.state.articles,
            articlesCounter: 0,
            articlesToShow: this.state.articles,
         });
         return;
      }
      const sortTypeText = this.state.sortBy;
      var sortId = "";
      if (sortTypeText === "תאריך") {
         sortId = "date";
      } else if (sortTypeText === "כותרת") {
         sortId = "title";
      } else if (sortTypeText === "תת כותרת") {
         sortId = "description";
      }
      const data = this.state.articles;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
         let field;
         if (sortId === "date") {
            const dateAsString = this.getDate(data[i].createdAt);
            field = dateAsString.toLowerCase();
         } else if (sortId === "title") {
            field = typeof data[i][sortId] === "string" ? data[i][sortId].toLowerCase() : toString(data[i][sortId]);
         } else {
            field = typeof data[i][sortId] === "string" ? data[i][sortId].toLowerCase() : toString(data[i][sortId]);
         }
         if (field.includes(text.toLowerCase())) {
            sortedArray.push(data[i]);
         }
      }
      this.setState({
         sortedArticles: sortedArray,
         articlesCounter: 0,
         articlesToShow: sortedArray,
      });
   };

   openPreviewArticle = (data, local) => {
      this.setState({ currentArticle: data, previewLocal: local }, () => {
         this.setState({ openPreviewArticle: true });
      });
   };

   closePreviewArticle = () => {
      this.setState({ openPreviewArticle: false, previewLocal: false });
   };

   deleteArticle = () => {
      this.setState({ deleting: true });
      axios
         .post("https://jama-server.appspot.com/api/articles/" + this.state.currentArticleId + "/delete")
         .then((response) => {
            this.setState({ deleting: false, openDeleteDialog: false });
            this.getArticles();
         })
         .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
         });
   };

   updateArticle = (id, field, value) => {
      const articles = this.state.articles;
      articles.forEach((article, index) => {
         if (article.id === id) {
            articles[index][field] = value;
            this.setState({ articles: articles });
         }
      });
      axios
         .post(
            "https://jama-server.appspot.com/api/articles/" + id + "/update",
            { [field]: value },
            { headers: { "Content-Type": "application/json" } }
         )
         .catch((error) => {
            this.setState({ isLoading: false });
            console.log(error);
         });
   };

   setSelectedArticle = (data, typeOfAction) => {
      if (typeOfAction === "preview") {
         this.setState({ openPreviewArticle: true, currentArticle: data });
      } else {
         this.setState({ openEditArticle: true, currentArticle: data });
      }
   };

   loadMoreArticles = () => {
      if (!this.state.articlesToShow) return;
      if (this.state.articlesCounter === 0 && this.state.articlesToShow.length === 10) {
         this.setState({
            articlesToShow: [...this.state.articles.slice(this.state.articlesCounter, this.state.articlesCounter + 10)],
            articlesCounter: this.state.articlesCounter + 10,
         });
      } else {
         this.setState({
            articlesToShow: [
               ...this.state.articlesToShow,
               ...this.state.articles.slice(this.state.articlesCounter, this.state.articlesCounter + 10),
            ],
            articlesCounter: this.state.articlesCounter + 10,
         });
      }
   };

   sendNotification = async (id) => {
      try {
         const newNotifications = { id, show: true };
         const sendNotificationToAllUsers = await axios.post("https://jama-server.appspot.com/api/users/notification", {
            newNotifications,
         });
         if (sendNotificationToAllUsers.status === 200) {
            await axios.post(`https://jama-server.appspot.com/api/articles/${id}/updateNotification`);
            this.getArticles();
         }
      } catch (err) {
         console.error(err);
      }
   };

   render() {
      const { classes, theme } = this.props;
      return (
         <div className="root">
            <NewArticle main={this} />
            <DeleteDialog main={this} />
            {this.state.openEditArticle && (
               <EditArticle getArticles={this.getArticles} main={this} data={this.state.currentArticle} />
            )}
            {this.state.openPreviewArticle && (
               <PreviewArticle
                  open={this.state.openPreviewArticle}
                  closePreviewArticle={this.closePreviewArticle}
                  data={this.state.currentArticle}
                  local={this.state.previewLocal}
               />
            )}
            {this.state.isLoading === true ? (
               <div>
                  <Skeleton />
               </div>
            ) : (
               <SnackbarProvider
                  maxSnack={10}
                  classes={{
                     variantSuccess: classes.success,
                     variantError: classes.error,
                     variantWarning: classes.warning,
                     variantInfo: classes.info,
                  }}
               >
                  <div
                     style={{
                        width: "100%",
                        marginTop: 0,
                        marginBottom: 10,
                        textAlign: "right",
                     }}
                  >
                     <FormControl className={classes.formControl}>
                        <InputLabel id="demo-simple-select-label">מיין לפי</InputLabel>
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={this.state.sortBy}
                           onChange={this.handleChangeSortBy}
                        >
                           <MenuItem value={"כותרת"}>כותרת</MenuItem>
                           <MenuItem value={"תת כותרת"}>תת כותרת</MenuItem>
                           <MenuItem value={"תאריך"}>תאריך</MenuItem>
                        </Select>
                     </FormControl>
                     <TextField
                        id="standard-basic"
                        label={this.state.sortBy === "" ? "בחר ערך" : this.state.sortBy}
                        className={classes.formControl}
                        onChange={this.handleChangeText("sortData")}
                        value={this.state.sortData}
                     />
                  </div>

                  <InfiniteScroll
                     pageStart={this.state.articlesCounter}
                     loadMore={this.loadMoreArticles}
                     hasMore={this.state.articlesCounter < this.state.sortedArticles.length}
                     style={{ width: "100%" }}
                     loader={<div className="loader"></div>}
                  >
                     <Grid container spacing={mobile ? 2 : 4}>
                        <div style={{ width: "100%" }}></div>
                        {this.state.articlesToShow.map((article, index) => (
                           <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                              <Article data={article} setSelectedArticle={this.setSelectedArticle} main={this} />
                           </Grid>
                        ))}
                     </Grid>
                  </InfiniteScroll>
               </SnackbarProvider>
            )}
            <Fab
               color="primary"
               aria-label="add"
               className={classes.fab}
               onClick={() => this.setState({ openNewArticle: true })}
            >
               <AddIcon />
            </Fab>
         </div>
      );
   }
}

Articles.propTypes = {
   classes: PropTypes.object.isRequired,
   theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Articles));
