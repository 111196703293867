import React from 'react';
import { withRouter } from "react-router";
import axios from 'axios';
import firebase from 'firebase'

import { withStyles } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import PaymentPopup from './PaymentMethodDialog';

const styles = theme => ({
    root: {
        '& button': {
            fontFamily: theme.fontFamily,
        },
        '& .MuiTextField-root': {
            margin: `${theme.spacing(2)}px 0`,
            height: 50,
            width: '100%'
        },
        '& .MuiInputLabel-root': {
            color: theme.palette.primary.main,
            fontFamily: theme.fontFamily,
            fontWeight: 'bold'
        },
        '& .MuiInputLabel-formControl': {
            right: theme.direction === 'rtl' ? 0 : 'auto',
            left: theme.direction === 'rtl' ? 'auto' : 0,
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.palette.primary.main}`,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '50px',
            borderColor: theme.palette.primary.main
        },
        '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        },
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontWeight: 'bold',
    },
    coverInput: {
        width: '100%',
        height: '100%',
        opacity: 0,
        position: 'absolute',
        zIndex: 1100,
        cursor: 'pointer'
    },
    input: {
        opacity: 0,
        width: 85,
        height: 85,
        position: 'absolute',
        cursor: 'pointer',
        zIndex: 1150
    },
    chip: {
        margin: 2,
    },
    profileImagePreview: {
        width: 85,
        height: 85,
    },
    profileImagePreviewContainer: {
        borderRadius: 50,
        border: '1px solid  rgb(252, 166, 188)',
        zIndex: 10,
        padding: 2,
        marginBottom: 20,
        position: 'relative'
    },
    profileImageUploadContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '-44px'
    },
    coverImageUploadContainer: {
        height: 260,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    coverImg: {
        width: '100%',
        height: '100%'
    },
    '@global': {
        'textarea#description-input + fieldset': {
            borderRadius: '12px',
        },
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 100,
        height: 180,
        width: '100%',
        background: 'linear-gradient(to bottom,rgba(248,248,252,0) 0%, rgba(248,248,252,1) 100%)'
    },

})

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const interests = [
    'הריון ולקראת לידה',
    'הנקה',
    'מוזיקה ותנועה',
    'התפתחות הבייבי',
    'כושר ותזונה לאמא',
    'כיף עם הבייבי',
    'טיפים וכלים להורים',
    'תזונת תינוקות',
    'להכיר חברות חדשות',
    'כולם'
];

const names = [
    'הריון ולקראת לידה',
    'הנקה',
    'מוזיקה ותנועה',
    'התפתחות הבייבי',
    'כושר ותזונה לאמא',
    'כיף עם הבייבי',
    'טיפים וכלים להורים',
    'תזונת תינוקות',
    'להכיר חברות חדשות'
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
class SupplierProfile extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        description: '',
        profileImage: '',
        coverImage: '',
        userId: null,
        profileImageUploaded: false,
        profileImageData: null,
        coverImageUploaded: false,
        coverImageData: null,
        profileImageFile: null,
        coverImageFile: null,
        isUploading: false,
        showAlert: false,
        editingData: false,
        alertMsg: '',
        alertType: null,
        interests: [],
        openPaymentMethod: false,
        meshulamClientId: '',
        city: ''
    }

    handleChangeInterests = (e, v) => {
        this.setState({ interests: v });
    };

    componentDidMount() {
        var { firstName, lastName, email, phone, description, profileImage, coverImage, id, interests, didUpdate, methodType, methodStatus, meshulamClientId, city } = this.props.userData;
        console.log(didUpdate)
        console.log('meshulamClientId', meshulamClientId)
        if (meshulamClientId === undefined) {
            meshulamClientId = ''
        }

        if (city === undefined) {
            city = ''
        }

        // console.log(methodStatus)
        if (!methodType && !this.props.fromAdminEdit) {
            this.setState({
                openPaymentMethod: true
            })
        }

        if (interests === null || interests === undefined) {
            interests = []
        }
        this.setState({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            description: description,
            userId: id,
            coverImageData: coverImage,
            profileImageData: profileImage,
            interests: interests,
            didUpdate: didUpdate,
            methodType: methodType,
            methodStatus: methodStatus,
            meshulamClientId: meshulamClientId,
            city: city
            // methodType: '12',
            // methodStatus: 'pending'
        }, () => { console.log(this.state) })
        if (profileImage) {
            if (profileImage.includes('http')) {
                this.setState({ profileImageFile: true })
            }
        }
        if (coverImage) {
            if (coverImage.includes('http')) {
                this.setState({ coverImageFile: true })
            }
        }
    }

    getStyles = (name, tags, theme) => {
        return {
            fontWeight:
                tags.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value }, () => { })
    };

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ showAlert: false, alertMsg: '', alertType: null }, () => { });
    };

    onChangeCoverImage = async e => {
        var oFReader = new FileReader();
        const files = Array.from(e.target.files)
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = (oFREvent) => {
            this.setState({ coverImageData: oFREvent.target.result, coverImageFile: files[0] }, () => {
                this.uploadCoverImage()
            });
        };
    }

    onChangeProfileImage = async e => {
        var oFReader = new FileReader();
        const files = Array.from(e.target.files)
        console.log(files)
        oFReader.readAsDataURL(files[0]);
        oFReader.onload = (oFREvent) => {
            this.setState({ profileImageData: oFREvent.target.result, profileImageFile: files[0] }, () => {
                this.uploadProfileImage()
            });
        };
    }

    saveDataInDatabase = () => {
        this.setState({ showAlert: false, alertMsg: '', alertType: null, editingData: true }, () => { });
        const { userId } = this.state;
        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            description: this.state.description,
            interests: this.state.interests,
            phone: this.state.phone,
            meshulamClientId: this.state.meshulamClientId,
            city: this.state.city,
            didUpdate: true
        }
        console.log(data)

        axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            console.log(response.data);
            this.setState({ showAlert: true, editingData: false, alertMsg: 'הפרופיל עודכן בהצלחה', alertType: 'success' });
            if (!this.state.didUpdate) {
                this.props.history.push({
                    pathname: '/supplier/activities',
                    state: {
                        from: 'supplierProfile',
                        firstUpdateCompleted: true
                    }
                })
            }
        })
            .catch((error) => {
                this.setState({ showAlert: true, editingData: false, alertMsg: 'קרתה תקלה בעדכון הפרופיל, אנא נסי שוב', alertType: 'error' });
                console.log(error);
            });
    }

    uploadProfileImage = () => {
        const { userId } = this.state;
        this.setState({ editingData: true })
        console.log(this.state.profileImageFile)
        var form = new FormData();
        if (this.state.profileImageFile !== null) {
            form.append('photos', this.state.profileImageFile, this.state.profileImageFile.name);
        } else {
            form.append('photos', null, null);
        }
        console.log(form);
        axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/uploadProfileImage', form, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
            this.setState({ showAlert: true, editingData: false, alertMsg: 'הפרופיל עודכן בהצלחה', alertType: 'success' });
        })
            .catch((error) => {
                this.setState({ showAlert: true, editingData: false, alertMsg: 'קרתה תקלה בעדכון הפרופיל, אנא נסי שוב', alertType: 'error' });
            });
    }

    uploadCoverImage = () => {
        const { userId } = this.state;
        this.setState({ editingData: true })
        var form = new FormData();
        if (this.state.coverImageFile !== null) {
            form.append('photos', this.state.coverImageFile, this.state.coverImageFile.name);
        } else {
            form.append('photos', null, null);
        }
        console.log(form);
        axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/uploadCoverImage', form, { headers: { 'content-type': 'multipart/form-data' } }).then((response) => {
            this.setState({ showAlert: true, editingData: false, alertMsg: 'הפרופיל עודכן בהצלחה', alertType: 'success' });
        })
            .catch((error) => {
                this.setState({ showAlert: true, editingData: false, alertMsg: 'קרתה תקלה בעדכון הפרופיל, אנא נסי שוב', alertType: 'error' });
            });
    }

    handleMethodType = () => {
        console.log(this.state.methodType)
        if (this.state.methodType === '1') {
            return 'מזומן'
        } else if (this.state.methodType === '2') {
            return 'אשראי'
        } else if (this.state.methodType === '12') {
            return 'מזומן ואשראי'
        }
    }

    handleStatus = () => {
        console.log(this.state.methodStatus)
        if (this.state.methodStatus === 'pending') {
            return 'ממתין לאישור'
        } else if (this.state.methodStatus === 'approved') {
            return 'פעיל'
        } else {
            return 'בתהליך'
        }
    }

    render() {
        const { classes, theme } = this.props;

        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Grid container className={classes.root} direction="column" alignItems="center">
                        {this.state.openPaymentMethod && (
                            <PaymentPopup main={this} handleClose={() => this.setState({ openPaymentMethod: false })} />
                        )}
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <div className={classes.coverImageUploadContainer}>
                                <input
                                    accept="image/*"
                                    className={classes.coverInput}
                                    id="cover-button-file"
                                    type="file"
                                    onChange={this.onChangeCoverImage}
                                />
                                {this.state.coverImageFile ?
                                    <img alt='' src={this.state.coverImageData} style={{ width: '100%', height: 250, objectFit: 'cover' }} />
                                    :
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingLeft: 8, height: 250 }}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.2 15.2C7.2 15.64 7.56 16 8 16C8.44 16 8.8 15.64 8.8 15.2V8.80003H15.2C15.64 8.80003 16 8.44003 16 8.00003C16 7.56003 15.64 7.20003 15.2 7.20003H8.8V0.800031C8.8 0.360031 8.44 3.05176e-05 8 3.05176e-05C7.56 3.05176e-05 7.2 0.360031 7.2 0.800031V7.20003H0.8C0.36 7.20003 0 7.56003 0 8.00003C0 8.44003 0.36 8.80003 0.8 8.80003H7.2V15.2Z" fill="url(#paint0_linear)" />
                                            <defs>
                                                <linearGradient id="paint0_linear" x1="8" y1="3.05176e-05" x2="8" y2="16" gradientUnits="userSpaceOnUse">
                                                    <stop stopColor="#FCA6BC" />
                                                    <stop offset="1" stopColor="#FF8FAC" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                        <div style={{ color: "#FCA6BC", fontSize: 20, marginRight: 8 }}>הוסיפי תמונת נושא</div>
                                    </div>
                                }
                                <div className={classes.overlay} id="overlay">&nbsp;</div>
                            </div>

                            <div className={classes.profileImageUploadContainer}>
                                <input
                                    accept="image/*"
                                    className={classes.input}
                                    id="profile-button-file"
                                    type="file"
                                    onChange={this.onChangeProfileImage}
                                />
                                {this.state.profileImageFile ?
                                    <div className={classes.profileImagePreviewContainer}>
                                        <img src={this.state.profileImageData} style={{ width: 85, height: 85, borderRadius: 45, objectFit: 'cover' }} alt="" />
                                    </div>
                                    :
                                    <div className={classes.profileImagePreviewContainer}>
                                        <Avatar src={''} className={classes.profileImagePreview} />
                                    </div>
                                }
                            </div>
                            <form style={{ maxWidth: '100%', paddingBottom: 40 }}>
                                <div className={classes.inputTitle}>שם:</div>
                                <TextField
                                    id="outlined-name-input"
                                    label=''
                                    value={this.state.firstName}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('firstName')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                                <div className={classes.inputTitle}>שם משפחה:</div>
                                <TextField
                                    id="outlined-last-name-input"
                                    label=''
                                    value={this.state.lastName}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('lastName')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                                <div className={classes.inputTitle}>טלפון:</div>
                                <TextField
                                    id="phone-input"
                                    label=''
                                    value={this.state.phone}
                                    disabled={!this.props.fromAdminEdit}
                                    type="phone"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                    onChange={this.handleChange('phone')}
                                />
                                <div className={classes.inputTitle}>מייל:</div>
                                <TextField
                                    id="email-input"
                                    label=''
                                    value={this.state.email}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('email')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                                <div className={classes.inputTitle}>כמה מילים על עצמי:</div>
                                <TextField
                                    id="description-input"
                                    multiline
                                    rows="3"
                                    cols="40"
                                    label=''
                                    value={this.state.description}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('description')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                                <div className={classes.inputTitle} style={{ marginTop: 40 }}>תחומי עניין שמתאימים לפעילות/שירות שלי:</div>
                                <Autocomplete
                                    style={{ border: 'solid 1px rgba(73, 155, 234, 1)', borderRadius: 30, height: 55, paddingLeft: 20, paddingRight: 8, paddingTop: 12, width: '100%', marginTop: 80 }}
                                    multiple
                                    id="tags-filled"
                                    options={names.map(option => option)}
                                    freeSolo
                                    value={this.state.interests}
                                    onChange={this.handleChangeInterests}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} style={{ padding: 8 }} />
                                        ))
                                    }
                                    renderInput={params => (
                                        <TextField {...params} className="textinput" InputProps={{ ...params.InputProps, disableUnderline: true }} label="" placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)" style={{ minHeight: 55, borderSize: 0, marginTop: -5 }} />
                                    )}
                                />

                                <div className={classes.inputTitle} style={{ marginTop: 20 }}>עיר</div>
                                <TextField
                                    id="description-input"
                                    label=''
                                    value={this.state.city}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('city')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />

                                <div className={classes.inputTitle} style={{ marginTop: 20 }}>מספר ספק - משולם</div>
                                <TextField
                                    id="description-input"
                                    label=''
                                    value={this.state.meshulamClientId}
                                    type="text"
                                    variant="outlined"
                                    onChange={this.handleChange('meshulamClientId')}
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />

                                {/* <div className={classes.inputTitle} style={{ marginTop: 20 }}>סוג תשלום:</div>
                                <TextField
                                    label=''
                                    value={this.handleMethodType()}
                                    type="text"
                                    variant="outlined"
                                    disabled
                                    InputLabelProps={{
                                        shrink: false,
                                    }}
                                />
                                <div style={{ textAlign: 'right' }}>כדי לשנות יש ליצור עם צוות Jama קשר</div>
                                {this.state.methodStatus && (
                                    <>
                                        <div className={classes.inputTitle} style={{ marginTop: 20 }}>סטטוס:</div>
                                        <TextField
                                            label=''
                                            value={this.handleStatus()}
                                            type="text"
                                            variant="outlined"
                                            disabled
                                            InputLabelProps={{
                                                shrink: false,
                                            }}
                                        />
                                    </>
                                )} */}



                            </form>
                            <center>
                                <div>
                                    <Button data-trans="signin-done-button" className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={this.saveDataInDatabase}>
                                        {this.state.editingData ? <CircularProgress size={25} /> : 'עדכון פרטים'}
                                    </Button>
                                </div>
                                {/* <div>
                                    <Button className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => this.setState({ openPaymentMethod: true })}>
                                        {this.state.editingData ? <CircularProgress size={25} /> : 'עידכון פרטי תשלום'}
                                    </Button>
                                </div> */}
                            </center>
                        </Grid>
                        {this.state.alertType === 'success' && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={this.state.showAlert} autoHideDuration={3000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity="success">{this.state.alertMsg}</Alert>
                        </Snackbar>
                        }
                        {this.state.alertType === 'error' && <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} open={this.state.showAlert} autoHideDuration={3000} onClose={this.handleClose}>
                            <Alert onClose={this.handleClose} severity="error">{this.state.alertMsg}</Alert>
                        </Snackbar>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(SupplierProfile));