import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';


// Components
import Post from './Post';
import Skeleton from './Skeleton';
import DeleteDialog from './DeleteDialog';

import InfiniteScroll from 'react-infinite-scroller';


const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    root: {
        width: '100vw',
        height: '100vh'
    },
    success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
    h2: {
        float: 'left',
        textWeight: 'bold',
        display: 'inline-block',
        textAlign: 'left',
        color: 'rgb(100,100,100)',
        fontSize: 20,
    },
    newArticle: {
        background: 'linear-gradient(to right , #438BEC, #57CBF2)',
        color: 'white'
    },
    fab: {
        position: 'fixed',
        background: 'linear-gradient(to right , #438BEC, #57CBF2)',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    }
});

class Posts extends React.Component {

    state = {
        isLoading: true,
        posts: [],
        openDeleteDialog: false,
        currentPost: { components: [] },
        currentPostId: null,
        deleting: false,
        previewLocal: false,
        postsCounter: 0,
        postsToShow: []
    }

    getPosts = () => {
        axios.get('https://jama-server.appspot.com/api/posts').then((response) => {
        // axios.get('http://localhost:8080/api/posts').then((response) => {
            const posts = response.data;
            posts.reverse()
            posts.sort((a, b) => (new Date(b.createdAt._seconds * 1000).getTime()) - (new Date(a.createdAt._seconds * 1000).getTime()));
            const filteredPosts = this.state.postsToShow.filter(p => p.id !== this.state.currentPostId)
            this.setState({ 'posts': posts, 'isLoading': false, postsToShow: filteredPosts });
        })
            .catch((error) => {
                this.setState({ 'isLoading': false });
                console.log(error);
            });
    }

    componentDidMount() {
        this.getPosts()
    }


    deletePost = () => {
        this.setState({ deleting: true })
        axios.post('https://jama-server.appspot.com/api/posts/' + this.state.currentPostId + '/delete').then((response) => {
        // axios.post('http://localhost:8080/api/posts/' + this.state.currentPostId + '/delete').then((response) => {
            this.setState({ deleting: false, openDeleteDialog: false, isLoading: true }, () => { })
            this.getPosts()
        })
            .catch((error) => {
                this.setState({ 'isLoading': false });
                console.log(error);
            });
    }

    setSelectedPost = (data) => {
        this.setState({ currentPost: data });
    }

    loadMorePosts = () => {
        if (!this.state.postsToShow) return;
        this.setState({
            postsToShow: [...this.state.postsToShow, ...this.state.posts.slice(this.state.postsCounter, this.state.postsCounter + 10)],
            postsCounter: this.state.postsCounter + 10
        })
    }

    render() {
        const { classes, theme } = this.props;
        return (
            <div className="root">
                <DeleteDialog main={this} />

                {this.state.isLoading === true ? (
                    <div>
                        <Skeleton />
                    </div>
                ) : (
                        <SnackbarProvider maxSnack={10} classes={{
                            variantSuccess: classes.success,
                            variantError: classes.error,
                            variantWarning: classes.warning,
                            variantInfo: classes.info,
                        }}>
                            <InfiniteScroll
                                pageStart={this.state.postsCounter}
                                loadMore={this.loadMorePosts}
                                hasMore={this.state.postsCounter < this.state.posts.length}
                                style={{ width: '100%' }}
                                loader={<div className="loader"></div>}
                            >
                                <Grid container spacing={mobile ? 2 : 4}>
                                    <div style={{ width: '100%' }}></div>
                                    {this.state.postsToShow.map((post, index) => (
                                        <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                            <Post data={post} setSelectedPost={this.setSelectedPost} main={this} />
                                        </Grid>
                                    ))}
                                </Grid>
                            </InfiniteScroll>
                        </SnackbarProvider>
                    )}
            </div>
        );
    }
}

Posts.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Posts));
