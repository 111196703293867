import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';


// Components
import Story from './Story';
import Skeleton from './Skeleton';
import NewStory from './NewStory';
import DeleteDialog from './DeleteDialog';

import InfiniteScroll from 'react-infinite-scroller';


const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
    root: {
        width: '100vw',
        height: '100vh'
    },
    success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
    h2: {
        float: 'left',
        textWeight: 'bold',
        display: 'inline-block',
        textAlign: 'left',
        color: 'rgb(100,100,100)',
        fontSize: 20,
    },
    fab: {
        position: 'fixed',
        background: 'linear-gradient(to right , #438BEC, #57CBF2)',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
    formControl: {
        marginTop: theme.spacing(4),
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        minWidth: 120,
        float: 'left',
        textAlign: 'left'
    },
});

const getDate = (timestamp) => {
    var date = new Date(timestamp._seconds * 1000)

    var datevalues = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];
    return datevalues[2] + '/' + datevalues[1] + '/' + datevalues[0] + ' ' + datevalues[3] + ':' + datevalues[4]
}

class Stories extends React.Component {

    state = {
        isLoading: true,
        openNewStory: false,
        stories: [],
        openPreviewStory: false,
        openEditStory: false,
        openDeleteDialog: false,
        currentStory: { components: [] },
        currentStoryId: null,
        deleting: false,
        adding: false,
        updating: false,
        previewLocal: false,
        alreadyUpdated: null,
        storiesToShow: [],
        storiesCounter: 0,
        sortBy: '',
        sortData: '',
        sortedStories: [],
        formattedStories: [],
    }

    getStories = () => {
        if (this.state.alreadyUpdated !== null) return;
        axios.get('https://jama-server.appspot.com/api/stories').then((response) => {
        // axios.get('http://localhost:8080/api/stories').then((response) => {
            console.log(response.data);
            var stories = response.data
            stories.sort((a, b) => (new Date(b.createdAt._seconds * 1000).getTime()) - (new Date(a.createdAt._seconds * 1000).getTime()));
            stories.reverse()

            this.setState({ stories: stories, formattedStories: stories, sortedStories: stories, 'isLoading': false });
        })
            .catch((error) => {
                this.setState({ 'isLoading': false });
                console.log(error);
            });
    }



    componentDidMount() {
        this.getStories()
    }

    openPreviewStory = (data, local) => {
        this.setState({ currentStory: data, previewLocal: local }, () => {
            console.log(data)
            this.setState({ openPreviewStory: true })
        })
    }

    closePreviewStory = () => {
        this.setState({ openPreviewStory: false, previewLocal: false })
    }

    deleteStory = () => {
        this.setState({ deleting: true })
        console.log(this.state.currentStoryId);

        axios.post('https://jama-server.appspot.com/api/Stories/' + this.state.currentStoryId + '/delete').then((response) => {
        // axios.post('http://localhost:8080/api/Stories/' + this.state.currentStoryId + '/delete').then((response) => {
            console.log(response.data);
            this.setState({ deleting: false, openDeleteDialog: false })
            this.getStories()
        })
            .catch((error) => {
                this.setState({ 'isLoading': false });
                console.log(error);
            });
    }

    updateStory = (id, field, value) => {
        const stories = this.state.sortedStories
        stories.forEach((story, index) => {
            if (story.id === id) {
                stories[index][field] = value
                this.setState({ stories: stories })
            }
        });
        axios.post('https://jama-server.appspot.com/api/stories/' + id + '/update', { [field]: value }, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
        // axios.post('http://localhost:8080/api/Stories/' + id + '/update', { [field]: value }, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            console.log(response.data);
            this.getStories()
        })
            .catch((error) => {
                this.setState({ 'isLoading': false });
                console.log(error);
            });
    }

    setSelectedStory = (data, typeOfAction) => {
        if (typeOfAction === 'preview') {
            this.setState({ openPreviewStory: true, currentStory: data });
        } else {
            this.setState({ openEditStory: true, currentStory: data });
        }
    }

    loadMoreStories = () => {
        if (!this.state.storiesToShow) return;
        this.setState({
            storiesToShow: [...this.state.storiesToShow, ...this.state.sortedStories.slice(this.state.storiesCounter, this.state.storiesCounter + 10)],
            storiesCounter: this.state.storiesCounter + 10
        })
    }

    handleChangeSortBy = (event) => {
        this.setState({ sortBy: event.target.value, sortData: '' })
    };

    handleChangeText = name => event => {
        this.setState({ [name]: event.target.value })
        if (name === 'sortData') { this.sortData(event.target.value) }
    };

    sortData = (text) => {
        if (text === '') {
            this.setState({ sortedStories: this.state.formattedStories })
            return
        }
        const sortTypeText = this.state.sortBy
        var sortId = ''
        if (sortTypeText === 'כותרת') {
            sortId = 'title'
        } else if (sortTypeText === 'תיאור') {
            sortId = 'description'
        } else if (sortTypeText === 'תאריך') {
            sortId = 'date'
        }
        const data = this.state.formattedStories
        const sortedArray = []
        for (var i = 0; i < data.length; i++) {
            let field;
            if (sortId === 'date') {
                const dateAsString = getDate(data[i].createdAt)
                field = dateAsString.toLowerCase();
            } else {
                field = typeof data[i][sortId] === 'string' ? data[i][sortId].toLowerCase() : toString(data[i][sortId])
            }
            if (field.includes(text.toLowerCase())) {
                sortedArray.push(data[i])
            }
        }
        this.setState({ sortedStories: sortedArray })
    }


    render() {
        const { classes, theme } = this.props;
        return (
            <div className="root">
                {this.state.openNewStory && (
                    <NewStory main={this} />
                )}
                {this.state.openDeleteDialog && (
                    <DeleteDialog main={this} />
                )}
                {this.state.isLoading === true ? (
                    <div>
                        <Skeleton />
                    </div>
                ) : (
                        <SnackbarProvider maxSnack={10} classes={{
                            variantSuccess: classes.success,
                            variantError: classes.error,
                            variantWarning: classes.warning,
                            variantInfo: classes.info,
                        }}>
                            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ width: '100%', marginTop: 0, marginBottom: 10 }}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-simple-select-label">מיין לפי</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={this.state.sortBy}
                                            onChange={this.handleChangeSortBy}
                                        >
                                            <MenuItem value={'כותרת'}>כותרת</MenuItem>
                                            <MenuItem value={'תיאור'}>תיאור</MenuItem>
                                            <MenuItem value={'תאריך'}>תאריך</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <TextField id="standard-basic" label={this.state.sortBy === '' ? 'בחר ערך' : this.state.sortBy} className={classes.formControl} onChange={this.handleChangeText('sortData')} value={this.state.sortData} />
                                </div>
                            </div> */}
                            <InfiniteScroll
                                pageStart={this.state.storiesCounter}
                                loadMore={this.loadMoreStories}
                                hasMore={this.state.storiesCounter < this.state.sortedStories.length}
                                style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}
                                loader={<div className="loader"></div>}
                            >
                                <Grid container spacing={mobile ? 2 : 4}>
                                    <div style={{ width: '100%' }}></div>
                                    {
                                        this.state.sortedStories.map((story, index) => (
                                            console.log(story),
                                            <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                                                <Story getStories={this.getStories} data={story} setSelectedStory={this.setSelectedStory} main={this} />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </InfiniteScroll>
                        </SnackbarProvider>
                    )
                }
                <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.setState({ openNewStory: true })}>
                    <AddIcon />
                </Fab>
            </div>
        );
    }
}

Stories.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Stories));
