import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
}));

export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({ firstName: '', lastName: '', email: '', phone: '', bio: '' });
    const [errorTitle, setErrorTitle] = React.useState(false);
    const [errorDescription, setErrorDescription] = React.useState(false);

    const { selectedUser } = props;

    React.useEffect(() => {
        if (!selectedUser) return;
        // Update the document title using the browser API
        setState({
            ...state,
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
            phone: selectedUser.phone,
            bio: selectedUser.bio
        })
    }, [selectedUser]);


    const handleClose = () => {
        props.main.handleCloseEdit()
    };

    const edit = () => {
        setLoading(true)
        axios.post('https://jama-server.appspot.com/api/users/' + selectedUser.id + '/update', state, {headers: {'Content-Type': 'application/json'}}).then((response) => {
        // axios.post('http://localhost:8080/api/users/' + selectedUser.id + '/update', state, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            setLoading(false)
            props.main.refreshData()
            props.main.cleanSearch()
            props.main.handleCloseEdit()
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error);
                // setLoading(false)
            });
    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={'sm'}
                maxWidth={'sm'}
                open={props.openEdit}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'right' }}>עריכת משתמשת</DialogTitle>
                <DialogContent>
                    <div className={classes.inputTitle}>שם פרטי</div>
                    <input className="textinput" value={state.firstName} onChange={(e) => setState({ ...state, firstName: e.target.value })} />
                    {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}
                    <div className={classes.inputTitle}>שם משפחה</div>
                    <input className="textinput" value={state.lastName} onChange={(e) => setState({ ...state, lastName: e.target.value })} />
                    {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}
                    <div className={classes.inputTitle}>אימייל</div>
                    <input className="textinput" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                    {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}
                    <div className={classes.inputTitle}>מספר פלאפון</div>
                    <input className="textinput" value={state.phone} onChange={(e) => setState({ ...state, phone: e.target.value })} />
                    {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}

                    <div className={classes.inputTitle}>קצת על המשתמשת</div>
                    <textarea className="textarea_box" value={state.bio} onChange={(e) => setState({ ...state, bio: e.target.value })} cols="40" rows="5" />

                    <center>
                        <Button data-trans="signin-done-button" className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => edit()}>
                            {loading ? <CircularProgress size={25} /> : 'עריכת משתמשת'}
                        </Button>
                    </center>
                </DialogContent>
                <DialogActions style={{ width: '100%', textAlign: 'left' }}>
                    <Button onClick={handleClose} style={{ float: 'left' }} color="primary">
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
