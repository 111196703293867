import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import firebase from '../../../Firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from 'antd';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
    engagementsContainer: {
        marginTop: 30,
        maxWidth: 450,
        wordBreak: 'break-word',
        boxShadow: '0px 0px 25px -8px rgba(0, 0, 0, 0.2)',
        padding: 16,
        borderRadius: 14,
        margin: '0 auto'
    },
    appBar: {
        position: 'relative',
        background: "linear-gradient(to right , #438BEC, #57CBF2)",
        boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
    },
    root: {
        '& .MuiSelect-icon': {
            float: 'left',
            direction: 'ltr',
        },
        '& .MuiInputLabel-icon': {
            float: 'left',
            direction: 'ltr',
        },
        '& .MuiSelect-select': {
            direction: 'ltr',
            float: 'left',
            backgroundColor: 'transparent'
        },
        '& MuiSelect-selectMenu': {
            direction: 'ltr'
        },
        '.MuiTextField-root': {
            borderSize: 0,
            border: 1
        },
        '& .MuiAutocomplete-tag': {
            marginLeft: 90
        },
        deleteIcon: {
            marginLeft: 20
        }
    },
    pageTitle: {
        textAlign: 'center',
        fontFamily: "'Varela Round', sans-serif",
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [state, setState] = React.useState({ name: '', date: '', message: '' });
    const [date, setDate] = React.useState(null)
    const [errorTitle, setErrorTitle] = React.useState(false);
    const [errorDescription, setErrorDescription] = React.useState(false);
    const [engagements, setEngagements] = React.useState(null);
    const [formattedDate, setFormattedDate] = React.useState(null);
    const [isFirst, setIsFirst] = React.useState(true)

    const { selectedVendor } = props;

    React.useEffect(() => {
        if (!selectedVendor || !isFirst) return;

        console.log(selectedVendor)
        getEngagements(selectedVendor)
        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        setState({ ...state, date: timestamp });
        setDate(timestamp);
        setFormattedDate(getFormattedDate(timestamp))
        setIsFirst(false)
    }, [selectedVendor, isFirst]);

    const resetParams = () => {
        let timestamp = firebase.firestore.Timestamp.fromDate(new Date());
        setState({ name: '', date: timestamp, message: '' })
        setDate(timestamp);
    }
    const getDate = (timestamp) => {
        console.log(timestamp)
        var date;
        if (timestamp._seconds) {

            date = new Date(timestamp._seconds * 1000)
        } else {
            date = new Date(timestamp.seconds * 1000)
        }

        var datevalues = [
            date.getFullYear(),
            date.getMonth() + 1,
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
        ];

        if (typeof datevalues[4] === 'number' && datevalues[4] >>> 0 === datevalues[4] % 10) {
            datevalues[4] = '0' + datevalues[4]
        }
        return datevalues[2] + '/' + datevalues[1] + '/' + datevalues[0] + ' ' + datevalues[3] + ':' + datevalues[4]
    }

    const getEngagements = (vendor) => {
        console.log(vendor)
        axios.post('https://jama-server.appspot.com/api/engagements/get-by-supplier', { id: vendor.id },
            // axios.post('http://localhost:8080/api/engagements/get-by-supplier', { id: vendor.id },
            { headers: { 'Content-Type': 'application/json' } }).then((response) => {
                var engagements = response.data;

                engagements.sort((a, b) => (new Date(b.date.seconds * 1000).getTime()) - (new Date(a.date.seconds * 1000).getTime()));
                setEngagements(engagements)

            })
    }


    const handleClose = () => {
        props.main.handleCloseEngagement()
    };

    const send = () => {
        setLoading(true)
        console.log(selectedVendor)
        console.log(state)
        let data = state;
        data.supplierName = selectedVendor.firstName + ' ' + selectedVendor.lastName;
        data.supplierId = selectedVendor.id;
        data.supplierPhone = selectedVendor.phone;
        data.supplierEmail = selectedVendor.email;
        data.date = date

        console.log(data)

        // axios.post('http://localhost:8080/api/engagements/create', data,
        axios.post('https://jama-server.appspot.com/api/engagements/create', data,
            { headers: { 'Content-Type': 'application/json' } }).then((response) => {
                setLoading(false)
                resetParams();
                getEngagements(selectedVendor)
            })
            .catch((error) => {
                console.log(error);
                setLoading(false)
            });
    }

    const onchangeDateTime = (e) => {
        setDate(firebase.firestore.Timestamp.fromDate(e._d))
        setFormattedDate(moment(e._d))
    }

    const getFormattedDate = (timestamp) => {
        var date = new Date(timestamp.seconds * 1000)
        return moment(date)
    }


    return (
        <div className={classes.root}>
            <Dialog fullScreen open={props.open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h5" className={classes.pageTitle}>
                            התקשרות אל מול {selectedVendor.firstName + ' ' + selectedVendor.lastName}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className={classes.content}>
                    <Grid container spacing={2} justify="center">
                        <Grid item sm={12} md={10} lg={8} xl={8}>

                            <DialogContent style={{ direction: 'rtl' }}>
                                <div className={classes.inputTitle}>שם איש צוות:</div>
                                <input className="textinput" value={state.name} onChange={(e) => setState({ ...state, name: e.target.value })} />
                                {errorTitle && (<div className={classes.errorMessage}>שדה חובה</div>)}
                                <div className={classes.inputTitle}>תאריך:</div>


                                <DatePicker
                                    renderExtraFooter={() => <div style={{ height: 0 }}><div style={{ backgroundColor: 'white', width: '50%', position: 'relative', top: 10, height: 50 }}></div></div>}
                                    // minuteStep={15}
                                    format={'DD/MM/YYYY HH:mm'}
                                    hideDisabledOptions={true}
                                    placeholder="יש לבחור תאריך ושעה"
                                    onChange={onchangeDateTime}
                                    clearIcon={(<div></div>)}
                                    popupStyle={{ zIndex: 99999 }}
                                    // showToday={false}
                                    // showNow={false}
                                    value={formattedDate}
                                    id='date-and-time'
                                    disabledHours={() => [0, 1, 2, 3, 4, 5]}
                                    className="textinput"
                                    style={{ borderRadius: 25, border: '1px solid #438BEC' }}
                                    showTime />


                                <div className={classes.inputTitle}>הודעה:</div>
                                <textarea className="textarea_box" value={state.message} onChange={(e) => setState({ ...state, message: e.target.value })} cols="40" rows="5" />
                                <Grid item sm={12} md={12} lg={12} xl={12}>
                                    <center>
                                        <Button data-trans="signin-done-button" className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => send()}>
                                            {loading ? <CircularProgress size={25} /> : 'שלח'}
                                        </Button>
                                    </center>
                                </Grid>
                                <div>
                                    {engagements && engagements.length > 0 ?
                                        <div style={{ fontSize: 16, fontFamily: "'Varela Round', sans-serif" }}>
                                            <div style={{ textAlign: 'center', marginTop: 30 }}>התקשרויות אחרונות:</div>
                                            <div>

                                                {engagements.map(e => {
                                                    return (
                                                        <div className={classes.engagementsContainer}>
                                                            <div style={{ marginTop: 6 }}>
                                                                <div>שם נציג גאמה:</div>
                                                                <div>{e.name}</div>
                                                            </div>
                                                            <div style={{ marginTop: 6 }}>
                                                                <div>תאריך:</div>
                                                                <div>{getDate(e.date)}</div>
                                                            </div>
                                                            <div style={{ marginTop: 6 }}>
                                                                <div>תיעוד התקשרות:</div>
                                                                <div style={{ maxWidth: 'fit-content', padding: '6px 0' }}>{e.message}</div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        <div style={{ textAlign: 'center', marginTop: 30 }}>אין התקשרויות אחרונות</div>
                                    }
                                </div>
                            </DialogContent>

                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        </div>
    );
}
