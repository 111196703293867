import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import PaymentIcon from "@material-ui/icons/Payment";

import axios from "axios";

import { DatePicker } from "antd";
import moment from "moment";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import "../../../../node_modules/antd/dist/antd.css";

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ageRangeArray = [
  { label: "0-3 חודשים", value: "0-3" },
  { label: "3-6 חודשים", value: "3-6" },
  { label: "6-9 חודשים", value: "6-9" },
  { label: "9-12 חודשים", value: "9-12" },
  { label: "12-24 חודשים", value: "12-24" },
  { label: "24+ חודשים", value: "24+" },
  { label: "לאמא בהריון", value: "הריון" },
  { label: "לאמא ולבייבי", value: "אמא ובייבי" },
  { label: "לאמא", value: "אמא" },
  { label: "כולם", value: "כולם" },
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
}));

function createData(name, phone, email, signinAt, price, status, id) {
  return { name, phone, email, signinAt, status, price, id };
}

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    title: "",
    description: "",
    items: [],
    interests: [],
    price: "",
    location: "",
    images: [],
  });
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [date, setDate] = React.useState(0);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [errorDate, setErrorDate] = React.useState(false);

  const [errorTags, setErrorTags] = React.useState(false);
  const [ageRange, setAgeRange] = React.useState([]);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);
  const [tags, setTags] = React.useState([]);

  const [showFields, setShowFields] = React.useState(true);
  const [itemPrice, setItemPrice] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("");
  const [newId, setNewId] = React.useState(101);
  const [prices, setPrices] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [errorProducts, setErrorProducts] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [errorLocation, setErrorLocation] = React.useState(false);

  React.useEffect(() => {
    // Update the document title using the browser API
    setState({
      ...state,
      title: props.activityData.title,
      description: props.activityData.description,
      items: props.activityData.prices,
      interests: props.activityData.interests,
      price: props.activityData.price,
      location: props.activityData.location,
      images: props.activityData.images,
    });
    setFormattedDate(getDate(props.activityData.date));
    setAgeRange(props.activityData.ages);
    setTags(props.activityData.interests);
    if (props.activityData.price) {
      setPrices([{ price: props.activityData.price }]);
      setNewId(newId + 1);
    } else if (props.activityData.items) {
      setPrices(props.activityData.items);
      setNewId(newId + props.activityData.items.length);
    }
  }, []);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const handleShowFields = () => {
    setShowFields(true);
  };
  const addItem = () => {
    var tempPrices = prices;
    tempPrices.push({
      price: itemPrice,
      item: itemDescription,
      id: handleNewId(),
    });
    setPrices(tempPrices);
    setItemPrice("");
    setItemDescription("");
    setShowFields(false);
  };

  const handleNewId = () => {
    setNewId(newId + 1);
    return newId;
  };

  const deleteItemById = (id) => {
    var tempPrices = prices;
    tempPrices = tempPrices.filter((i) => i.id !== id);
    setPrices(tempPrices);
    setUpdate(true);
    if (tempPrices.length === 0) {
      setShowFields(true);
    }
  };

  const edit = async () => {
    setLoading(true);
    let data = state;
    var tempDate = date;
    var datetime = null;
    if (!props.activityData.withoutDate && date) {
      datetime = tempDate.replace(":0 ", ":00 ");
      data.date = datetime;
    }
    data.items = prices;
    if (prices) {
      data.price = null;
    }
    if (state.onlineActivity) {
      data.type = "online";
      data.location = "Zoom";
    } else {
      data.type = "frontal";
      data.location = state.location;
      var response = await getCoordinates(state.location);
      if (response !== false) {
        data.locationData = response;
      }
    }

    data.ages = ageRange;

    if (coverImage) {
      var form = new FormData();
      form.append("file", coverImage, coverImage.name);

      axios
        .post(
          "https://jama-server.appspot.com/api/activities/" +
            props.id +
            "/uploadPhoto",
          form,
          {
            headers: {
              "content-type": "multipart/form-data", // do not forget this
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    console.log(data);

    axios
      .post(
        "https://jama-server.appspot.com/api/activities/" +
          props.id +
          "/update",
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        // axios.post('http://localhost:8080/api/activities/' + props.id + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
        setLoading(false);
        props.getActivities();
        props.setOpenEdit(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onchangeDateTime = (e) => {
    setDate(String(e._d));
    setFormattedDate(moment(e._d));
  };
  const getCoordinates = async (address) => {
    try {
      var link =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        encodeURI(address) +
        "&key=AIzaSyBhR-rD1My1pncnY-GcDBgd28AHcLWVVTY";
      const response = await axios.get(link);
      const resAddress = response.data.results[0];
      console.log(response);
      console.log(resAddress);
      if (response.data.results.length > 0) {
        console.log("yes! Good address!");
        const userAddress = {
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng,
          formattedAddress: response.data.results[0].formatted_address,
        };
        console.log(userAddress);
        return userAddress;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const formatPrice = (price) => {
    let trimmed = price.trim();
    if (trimmed === "0" || trimmed === "חינם") return "חינם";
    return `${price} שקלים`;
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const getDate = (timestamp) => {
    var date = new Date(timestamp._seconds * 1000);
    return moment(date);
  };

  const handleChangeCheckbox = (event, pOrN) => {
    if (pOrN === "p") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: !event.target.checked });
    }
  };

  const handleChangeAgeRange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleChange = (e, v) => {
    setTags(v);
    setState({ ...state, interests: v });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={props.openEdit}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right" }}>
          עריכת פעילות
        </DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
          <div style={{ direction: "rtl", paddingBottom: 16, fontSize: 12 }}>
            <div>האותיות הקטנות:</div>
            <div>* תמונות איכותיות מתוך הפעילות שלך יתקבלו באהבה!</div>
            <div>* בבקשה ללא טשטושי פנים (זה הרבה פחות מזמין)</div>
            <div>* בבקשה לא לצרף לוגו עם טלפון או מייל</div>
            <div>* אם אין ברשותך תמונות איכותיות צרי קשר ואנחנו נסייע לך</div>
          </div>
          <input
            className="textinput"
            type="file"
            id="single"
            onChange={onChange}
            style={{ paddingTop: 13, paddingRight: 20 }}
          />
          {errorImage && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>כותרת</div>
          <input
            className="textinput"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          {errorTitle && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>תיאור הפעילות בקצרה</div>
          <textarea
            className="textarea_box"
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            cols="40"
            rows="5"
          />
          {errorDescription && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <div className={classes.inputTitle}>סוג פעילות</div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={!state.onlineActivity}
                  onChange={(e) => handleChangeCheckbox(e, "מ")}
                  name="onlineActivity"
                />
              }
              label="פעילות פרונטלית"
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={state.onlineActivity}
                  onChange={(e) => handleChangeCheckbox(e, "p")}
                  name="onlineActivity"
                />
              }
              label="פעילות אונליין"
            />
          </div>
          <div className={classes.inputTitle}>
            מיקום: מקום להכניס כתובת | מקום לסמן אם פעילות אונליין
          </div>
          {!state.onlineActivity ? (
            <input
              className="textinput"
              placeholder="כתובת"
              onChange={(e) => setState({ ...state, location: e.target.value })}
              value={state.location}
            />
          ) : (
            <input className="textinput" disabled value="אונליין" />
          )}
          {errorLocation && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          {!props.activityData.withoutDate && (
            <div>
              <div className={classes.inputTitle}>יום ושעה</div>
              <DatePicker
                renderExtraFooter={() => (
                  <div style={{ height: 0 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        width: "50%",
                        position: "relative",
                        top: 10,
                        height: 50,
                      }}
                    ></div>
                  </div>
                )}
                minuteStep={15}
                format={"DD/MM/YYYY HH:mm"}
                hideDisabledOptions={true}
                placeholder="יש לבחור תאריך ושעה"
                onChange={onchangeDateTime}
                clearIcon={<div></div>}
                disabledDate={disabledDate}
                popupStyle={{ zIndex: 99999 }}
                showToday={false}
                showNow={false}
                value={formattedDate}
                id="date-and-time"
                disabledHours={() => [0, 1, 2, 3, 4, 5]}
                className="textinput"
                style={{ borderRadius: 25, border: "1px solid #438BEC" }}
                showTime
              />
            </div>
          )}
          {errorDate && <div className={classes.errorMessage}>שדה חובה</div>}

          {showFields && (
            <>
              <div className={classes.inputTitle}>
                מחיר פעילות/שירות: מקום להכניס מחיר | מקום לסמן אם הפעילות חינם
              </div>
              <input
                value={itemPrice ? itemPrice : "מחיר"}
                type="text"
                className="textinput"
                onChange={(e) => setItemPrice(e.target.value)}
              />
              <div className={classes.inputTitle}>
                תיאור מחיר (לדוגמה - מחיר ליחיד/לזוג, מחיר למספר מפגשים וכו)
              </div>
              <input
                value={itemDescription}
                type="text"
                className="textinput"
                onChange={(e) => setItemDescription(e.target.value)}
              />
              {prices.length > 0 && (
                <Button
                  className={classes.cancelAddItemButton}
                  style={{ marginTop: 12, marginRight: 12 }}
                  onClick={() => setShowFields(false)}
                >
                  ביטול הוספת מחיר
                </Button>
              )}
              <Button
                className={"done-button"}
                style={{ marginTop: 12 }}
                onClick={() => addItem()}
              >
                הוספת מחיר
              </Button>
            </>
          )}
          <div className={classes.inputTitle}>מחירים שהתווספו</div>
          {prices.length === 0 ? (
            <div style={{ textAlign: "right", width: "100%", fontSize: 18 }}>
              אין מחירים
            </div>
          ) : (
            <div>
              <List
                style={{ direction: "rtl", textAlign: "right", width: "100%" }}
              >
                {prices.map((item, index) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PaymentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.item}
                      secondary={formatPrice(item.price)}
                    />
                    <IconButton
                      onClick={() => deleteItemById(item.id)}
                      edge="start"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          {!showFields && (
            <div style={{ height: 80 }}>
              <Button
                className={"done-button"}
                style={{ marginTop: 12, float: "right" }}
                onClick={handleShowFields}
              >
                הוספת מחיר נוסף
              </Button>
            </div>
          )}
          {errorProducts && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <div className={classes.inputTitle}>תחומי פעילות</div>
          <Autocomplete
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
            multiple
            id="tags-filled"
            options={names.map((option) => option)}
            freeSolo
            value={state.interests}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  style={{ padding: 8 }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="textinput"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                label=""
                placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
              />
            )}
          />
          {errorTags && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>טווחי גילאים</div>
          <FormControl
            className={classes.formControl}
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
            }}
          >
            <Select
              labelId="demo-mutiple-chip-label"
              disableUnderline
              id="demo-mutiple-chip"
              multiple
              value={ageRange}
              onChange={handleChangeAgeRange}
              input={
                <Input
                  id="select-multiple-chip"
                  style={{ direction: "rtl", marginTop: -10, minHeight: 50 }}
                />
              }
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {ageRangeArray.map((step) => (
                <MenuItem
                  key={step.value}
                  value={step.value}
                  style={getStyles(step.value, ageRangeArray, theme)}
                >
                  {step.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorAgeRange && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <center>
            <Button
              data-trans="signin-done-button"
              className={"done-button"}
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => edit()}
            >
              {loading ? <CircularProgress size={25} /> : "עריכת פעילות"}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: "100%", textAlign: "left" }}>
          <Button
            onClick={handleClose}
            style={{ float: "left" }}
            color="primary"
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
