import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import TipsIcon from "@material-ui/icons/WbIncandescent";
import Home from "@material-ui/icons/Home";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import UsersIcon from "@material-ui/icons/PregnantWoman";
import ActivitesIcon from "@material-ui/icons/Spa";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ArticlesIcon from "@material-ui/icons/ArtTrack";
import StoriesIcon from "@material-ui/icons/AmpStories";
import YouTubeIcon from "@material-ui/icons/YouTube";
import StoreIcon from "@material-ui/icons/Store";
// import Stories from '@material-ui/icons/AmpStoriesRounded';
import MailIcon from "@material-ui/icons/Mail";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Grid from "@material-ui/core/Grid";
import firebase from "../../Firebase";
import SaveIcon from "@material-ui/icons/Save";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Articles from "../Pages/Content/Articles";
import Videos from "../Pages/Videos/Videos";
import Main from "../Pages/Main/Main";
import Statics from "../Pages/Statics/Statics";
import Reports from "../Pages/Reports/Reports";
import Tips from "../Pages/Tips/Tips";
import Users from "../Pages/Users/Users";
import TempUsers from "../Pages/TempUsers/Users";
import Vendors from "../Pages/Vendors/Vendors";
import Posts from "../Pages/Posts/Posts";
import AdminUsers from "../Pages/AdminUsers/Users";
import Activities from "../Pages/Activities/Activities";
import Products from "../Pages/Products/Products";
import ActivitiesSupplier from "../Pages/ActivitiesSupplier/Activities";
import SupplierProfile from "../Pages/SupplierProfile/SupplierProfile";
import ContractPopup from "../Pages/ContractPopup/ContractPopup";
import Stories from "../Pages/Stories/Stories";
import OrdersVod from "../Pages/OrdersVod/OrdersVod";
import { Redirect } from "react-router";
import SaveAsApp from "../Pages/SaveAsAppTutorial/SaveAsAppTutorial.js";
import ListAltIcon from "@mui/icons-material/ListAlt";

import { createBrowserHistory } from "history";
import FileViewer from "react-file-viewer";
const doc = require("../../Assets/Docs/contract.docx");

const history = createBrowserHistory();

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiListItemText-root": {
      fontFamily: "'Varela Round', sans-serif",
    },
    "& .MuiListItemText-dense": {
      fontFamily: "'Varela Round', sans-serif",
    },
    "& .MuiListItemText-dense": {
      fontFamily: "'Varela Round', sans-serif",
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    boxShadow: "0px 8px 10px -7px rgba(0,0,0,0.30)",
    color: "white",
  },
  menuButton: {
    marginRight: theme.spacing(-1),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: theme.spacing(12),
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  pageTitle: {
    textAlign: "center",
    fontFamily: "'Varela Round', sans-serif",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: "bold",
  },
  mobile: {
    padding: 20,
  },
  list: {
    width: 240,
  },
  link: {
    fontFamily: "'Varela Round', sans-serif",
    textAlign: "left",
    marginRight: 0,
    textDecoration: "none",
  },
  header: {
    textAlign: "left",
    textTransform: "capitalize",
    marginTop: -2,
    "&>h1": {
      fontWeight: "bold",
    },
  },
  drawerTitle: {
    fontFamily: "'Varela Round', sans-serif",
    textAlign: "left",
    fontWeight: "bold",
  },
  drawerItem: {
    float: "left",
  },
  "@global": {
    "div.document-container": {
      textAlign: "left",
    },
  },
  orders: {
    width: "110%",
  },
}));

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

export default function PersistentDrawerLeft(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(mobile ? false : true);
  const [currentPage, setCurrentPage] = React.useState("");
  const [openContractPopup, setOpenContractPopup] = React.useState(false);
  const [openPaymentPopup, setOpenPaymentMethod] = React.useState(false);
  const [openSaveAsApp, setOpenSaveAsApp] = React.useState(false);
  React.useEffect(() => {
    const currentPathname = window.location.pathname.replace("/", "");
    if (currentPathname == "") {
      setCurrentPage("users");
    } else {
      setCurrentPage(getTitle(currentPathname));
    }
    handleCheckContract();
  });

  const getTitle = (pathname) => {
    switch (pathname) {
      case "main":
        return "ראשי";
        break;
      case "statics":
        return "סטטיסטיקות";
        break;
      case "reports":
        return "דוחות";
        break;
      case "articles":
        return "כתבות";
        break;
      case "users":
        return "משתמשים";
        break;
      case "temp-users":
        return "משתמשים זמניים";
        break;
      case "tips":
        return "טיפים";
        break;
      case "stories":
        return "סטוריז";
        break;
      case "vendors":
        return "ספקים";
        break;
      case "videos":
        return "סרטונים";
        break;
      case "orders-vod":
        return "Orders VOD";
        break;
      case "posts":
        return "פוסטים";
        break;
      case "products":
        return "מוצרים";
        break;
      case "admin-users":
        return "משתמשי מערכת";
        break;
      case "activities":
        return "פעילויות";
      case "supplier/activities":
        return "הפעילויות/השירות שלי";
      case "supplier/profile":
        return "פרופיל";
      case "supplier/contract":
        return "תנאי שימוש";
      default:
        if (
          props.main.state.type === "admin" ||
          props.main.state.type === "content"
        ) {
          history.push("/main");
          return "main";
        } else {
          if (props.main.state.userData.didUpdate) {
            history.push("/supplier/activities");
            return "הפעילויות/השירות שלי";
          } else {
            history.push("/supplier/profile");
            return "פרופיל";
          }
        }
        break;
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const getButtonStyle = (current) => {
    if (
      window.location.pathname.replace("/", "") === current ||
      (window.location.pathname.replace("/", "") === "" && current == "statics")
    ) {
      return {
        fontFamily: "'Varela Round', sans-serif",
        background: "linear-gradient(to right , #438BEC, #57CBF2)",
        color: "white",
        borderRadius: "0px 0px 0px 0px",
        width: "100%",
        textAlign: "left",
        marginRight: 0,
      };
    }
    return {
      fontFamily: "'Varela Round', sans-serif",
      backgroundColor: "white",
      textDecoration: "none",
      color: "black",
    };
  };

  const handleCheckContract = () => {
    if (!props.main.state.userData.approvedContract) {
      setOpenContractPopup(true);
      return false;
    } else return true;
  };

  const userFirstName = props.main.state.userData.firstName;

  const content = (props) => {
    return (
      <div>
        <Grid container spacing={1} justify="center">
          <Grid item sm={12} md={11} lg={10} xl={8}>
            <div className={classes.header}>
              <h1>{getTitle(window.location.pathname.replace("/", ""))}</h1>
            </div>
            {props.main.state.type === "admin" ? (
              <Switch>
                <Route exact path="/">
                  <Main />
                </Route>
                <Route exact path="/main">
                  <Main />
                </Route>
                <Route exact path="/statics">
                  <Statics />
                </Route>
                <Route exact path="/reports">
                  <Reports userData={props.main.state.userData} />
                </Route>
                <Route exact path="/users">
                  <Users />
                </Route>
                <Route exact path="/temp-users">
                  <TempUsers />
                </Route>
                <Route exact path="/articles">
                  <Articles />
                </Route>
                <Route exact path="/activities">
                  <Activities />
                </Route>
                <Route exact path="/stories">
                  <OrdersVod />
                </Route>
                <Route exact path="/admin-users">
                  <AdminUsers />
                </Route>
                <Route exact path="/videos">
                  <Videos />
                </Route>
                <div className={classes.orders}>
                  <Route exact path="/orders-vod">
                    <OrdersVod />
                  </Route>
                </div>
                <Route exact path="/tips">
                  <Tips />
                </Route>
                <Route exact path="/vendors">
                  <Vendors />
                </Route>
                <Route exact path="/posts">
                  <Posts />
                </Route>
                <Route exact path="/products">
                  <Products />
                </Route>
              </Switch>
            ) : (
              <>
                {openSaveAsApp && <SaveAsApp isOpen={openSaveAsApp} />}
                {openContractPopup ? (
                  <ContractPopup
                    setOpenContractPopup={setOpenContractPopup}
                    openContractPopup={openContractPopup}
                    userData={props.main.state.userData}
                  />
                ) : (
                  <Switch>
                    <Route exact path="/">
                      {props.main.state.userData.didUpdate ? (
                        <Redirect to={"/supplier/activities"} />
                      ) : (
                        <Redirect to={"/supplier/profile"} />
                      )}
                    </Route>
                    <Route exact path="/supplier/profile">
                      <SupplierProfile userData={props.main.state.userData} />
                    </Route>
                    <Route exact path="/supplier/activities">
                      <ActivitiesSupplier
                        userData={props.main.state.userData}
                      />
                    </Route>
                    <Route exact path="/supplier/contract">
                      <div style={{ overflowX: "scroll", width: "100vw" }}>
                        <FileViewer fileType={"docx"} filePath={doc} />
                      </div>
                    </Route>
                  </Switch>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    );
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then((result) => {
        props.main.setState({
          currentUser: null,
          isSignedIn: false,
        });
      });
  };

  const sideListContent = (props) => {
    if (props.main.state.type === "admin") {
      return (
        <div style={{ direction: "rtl" }}>
          {props.main.state.userData.permissions.includes("admin") && (
            <List className={classes.list}>
              <Link
                onClick={() => {
                  setCurrentPage("main");
                  setOpen(false);
                }}
                to="/main"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("main")}>
                  <ListItemIcon>
                    <Home />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>ראשי</div>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setCurrentPage("statics");
                  setOpen(false);
                }}
                to="/statics"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("statics")}>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>סטטיסטיקות</div>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setCurrentPage("reports");
                  setOpen(false);
                }}
                to="/reports"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("reports")}>
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>דוחות</div>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setCurrentPage("users");
                  setOpen(false);
                }}
                to="/users"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("users")}>
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>משתמשים</div>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setCurrentPage("temp-users");
                  setOpen(false);
                }}
                to="/temp-users"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("temp-users")}>
                  <ListItemIcon>
                    <UsersIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>משתמשים זמניים</div>
                </ListItem>
              </Link>
            </List>
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <Divider />
          )}
          {(props.main.state.userData.permissions.includes("admin") ||
            props.main.state.userData.permissions.includes("content")) && (
            <>
              <List className={classes.list}>
                <Link
                  onClick={() => {
                    setCurrentPage("articles");
                    setOpen(false);
                  }}
                  to="/articles"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("articles")}>
                    <ListItemIcon>
                      <ArticlesIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>כתבות</div>
                  </ListItem>
                </Link>
                <Link
                  onClick={() => {
                    setCurrentPage("tips");
                    setOpen(false);
                  }}
                  to="/tips"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("tips")}>
                    <ListItemIcon>
                      <TipsIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>טיפים</div>
                  </ListItem>
                </Link>
                <Link
                  onClick={() => {
                    setCurrentPage("stories");
                    setOpen(false);
                  }}
                  to="/stories"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("stories")}>
                    <ListItemIcon>
                      <StoriesIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>סטוריז</div>
                  </ListItem>
                </Link>
                <Link
                  onClick={() => {
                    setCurrentPage("videos");
                    setOpen(false);
                  }}
                  to="/videos"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("videos")}>
                    <ListItemIcon>
                      <YouTubeIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>סרטונים</div>
                  </ListItem>
                </Link>
                <Link
                  onClick={() => {
                    setCurrentPage("posts");
                    setOpen(false);
                  }}
                  to="/posts"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("posts")}>
                    <ListItemIcon>
                      <ArticlesIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>פוסטים</div>
                  </ListItem>
                </Link>
              </List>
              <Divider />
              <List className={classes.list}>
                <Link
                  onClick={() => {
                    setCurrentPage("orders-vod");
                    setOpen(false);
                  }}
                  to="/orders-vod"
                  className={classes.link}
                >
                  <ListItem button style={getButtonStyle("orders-vod")}>
                    <ListItemIcon>
                      <ListAltIcon />
                    </ListItemIcon>
                    <div className={classes.drawerTitle}>Orders VOD</div>
                  </ListItem>
                </Link>
              </List>
            </>
          )}
          {(props.main.state.userData.permissions.includes("admin") ||
            props.main.state.userData.permissions.includes("contect")) && (
            <Divider />
          )}
          {(props.main.state.userData.permissions.includes("admin") ||
            props.main.state.userData.permissions.includes("activities")) && (
            <List className={classes.list}>
              <Link
                onClick={() => {
                  setCurrentPage("vendors");
                  setOpen(false);
                }}
                to="/vendors"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("vendors")}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>ספקים</div>
                </ListItem>
              </Link>
              <Link
                onClick={() => {
                  setCurrentPage("activities");
                  setOpen(false);
                }}
                to="/activities"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("activities")}>
                  <ListItemIcon>
                    <ActivitesIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>פעילויות</div>
                </ListItem>
              </Link>
            </List>
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <Divider />
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <List className={classes.list}>
              <Link
                onClick={() => {
                  setCurrentPage("products");
                  setOpen(false);
                }}
                to="/products"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("products")}>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>מוצרים</div>
                </ListItem>
              </Link>
            </List>
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <Divider />
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <List className={classes.list}>
              <Link
                onClick={() => {
                  setCurrentPage("admin-users");
                  setOpen(false);
                }}
                to="/admin-users"
                className={classes.link}
              >
                <ListItem button style={getButtonStyle("admin-users")}>
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <div className={classes.drawerTitle}>משתמשי מערכת</div>
                </ListItem>
              </Link>
            </List>
          )}
          {props.main.state.userData.permissions.includes("admin") && (
            <Divider />
          )}
          <List>
            <ListItem button onClick={() => logout()} className={classes.link}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <div className={classes.drawerTitle}>יציאה</div>
            </ListItem>
          </List>
        </div>
      );
    } else {
      return (
        <div style={{ direction: "rtl" }}>
          <List className={classes.list}>
            <Link
              onClick={() => {
                setCurrentPage("activities");
                setOpen(false);
              }}
              to="/supplier/activities"
              className={classes.link}
            >
              <ListItem button style={getButtonStyle("activities")}>
                <ListItemIcon>
                  <ActivitesIcon />
                </ListItemIcon>
                <div className={classes.drawerTitle}>הפעילויות/השירות שלי</div>
              </ListItem>
            </Link>
            <Link
              onClick={() => {
                setCurrentPage("vendors");
                setOpen(false);
              }}
              to="/supplier/profile"
              className={classes.link}
            >
              <ListItem button style={getButtonStyle("vendors")}>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <div className={classes.drawerTitle}>פרטים אישיים</div>
              </ListItem>
            </Link>
          </List>
          <Divider />
          <List>
            {/* <ListItem button style={getButtonStyle('payment')} onClick={() => setOpenPaymentMethod(true)}>
              <ListItemIcon><MenuBookIcon /></ListItemIcon>
              <div className={classes.drawerTitle}>פרטי תשלום</div>
            </ListItem> */}
            <ListItem
              button
              style={getButtonStyle("saveAsApp")}
              onClick={() => setOpenSaveAsApp(true)}
            >
              <ListItemIcon>
                <SaveIcon />
              </ListItemIcon>
              <div className={classes.drawerTitle}>שמירה כאפליקציה</div>
            </ListItem>
            <Link
              onClick={() => {
                setCurrentPage("contract");
                setOpen(false);
              }}
              to="/supplier/contract"
              className={classes.link}
            >
              <ListItem button style={getButtonStyle("contract")}>
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <div className={classes.drawerTitle}>תנאי שימוש</div>
              </ListItem>
            </Link>
            <ListItem button onClick={() => logout()} className={classes.link}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <div className={classes.drawerTitle}>יציאה</div>
            </ListItem>
          </List>
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {mobile ? (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h5" noWrap className={classes.pageTitle}>
              {userFirstName
                ? `JAMA Admin Dashboard - האזור האישי של ${userFirstName}`
                : `JAMA Admin Dashboard`}
            </Typography>
          </Toolbar>
        </AppBar>
      ) : (
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" noWrap className={classes.pageTitle}>
              {userFirstName
                ? `JAMA Admin Dashboard - האזור האישי של ${userFirstName}`
                : `JAMA Admin Dashboard`}
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      {mobile ? (
        <SwipeableDrawer
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          {props.main.state.userData !== null && sideListContent(props)}
        </SwipeableDrawer>
      ) : (
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {props.main.state.userData !== null && sideListContent(props)}
          {/* {props.main.state.userData !== null && 'permissions' in props.main.state.userData && sideListContent(props)} */}
        </Drawer>
      )}
      {mobile ? (
        <main className={classes.mobile}>
          <div className={classes.drawerHeader} />
          {content(props)}
        </main>
      ) : (
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {content(props)}
        </main>
      )}
    </div>
  );
}
