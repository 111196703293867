import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Divider from "@material-ui/core/Divider";
import { components } from "react-select";
import Grid from "@material-ui/core/Grid";
import ReactPlayer from "react-player";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewArticle(props) {
  const handleClose = () => {
    props.closePreviewArticle();
  };

  const getHTML = (htmlBody) => {
    const body = htmlBody;
    const html =
      '<html><head><link href="https://fonts.googleapis.com/css?family=Varela+Round&display=swap" rel="stylesheet"></head><body">' +
      body +
      "</body></html>";
    return (
      <div
        dir="rtl"
        style={{
          textAlign: "right",
          direction: "rtl",
          fontFamily: "Varela Round, sans-serif",
        }}
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  };

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={"md"}
        maxWidth={"md"}
        style={{ height: "90vh" }}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="alert-dialog-slide-title"
          style={{ float: "center", textAlign: "center" }}
        >
          <div style={{ fontFamily: "Varela Round, sans-serif" }}>
            תצוגה מקדימה
          </div>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {props.data.components.map((component, index) => (
            <div>
              {component.type === "text" && (
                <div>
                  {getHTML(
                    props.local
                      ? draftToHtml(
                          convertToRaw(
                            component.editorState.getCurrentContent()
                          )
                        )
                      : component.html
                  )}
                </div>
              )}
              {component.type === "image" && (
                <div>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={0}
                  >
                    <Grid item sm={12} md={8} lg={8} xl={6}>
                      <img
                        alt={component.image}
                        src={
                          props.local
                            ? URL.createObjectURL(component.file)
                            : component.image
                        }
                        width="100%"
                        height="auto"
                        style={{ marginTop: 15, marginBottom: 15 }}
                      />
                      <div
                        style={{
                          fontFamily: "Varela Round, sans-serif",
                          fontSize: 16,
                          textAlign: "right",
                        }}
                      >
                        {component.imageTitle}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              {component.type === "video" && (
                <div>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="flex-end"
                    spacing={0}
                  >
                    <Grid item sm={12} md={12} lg={8} xl={6}>
                      <ReactPlayer
                        controls={true}
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          maxWidth: "100%",
                        }}
                        url={component.link}
                      />
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          ))}
          {/* <iframe title="preview" style={{frameBorder: 0, borderRadius: 0, border: 0, fontFamily: 'Varela Round, sans-serif', width: '100%', height: '90vh', direction: 'rtl', textAlign: 'right'}} srcdoc={getHTML(props.data.html)}></iframe> */}
        </DialogContent>
        <center>
          <DialogActions style={{ float: "left" }}>
            <Button onClick={handleClose} color="primary">
              <div style={{ fontFamily: "Varela Round, sans-serif" }}>סגור</div>
            </Button>
          </DialogActions>
        </center>
      </Dialog>
    </div>
  );
}
