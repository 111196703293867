import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


// Components
import Tip from './Tip';
import NewTip from './NewTip';
import Skeleton from './Skeleton';
import DeleteDialog from './DeleteDialog';

import InfiniteScroll from 'react-infinite-scroller';

import * as firebase from 'firebase';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh'
  },
  success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
  h2: {
    float: 'left',
    textWeight: 'bold',
    display: 'inline-block',
    textAlign: 'left',
    color: 'rgb(100,100,100)',
    fontSize: 20,
  },
  newTip: {
    background: 'linear-gradient(to right , #438BEC, #57CBF2)',
    color: 'white'
  },
  fab: {
    position: 'fixed',
    background: 'linear-gradient(to right , #438BEC, #57CBF2)',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: 'left',
    textAlign: 'left'
  },
});

function sleep(delay = 0) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000)

  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  return datevalues[2] + '/' + datevalues[1] + '/' + datevalues[0] + ' ' + datevalues[3] + ':' + datevalues[4]
}


class Tips extends React.Component {

  state = {
    isLoading: true,
    openNewTip: false,
    tips: [],
    openDeleteDialog: false,
    deleting: false,
    adding: false,
    updating: false,
    previewLocal: false,
    currentTipId: null,
    options: [],
    tipsToShow: [],
    tipsCounter: 0,
    sortBy: '',
    sortData: '',
    sortedTips: [],
    formattedTips: [],
  }

  componentWillMount() {
    if (localStorage.getItem('suppliers') === undefined || localStorage.getItem('suppliers') === null) {
      axios.get('https://jama-server.appspot.com/api/suppliers').then((response) => {
        const arr = response.data
        const searchArray = []
        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          searchArray.push({ name: element.firstName.toLowerCase() + ' ' + element.lastName.toLowerCase(), id: element.id, type: 'supplier', profileImage: element.profileImage })
        }
        searchArray.push({ name: 'Jama', type: 'jama' })
        searchArray.reverse()
        this.setState({ 'options': searchArray })

      })
        .catch((error) => {
          this.setState({ 'isLoading': false });
          console.log(error);
        });
    } else {
      const arr = JSON.parse(localStorage.getItem("suppliers"))
      const searchArray = []
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        searchArray.push({ name: element.firstName.toLowerCase() + ' ' + element.lastName.toLowerCase(), id: element.id, type: 'supplier', profileImage: element.profileImage })
      }
      searchArray.push({ name: 'Jama', type: 'jama' })
      searchArray.reverse()
      this.setState({ 'options': searchArray })
    }
  }

  getTips = () => {
    axios.get('https://jama-server.appspot.com/api/tips').then((response) => {
      console.log(response.data);
      var tips = response.data
      tips.sort((a, b) => (new Date(b.createdAt._seconds * 1000).getTime()) - (new Date(a.createdAt._seconds * 1000).getTime()));
      this.setState({ 'tips': tips, 'isLoading': false, sortedTips: response.data, formattedTips: tips });
    })
      .catch((error) => {
        this.setState({ 'isLoading': false });
        console.log(error);
      });
  }

  componentDidMount() {
    this.getTips()
  }

  deleteTip = () => {
    this.setState({ deleting: true })
    console.log(this.state.currentTipId);
    axios.post('https://jama-server.appspot.com/api/tips/' + this.state.currentTipId + '/delete').then((response) => {
      console.log(response.data);
      this.setState({ deleting: false, openDeleteDialog: false })
      this.getTips()
    })
      .catch((error) => {
        this.setState({ 'isLoading': false });
        console.log(error);
      });
  }

  updateTip = (id, field, value) => {
    const tips = this.state.tips
    tips.forEach((tip, index) => {
      if (tip.id === id) {
        tips[index][field] = value
        this.setState({ tips: tips })
      }
    });

    axios.post('https://jama-server.appspot.com/api/tips/' + id + '/update', { [field]: value }, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
      console.log(response.data);
    })
      .catch((error) => {
        this.setState({ 'isLoading': false });
        console.log(error);
      });
  }

  loadMoreTips = () => {
    if (!this.state.tipsToShow) return;
    this.setState({
      tipsToShow: [...this.state.tipsToShow, ...this.state.sortedTips.slice(this.state.tipsCounter, this.state.tipsCounter + 10)],
      tipsCounter: this.state.tipsCounter + 10
    })
  }


  handleChangeSortBy = (event) => {
    this.setState({ sortBy: event.target.value, sortData: '', sortedTips: this.state.formattedTips })
  };

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value })
    if (name === 'sortData') { this.sortData(event.target.value) }
  };

  sortData = (text) => {
    if (text === '') {
      this.setState({ sortedTips: this.state.formattedTips, tipsCounter: 0, tipsToShow: [] })
      return
    }
    const sortTypeText = this.state.sortBy
    var sortId = ''
    if (sortTypeText === 'תאריך') {
      sortId = 'date'
    } else if (sortTypeText === 'שם מפעילה') {
      sortId = 'name'
    } else if (sortTypeText === 'תיאור') {
      sortId = 'tip'
    }
    const data = this.state.formattedTips
    const sortedArray = []
    for (var i = 0; i < data.length; i++) {
      let field;
      if (sortId === 'date') {
        const dateAsString = getDate(data[i].createdAt)
        field = dateAsString.toLowerCase();

      } else if (sortId === 'name') {
        field = typeof data[i]['publisher'][sortId] === 'string' ? data[i]['publisher'][sortId].toLowerCase() : toString(data[i]['publisher'][sortId])

      } else {
        field = typeof data[i][sortId] === 'string' ? data[i][sortId].toLowerCase() : toString(data[i][sortId])
      }
      if (field.includes(text.toLowerCase())) {
        sortedArray.push(data[i])
      }
    }
    this.setState({ sortedTips: sortedArray, tipsCounter: 0, tipsToShow: [] })
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        <NewTip main={this} />
        <DeleteDialog main={this} />
        {this.state.isLoading === true ? (
          <div>
            <Skeleton />
          </div>
        ) : (
            <SnackbarProvider maxSnack={10} classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}>

              <div style={{ width: '100%', marginTop: 0, marginBottom: 10 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">מיין לפי</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sortBy}
                    onChange={this.handleChangeSortBy}
                  >
                    <MenuItem value={'שם מפעילה'}>שם מפעילה</MenuItem>
                    <MenuItem value={'תיאור'}>תיאור</MenuItem>
                    <MenuItem value={'תאריך'}>תאריך</MenuItem>
                  </Select>
                </FormControl>
                <TextField id="standard-basic" label={this.state.sortBy === '' ? 'בחר ערך' : this.state.sortBy} className={classes.formControl} onChange={this.handleChangeText('sortData')} value={this.state.sortData} />
              </div>



              <InfiniteScroll
                pageStart={this.state.tipsCounter}
                loadMore={this.loadMoreTips}
                hasMore={this.state.tipsCounter < this.state.sortedTips.length}
                style={{ width: '100%' }}
                loader={<div className="loader"></div>}
              >
                <Grid container spacing={mobile ? 2 : 4}>
                  <div style={{ width: '100%' }}></div>
                  {this.state.tipsToShow.map((tip, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Tip data={tip} main={this} />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            </SnackbarProvider>
          )}
        <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => this.setState({ openNewTip: true })}>
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

Tips.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Tips));
