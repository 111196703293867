import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import * as firebase from 'firebase';
import RegisrationsDialog from './RegisrationsDialog'
import EditDialog from './EditDialog'
import CopyDialog from './CopyDialog'

const useStyles = makeStyles({
  card: {
    maxWidth: '100%',
    textAlign: 'left',
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.5)',
    fontFamily: "'Varela Round', sans-serif"
  },
  media: {
    height: 190,
  },
  registrationsButton: {
    color: '#438BEC',
    float: 'right',
    fontFamily: "'Varela Round', sans-serif"
  },
  deleteButton: {
    color: 'red',
    float: 'right',
    fontFamily: "'Varela Round', sans-serif"
  },
  previewButton: {
    float: 'left',
    fontFamily: "'Varela Round', sans-serif"
  },
  tag: {
    fontSize: 12,
    height: 25,
    marginTop: 10,
    marginRight: 5,
    fontFamily: "'Varela Round', sans-serif"
  },
  miniTitle: {
    marginTop: 10,
    fontSize: 14
  },
  root: {
    fontFamily: 'Varela Round',
    '& .MuiFormControlLabel-label': {
      fontFamily: 'Varela Round',
      fontSize: 14
    },
    '& .MuiFormControlLabel-root': {
      fontFamily: 'Varela Round',
      fontSize: 14
    }
  }
});

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000)

  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  return datevalues[2] + '/' + datevalues[1] + '/' + datevalues[0] + ' ' + datevalues[3] + ':' + datevalues[4]
}

function createData(name, phone, email, status, button) {
  return { name, phone, email, status, button };
}

export default function MediaCard(props) {
  const classes = useStyles();
  const [signups, setSignups] = React.useState([]);
  const [registrations, setRegistrations] = React.useState([]);
  const [openTable, setOpenTable] = React.useState(false);
  const [openCopy, setOpenCopy] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  React.useEffect(() => {
    getActivities()
  }, [])

  const handleChangePromote = (event, field, data) => {
    props.main.updateActivity(data.id, field, event.target.checked)
  }

  const handleChangePublish = (event, field, data) => {
    props.main.updateActivity(data.id, field, event.target.checked)
  }

  const getActivities = () => {
    axios.get('https://jama-server.appspot.com/api/activities/' + props.data.id + '/get-signups').then((response) => {
      // console.log(response.data);
      setSignups(response.data.length);
      var users = []
      response.data.forEach(signup => {
        // console.log(signup)
        users.push(createData(signup.userData.firstName + ' ' + signup.userData.lastName, signup.userData.phone, signup.userData.email, signup.status, ''))
      });
      setRegistrations(users)
      // console.log(users)
    })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className={classes.root}>
      {openTable && (
        <RegisrationsDialog openTable={openTable} setOpenTable={setOpenTable} tableData={registrations} id={props.data.id} activityData={props.data} />
      )}
      {openEdit && (
        <EditDialog getActivities={props.getActivities} openEdit={openEdit} setOpenEdit={setOpenEdit} id={props.data.id} activityData={props.data} />
      )}
      {openCopy && (
        <CopyDialog getActivities={props.getActivities} openCopy={openCopy} setOpenCopy={setOpenCopy} id={props.data.id} activityData={props.data} />
      )}
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={props.data.images[0]}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2" style={{ fontFamily: "'Varela Round', sans-serif", maxWidth: '100%', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
            {props.data.title}
          </Typography>
          <div className={classes.miniTitle}>{props.data.description}</div>
          <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>יום ושעה:</div>
          {props.data.withoutDate ? (
            <div className={classes.miniTitle}>בתיאום מראש</div>
          ) : (
              <div className={classes.miniTitle}>{getDate(props.data.date)}</div>
            )}
          <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>מיקום:</div>
          <div className={classes.miniTitle}>{props.data.location}</div>
          <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>ביקשו להירשם:</div>
          <div className={classes.miniTitle}>{signups}</div>
          {(props.data.price !== null && props.data.price !== undefined) ? (
            <div>
              <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>מחיר:</div>
              <div className={classes.miniTitle}>{props.data.price} ש״ח</div>
            </div>
          ) : (
              <div>
                <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>פירוט לגבי המחיר:</div>
                <div>(למשל: מחיר ליחיד/מחיר לזוג, מחיר לסדרת ומפגשים וכו')</div>

                {props.data.items.map(i => {
                  return (
                    <div className={classes.miniTitle}>{i.item} - {i.price} {i.price !== "חינם" && `ש"ח`}</div>
                  )
                })}
              </div>
            )}
          {/* <div className={classes.miniTitle}>מזהה מערכת</div>
          <div className={classes.miniTitle}>{props.data.id}</div> */}
          <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>תחומי עניין:</div>
          {props.data.interests.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle} style={{ fontWeight: 'bold' }}>טווחי גילאים:</div>
          {props.data.ages.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          {/* <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              disabled={props.data.status !== 'active' ? true : false}
              control={<Switch
                checked={props.data.publish} onChange={(e) => handleChangePublish(e, 'publish', props.data)}
                name="checkedA" />}
              style={{ fontFamily: 'Varela Round, sans-serif' }}
              label={'פרסם'}
            />
          </FormGroup> */}
          {/* <FormGroup row style={{marginTop: 10}}>
              <FormControlLabel
                control={<Switch
                  checked={props.data.promote} onChange={(e) => handleChangePromote(e, 'promote', props.data)} 
                  name="checkedA" />}
                style={{fontFamily: 'Varela Round, sans-serif'}}
                label={'קדם על פני אחרים'}
              />
            </FormGroup> */}
        </CardContent>
        {/* </CardActionArea> */}
        <CardActions style={{ width: '100%' }}>
          <Button disabled={props.data.status !== 'active' ? true : false} size="medium" color="primary" className={classes.registrationsButton} onClick={() => setOpenTable(true)}>
            רשומות
            </Button>
          <Button size="medium" color="primary" className={classes.registrationsButton} onClick={() => setOpenCopy(true)}>
            שיכפול
            </Button>
          <Button size="medium" color="primary" className={classes.registrationsButton} onClick={() => setOpenEdit(true)}>
            עריכה
            </Button>
          {/* <Button size="medium" color="primary" className={classes.deleteButton} onClick={ ()=> props.main.setState({openDeleteDialog: true, currentActivityId: props.data.id})}>
              מחיקה
            </Button> */}
        </CardActions>
      </Card>
    </div>
  );
}
