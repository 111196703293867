import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider, useSnackbar } from "notistack";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Badge from "@material-ui/core/Badge";

// Components
import Activity from "./Activity";
import NewActivity from "./NewActivity";
import Skeleton from "./Skeleton";
import DeleteDialog from "./DeleteDialog";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import * as firebase from "firebase";

import InfiniteScroll from "react-infinite-scroller";

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100vw",
    height: "100vh",
  },
  success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
  h2: {
    float: "left",
    textWeight: "bold",
    display: "inline-block",
    textAlign: "left",
    color: "rgb(100,100,100)",
    fontSize: 20,
  },
  NewActivity: {
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    color: "white",
  },
  fab: {
    position: "fixed",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: "left",
    textAlign: "left",
  },
});

const getDate = (timestamp) => {
  var date = new Date(timestamp._seconds * 1000);

  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  return (
    datevalues[2] +
    "/" +
    datevalues[1] +
    "/" +
    datevalues[0] +
    " " +
    datevalues[3] +
    ":" +
    datevalues[4]
  );
};

// const test_activity = {
//   ages: ["0-6"],
//   createdAt: { _seconds: 1589480244, _nanoseconds: 176000000 },
//   date: { _seconds: -61524201600, _nanoseconds: 0 },
//   description: "בדיקה",
//   id: "iF541g81b1tm8GYnWV7B1111111111111111",
//   imageTitle: "",
//   images: ["https://firebasestorage.googleapis.com/v0/b/jamaap…%2Ff281d974-b6b8-4467-93df-3b0dd44d7815?alt=media"],
//   interests: ["התפתחות הבייבי"],
//   items: [{ price: "0280", id: 101, item: "6 מפגשים של שעה" }],
//   limit: "3",
//   location: "Zoom",
//   promote: false,
//   publish: false,
//   publisher: { profileImage: "https://s3.amazonaws.com/jama.dev/images/37a5470c-ce59-4a9f-b5cb-d7a946da7850.jpg", name: "בדיקה", id: "ric0yBvr0DhSDtulXV9r", type: "supplier" },
//   status: "pending",
//   title: "בדיקה",
//   type: "online",
//   withLimitation: true,
//   withoutDate: false,
// }

class Activities extends React.Component {
  state = {
    isLoading: true,
    openNewActivity: false,
    activities: [],
    openPreviewActivity: false,
    openEditActivity: false,
    openDeleteDialog: false,
    currentActivity: { components: [] },
    currentActivityId: null,
    deleting: false,
    adding: false,
    updating: false,
    previewLocal: false,
    openRegistrations: true,
    options: [],
    alreadyUpdated: null,
    type: 1,
    activitiesToShow: [],
    activitiesCounter: 0,
    sortBy: "",
    sortData: "",
    sortedActivities: [],
    formattedActivities: [],
    pending: [],
    approved: [],
    rejected: [],
    withDate: [],
    withoutDate: [],
    passed: [],
    refreshData: false,
    currentIndexOfPagination: 1,
  };

  handleChangeType = (event, newValue) => {
    this.setState({
      type: newValue,
      activitiesToShow: [],
      activitiesCounter: 0,
      sortData: "",
      sortBy: "",
      currentIndexOfPagination: 1,
    });
    if (newValue === 0) {
      this.setState({
        sortedActivities: this.state.pending,
        formattedActivities: this.state.pending,
        currentIndexOfPagination: 1,
      });
    } else if (newValue === 1) {
      this.setState({
        sortedActivities: this.state.withDate,
        formattedActivities: this.state.withDate,
        currentIndexOfPagination: 1,
      });
    } else if (newValue === 2) {
      // this.state.withoutDate.reverse();
      this.setState({
        sortedActivities: this.state.withoutDate,
        formattedActivities: this.state.withoutDate,
        currentIndexOfPagination: 1,
      });
    } else {
      this.setState({
        sortedActivities: this.state.passed,
        formattedActivities: this.state.passed,
        currentIndexOfPagination: 1,
      });
    }
  };

  getActivities = (type = 1) => {
    var withDate = [];
    var withoutDate = [];
    var passed = [];
    var pending = [];
    var approved = [];
    var rejected = [];
    if (this.state.alreadyUpdated !== null) return;
    axios
      .get("https://jama-server.appspot.com/api/activities")
      .then((response) => {
        var activities = response.data;

        // activities = [...activities, test_activity]

        activities.sort(
          (a, b) =>
            new Date(b.createdAt._seconds * 1000).getTime() -
            new Date(a.createdAt._seconds * 1000).getTime()
        );
        activities.reverse();
        activities.forEach((element) => {
          if (element.status === "rejected") {
            rejected.push(element);
          } else if (element.status === "pending") {
            pending.push(element);
          } else {
            approved.push(element);
          }
        });
        approved.forEach((element) => {
          if (element.withoutDate) {
            withoutDate.unshift(element);
          } else {
            if (
              new Date().getTime() <=
              new Date(element.date._seconds * 1000).getTime()
            ) {
              withDate.push(element);
            } else {
              passed.push(element);
            }
          }
        });

        passed.reverse();

        withDate.sort(
          (a, b) =>
            new Date(a.date._seconds * 1000).getTime() -
            new Date(b.date._seconds * 1000).getTime()
        );

        if (this.state.type === 0) {
          this.setState(
            { activities: pending, formattedActivities: pending },
            () => {
              this.handleChangeSortByKnownFilter();
            }
          );
        } else if (this.state.type === 1) {
          this.setState(
            { activities: withDate, formattedActivities: withDate },
            () => {
              this.handleChangeSortByKnownFilter();
            }
          );
        } else if (this.state.type === 2) {
          this.setState(
            { activities: withoutDate, formattedActivities: withoutDate },
            () => {
              this.handleChangeSortByKnownFilter();
            }
          );
        } else {
          this.setState(
            { activities: passed, formattedActivities: passed },
            () => {
              this.handleChangeSortByKnownFilter();
            }
          );
        }
        this.setState({
          approved: approved,
          rejected: rejected,
          pending: pending,
          withDate: withDate,
          withoutDate: withoutDate,
          passed: passed,
          isLoading: false,
        });
        // this.setState({ approved: approved, rejected: rejected, pending: pending, activities: withDate, withDate: withDate, withoutDate: withoutDate, passed: passed, formattedActivities: withDate, sortedActivities: withDate, 'isLoading': false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  componentDidMount() {
    this.getActivities();
  }
  

  componentWillMount() {
    if (
      localStorage.getItem("suppliers") === undefined ||
      localStorage.getItem("suppliers") === null
    ) {
      axios
        .get("https://jama-server.appspot.com/api/suppliers")
        .then((response) => {
          const arr = response.data;
          const searchArray = [];
          for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            searchArray.push({
              name:
                element.firstName.toLowerCase() +
                " " +
                element.lastName.toLowerCase(),
              id: element.id,
              type: "supplier",
              profileImage: element.profileImage,
            });
          }
          searchArray.push({ name: "Jama", type: "jama" });
          searchArray.reverse();
          this.setState({ options: searchArray });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.log(error);
        });
    } else {
      const arr = JSON.parse(localStorage.getItem("suppliers"));
      const searchArray = [];
      for (let index = 0; index < arr.length; index++) {
        const element = arr[index];
        searchArray.push({
          name:
            element.firstName.toLowerCase() +
            " " +
            element.lastName.toLowerCase(),
          id: element.id,
          type: "supplier",
          profileImage: element.profileImage,
        });
      }
      searchArray.push({ name: "Jama", type: "jama" });
      searchArray.reverse();
      this.setState({ options: searchArray });
    }
  }

  openPreviewActivity = (data, local) => {
    this.setState({ currentActivity: data, previewLocal: local }, () => {
      console.log(data);
      this.setState({ openPreviewActivity: true });
    });
  };

  closePreviewActivity = () => {
    this.setState({ openPreviewActivity: false, previewLocal: false });
  };

  deleteActivity = () => {
    this.setState({ deleting: true });
    console.log(this.state.currentActivityId);
    axios
      .post(
        "https://jama-server.appspot.com/api/Activities/" +
          this.state.currentActivityId +
          "/delete"
      )
      .then((response) => {
        console.log(response.data);

        this.setState({ deleting: false, openDeleteDialog: false });
        this.getActivities();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  updateActivity = (id, field, value) => {
    const activities = this.state.sortedActivities;
    activities.forEach((activity, index) => {
      if (activity.id === id) {
        activities[index][field] = value;
        this.setState({ activities: activities });
      }
    });
    axios
      .post(
        "https://jama-server.appspot.com/api/activities/" + id + "/update",
        { [field]: value },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        // axios.post('http://localhost:8080/api/activities/' + id + '/update', { [field]: value }, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
        console.log(response.data);
        this.getActivities(this.state.type);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  setSelectedActivity = (data, typeOfAction) => {
    if (typeOfAction === "preview") {
      this.setState({ openPreviewActivity: true, currentActivity: data });
    } else {
      this.setState({ openEditActivity: true, currentActivity: data });
    }
  };

  loadMoreActivities = () => {
    if (!this.state.activitiesToShow) return;
    this.setState({
      activitiesToShow: [
        ...this.state.activitiesToShow,
        ...this.state.formattedActivities.slice(
          this.state.activitiesCounter,
          this.state.activitiesCounter + 10
        ),
      ],
      activitiesCounter: this.state.activitiesCounter + 10,
    });
  };

  handleChangeSortBy = (event) => {
    this.setState({ sortBy: event.target.value, sortData: "" });
    console.log(event.target.value);
    console.log(this.state.formattedActivities);
    if (event.target.value === "מקודמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].promote) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
    if (event.target.value === "לא מקודמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i].promote) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
    if (event.target.value === "מפורסמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].publish) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
    if (event.target.value === "לא מפורסמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i].publish) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
    if (event.target.value === "פופולריות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].popular) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
    if (event.target.value === "לא פופולריות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i].popular) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    }
  };

  handleChangeSortByKnownFilter = () => {
    if (this.state.sortBy === "מקודמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].promote) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    } else if (this.state.sortBy === "לא מקודמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i].promote) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    } else if (this.state.sortBy === "מפורסמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (data[i].publish) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    } else if (this.state.sortBy === "לא מפורסמות") {
      const data = this.state.formattedActivities;
      const sortedArray = [];
      for (var i = 0; i < data.length; i++) {
        if (!data[i].publish) {
          sortedArray.push(data[i]);
        }
      }

      this.setState({ sortedActivities: sortedArray, refreshData: true });
    } else {
      this.sortData(this.state.sortData);
      // this.setState({ sortedActivities: this.state.formattedActivities, refreshData: true })
    }
  };

  handleChangeText = (name) => (event) => {
    this.setState({ [name]: event.target.value });
    if (name === "sortData") {
      this.sortData(event.target.value);
    }
  };

  sortData = (text) => {
    if (text === "") {
      this.setState({
        sortedActivities: this.state.formattedActivities,
        refreshData: true,
      });
      return;
    }
    const sortTypeText = this.state.sortBy;
    var sortId = "";
    if (sortTypeText === "שם מפעילה") {
      sortId = "name";
    } else if (sortTypeText === "כותרת") {
      sortId = "title";
    } else if (sortTypeText === "תיאור") {
      sortId = "description";
    } else if (sortTypeText === "תאריך") {
      sortId = "date";
    }
    const data = this.state.formattedActivities;
    const sortedArray = [];
    for (var i = 0; i < data.length; i++) {
      let field;
      if (sortId === "date") {
        const dateAsString = getDate(data[i].createdAt);
        field = dateAsString.toLowerCase();
      } else if (sortId === "name") {
        field =
          typeof data[i]["publisher"][sortId] === "string"
            ? data[i]["publisher"][sortId].toLowerCase()
            : toString(data[i]["publisher"][sortId]);
      } else {
        field =
          typeof data[i][sortId] === "string"
            ? data[i][sortId].toLowerCase()
            : toString(data[i][sortId]);
      }
      if (field.includes(text.toLowerCase())) {
        sortedArray.push(data[i]);
      }
    }

    this.setState({ sortedActivities: sortedArray, refreshData: true });
  };

  loadNextBulkOfFeed = () => {
    console.log(this.state.currentIndexOfPagination);
    this.setState(
      { currentIndexOfPagination: this.state.currentIndexOfPagination + 1 },
      () => {
        console.log(this.state.currentIndexOfPagination);
      }
    );
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        {this.state.openNewActivity && <NewActivity main={this} />}
        <DeleteDialog main={this} />
        {this.state.isLoading === true ? (
          <div>
            <Skeleton />
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ width: "100%", marginTop: 0, marginBottom: 10 }}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label">
                    מיין לפי
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.sortBy}
                    onChange={(e) => this.handleChangeSortBy(e)}
                  >
                    <MenuItem name="0" value={"שם מפעילה"}>
                      שם מפעילה
                    </MenuItem>
                    <MenuItem name="1" value={"כותרת"}>
                      כותרת
                    </MenuItem>
                    <MenuItem name="2" value={"תיאור"}>
                      תיאור
                    </MenuItem>
                    <MenuItem name="3" value={"תאריך"}>
                      תאריך
                    </MenuItem>
                    <MenuItem name="4" value={"מקודמות"}>
                      מקודמות
                    </MenuItem>
                    <MenuItem name="5" value={"לא מקודמות"}>
                      לא מקודמות
                    </MenuItem>
                    <MenuItem name="6" value={"מפורסמות"}>
                      מפורסמות
                    </MenuItem>
                    <MenuItem name="7" value={"לא מפורסמות"}>
                      לא מפורסמות
                    </MenuItem>
                    <MenuItem name="8" value={"פופולריות"}>
                      פופולריות
                    </MenuItem>
                    <MenuItem name="9" value={"לא פופולריות"}>
                      לא פופולריות
                    </MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  id="standard-basic"
                  label={
                    this.state.sortBy === "" ? "בחר ערך" : this.state.sortBy
                  }
                  className={classes.formControl}
                  onChange={this.handleChangeText("sortData")}
                  value={this.state.sortData}
                />
              </div>
              <Paper
                className={classes.root}
                elevation={0}
                style={{
                  background: "none",
                  marginBottom: 20,
                  flexGrow: 1,
                  minWidth: 100,
                  width: "100%",
                  height: 50,
                }}
              >
                <Tabs
                  value={this.state.type}
                  onChange={this.handleChangeType}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.pending.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          ממתין לאישור
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.withDate.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          עם תאריך
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.withoutDate.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          ללא תאריך
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.passed.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          עבר זמנם
                        </div>
                      </Badge>
                    }
                  />
                </Tabs>
              </Paper>
            </div>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => this.loadNextBulkOfFeed()}
              hasMore={
                9 * this.state.currentIndexOfPagination <=
                this.state.sortedActivities.length
              }
              style={{ width: "100%" }}
              loader={
                <div className="loader" key={0}>
                  <CircularProgress size={50} />
                </div>
              }
            >
              <Grid container spacing={mobile ? 2 : 4}>
                <div style={{ width: "100%" }}></div>
                <>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div
                      style={{
                        textAlign: "right",
                        width: "100%",
                        fontWeight: "bold",
                        fontSize: 18,
                      }}
                    >
                      {this.state.sortedActivities.length} פעילויות
                    </div>
                  </Grid>
                  {this.state.sortedActivities
                    .slice(0, 9 * this.state.currentIndexOfPagination)
                    .map((activity, index) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        key={index}
                      >
                        <Activity
                          index={index}
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                          refreshData={this.state.refreshData}
                          handleRefresh={() =>
                            this.setState({ refreshData: false })
                          }
                        />
                      </Grid>
                    ))}
                </>
              </Grid>
            </InfiniteScroll>
          </SnackbarProvider>
        )}
        <Fab
          color="primary"
          aria-label="add"
          className={classes.fab}
          onClick={() => this.setState({ openNewActivity: true })}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Activities));
