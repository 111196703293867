import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

import axios from "axios";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
}));

const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    tip: props.tipData.tip,
  });
  const [tags, setTags] = React.useState([]);
  const [errorTip, setErrorTip] = React.useState(false);
  const [errorTags, setErrorTags] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);

  // React.useEffect(() => {
  // Update the document title using the browser API
  // setState({
  //     ...state,
  //     tip: props.tipData.tip,
  //   interests: props.tipData.interests,

  //   relNursing,
  //   relFitNutrition,
  //   relMeetNewFriends,
  //   relTipsTools,
  //   relFunWithBaby,
  //   relBabyDev,
  //   relInfantNutrition,
  //   relPregnancyBirth,
  //     });
  // }, []);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const edit = () => {
    var allowToContinue = true;
    setErrorTip(false);
    setErrorTags(false);
    setErrorAgeRange(false);

    if (Object.keys(interest).every((k) => !interest[k])) {
      setErrorTags(true);
      allowToContinue = false;
    }

    if (Object.keys(agesNew).every((k) => !agesNew[k])) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }

    if (state.tip === "") {
      setErrorTip(true);
      allowToContinue = false;
    }

    if (allowToContinue) {
      setLoading(true);
      let data = {
        tip: state.tip,

        relNursing,
        relFitNutrition,
        relMeetNewFriends,
        relTipsTools,
        relFunWithBaby,
        relBabyDev,
        relInfantNutrition,
        relPregnancyBirth,

        AFForEveryone,
        AFForMomBaby,
        AFForMoms,
        AFNineToTwelve,
        AFPregnancy,
        AFSixToNine,
        AFThreeToSix,
        AFTwelveToTwentyFour,
        AFTwentyFourPlus,
        AFZeroToSix,
        AFZeroToThree,
        AFZeroToTwelve,
      };
      axios
        .post(
          "https://jama-server.appspot.com/api/tips/" +
            props.tipData.id +
            "/update",
          data,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          // axios.post('http://localhost:8080/api/tips/' + props.tipData.id + '/update', state, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
          setLoading(false);
          props.getTips();
          props.setOpenEdit(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleChange = (e, v) => {
    setTags(v);
    setState({ ...state, interests: v });
  };

  const [interest, setInterest] = React.useState({
    relNursing: false,
    relFitNutrition: false,
    relMeetNewFriends: false,
    relTipsTools: false,
    relFunWithBaby: false,
    relBabyDev: false,
    relInfantNutrition: false,
    relPregnancyBirth: false,
  });

  const {
    relNursing,
    relFitNutrition,
    relMeetNewFriends,
    relTipsTools,
    relFunWithBaby,
    relBabyDev,
    relInfantNutrition,
    relPregnancyBirth,
  } = interest;

  const handleChangeCheckBox = (event) => {
    setInterest({
      ...interest,
      [event.target.name]: event.target.checked,
    });
  };

  console.log(interest);

  ///

  const [agesNew, setAgesNew] = React.useState({
    AFForEveryone: false,
    AFForMomBaby: false,
    AFForMoms: false,
    AFNineToTwelve: false,
    AFPregnancy: false,
    AFSixToNine: false,
    AFThreeToSix: false,
    AFTwelveToTwentyFour: false,
    AFTwentyFourPlus: false,
    AFZeroToSix: false,
    AFZeroToThree: false,
    AFZeroToTwelve: false,
  });

  const {
    AFForEveryone,
    AFForMomBaby,
    AFForMoms,
    AFNineToTwelve,
    AFPregnancy,
    AFSixToNine,
    AFThreeToSix,
    AFTwelveToTwentyFour,
    AFTwentyFourPlus,
    AFZeroToSix,
    AFZeroToThree,
    AFZeroToTwelve,
  } = agesNew;

  const handleChangeCheckBoxAges = (event) => {
    setAgesNew({
      ...agesNew,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={props.openEdit}
        onClose={handleClose}
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right" }}>
          עריכת טיפ
        </DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>טיפ</div>
          <textarea
            className="textarea_box"
            value={state.tip}
            onChange={(e) => setState({ ...state, tip: e.target.value })}
            cols="40"
            rows="5"
          />
          {errorTip && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>תחומי עניין</div>

          <FormGroup
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 165,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={relNursing}
                  onChange={handleChangeCheckBox}
                  name="relNursing"
                />
              }
              label="הנקה"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relFitNutrition}
                  onChange={handleChangeCheckBox}
                  name="relFitNutrition"
                />
              }
              label="כושר ותזונה לאמא"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relMeetNewFriends}
                  onChange={handleChangeCheckBox}
                  name="relMeetNewFriends"
                />
              }
              label="להכיר חברות חדשות"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relTipsTools}
                  onChange={handleChangeCheckBox}
                  name="relTipsTools"
                />
              }
              label="טיפים וכלים להורים"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relFunWithBaby}
                  onChange={handleChangeCheckBox}
                  name="relFunWithBaby"
                />
              }
              label="כיף עם הבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relBabyDev}
                  onChange={handleChangeCheckBox}
                  name="relBabyDev"
                />
              }
              label="התפתחות הבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relInfantNutrition}
                  onChange={handleChangeCheckBox}
                  name="relInfantNutrition"
                />
              }
              label="תזונת תינוקות"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relPregnancyBirth}
                  onChange={handleChangeCheckBox}
                  name="relPregnancyBirth"
                />
              }
              label="הריון ולקראת לידה"
            />
          </FormGroup>
          {errorTags && <div className={classes.errorMessage}>שדה חובה</div>}

          {/* <Autocomplete
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
            multiple
            id="tags-filled"
            options={names.map((option) => option)}
            freeSolo
            value={state.interests}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  style={{ padding: 8 }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="textinput"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                label=""
                placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
              />
            )}
          /> */}

          <div className={classes.inputTitle}>טווחי גילאים</div>

          <FormGroup
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 165,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForEveryone}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForEveryone"
                />
              }
              label="לכולם"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForMomBaby}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForMomBaby"
                />
              }
              label="לאמא ולבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForMoms}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForMoms"
                />
              }
              label="לאמא"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFPregnancy}
                  onChange={handleChangeCheckBoxAges}
                  name="AFPregnancy"
                />
              }
              label="לאמא בהריון"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToThree}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToThree"
                />
              }
              label="0-3"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToSix}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToSix"
                />
              }
              label="0-6"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToTwelve}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToTwelve"
                />
              }
              label="0-12"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFThreeToSix}
                  onChange={handleChangeCheckBoxAges}
                  name="AFThreeToSix"
                />
              }
              label="3-6"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFSixToNine}
                  onChange={handleChangeCheckBoxAges}
                  name="AFSixToNine"
                />
              }
              label="6-9"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFNineToTwelve}
                  onChange={handleChangeCheckBoxAges}
                  name="AFNineToTwelve"
                />
              }
              label="9-12"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFTwelveToTwentyFour}
                  onChange={handleChangeCheckBoxAges}
                  name="AFTwelveToTwentyFour"
                />
              }
              label="12-24"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFTwentyFourPlus}
                  onChange={handleChangeCheckBoxAges}
                  name="AFTwentyFourPlus"
                />
              }
              label="24+"
            />
          </FormGroup>
          {errorAgeRange && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <center>
            <Button
              data-trans="signin-done-button"
              className={"done-button"}
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => edit()}
            >
              {loading ? <CircularProgress size={25} /> : "עריכת טיפ"}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: "100%", textAlign: "left" }}>
          <Button
            onClick={handleClose}
            style={{ float: "left" }}
            color="primary"
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
