import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';

import ReactPlayer from 'react-player';

const useStyles = makeStyles({
    card: {
        maxWidth: '100%',
        textAlign: 'left',
        boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.5)',
        fontFamily: "'Varela Round', sans-serif"
    },
    media: {
        height: 190,
    },
    deleteButton: {
        color: 'red',
        float: 'right',
        fontFamily: "'Varela Round', sans-serif"
    },
    previewButton: {
        float: 'left',
        fontFamily: "'Varela Round', sans-serif"
    },
    tag: {
        fontSize: 12,
        height: 25,
        marginTop: 10,
        marginRight: 5,
        fontFamily: "'Varela Round', sans-serif"
    },
    miniTitle: {
        marginTop: 10,
        fontSize: 14
    },
    root: {
        fontFamily: 'Varela Round',
        '& .MuiFormControlLabel-label': {
            fontFamily: 'Varela Round',
            fontSize: 14
        },
        '& .MuiFormControlLabel-root': {
            fontFamily: 'Varela Round',
            fontSize: 14
        }
    }
});

const getDate = (timestamp) => {
    var date = new Date(timestamp * 1000)
    var datevalues = [
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds(),
    ];
    return datevalues[2] + '/' + datevalues[1] + '/' + datevalues[0] + ' ' + datevalues[3] + ':' + datevalues[4]
}

export default function MediaCard(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>

            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar} src={props.data.profileImage}></Avatar>
                    }
                    title={props.data.name}
                    subheader={getDate(props.data.createdAt._seconds)}
                />
                {props.data.postType === 'video' &&
                    <ReactPlayer
                        url={props.data.media}
                        controls={true}
                        playing={false}
                        width="100%" height="220px"
                    />
                }
                {props.data.postType === 'image' &&
                    <CardMedia
                        className={classes.media}
                        image={props.data.media}
                        title={props.data.titleImage}
                    />
                }
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2" style={{ fontFamily: "'Varela Round', sans-serif" }}>
                        {props.data.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" style={{ fontFamily: "'Varela Round', sans-serif" }}>
                        {props.data.description}
                    </Typography>

                </CardContent>
                <CardActions style={{ width: '100%' }}>

                    <Button size="medium" color="primary" className={classes.deleteButton} onClick={() => props.main.setState({ openDeleteDialog: true, currentPostId: props.data.id })}>
                        מחיקה
            </Button>
                </CardActions>
            </Card>
        </div>
    );
}
