import { BrowserRouter } from "react-router-dom";
import React, { Component } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Redirect } from "react-router";
import Cookies from "universal-cookie";
import { Settings } from "./settings";
import CircularProgress from "@material-ui/core/CircularProgress";
// Components
import Login from "./Components/Login/Login";
import Dashboard from "./Components/Structure/Dashboard";
import firebase from "./Firebase";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { createBrowserHistory } from "history";
import axios from "axios";
import IdleTimer from "react-idle-timer";

import { create as createJss } from "jss";

import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

// Configure JSS
const jss = createJss({ plugins: [...jssPreset().plugins, rtl()] });

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const history = createBrowserHistory();
const colors = Settings.colors.light;

const font = "Varela Round, sans-serif";
const theme = createMuiTheme({
  fontFamily: font,
  direction: "rtl",
  palette: {
    background: {
      default: colors.background,
    },
    primary: {
      main: "#438BEC",
      white: "#ffffff",
      dark: "#454f63",
      gray: "#99a1a9",
    },
    secondary: {
      main: "#57CBF2",
    },
  },
});

class App extends React.Component {
  // constructor(props) {
  //   super(props)
  //   this.idleTimer = null
  //   this.handleOnIdle = this.handleOnIdle.bind(this)
  // }
  state = {
    isLoading: true,
    isSignedIn: null,
    userData: null,
    currentUser: null,
  };

  getTypeOfUserData = async (phone, user) => {
    // axios.post('https://jama-server.appspot.com/api/adminUsers/get-user-by-phone', {phone: this.state.phone},
    axios
      .post(
        "https://jama-server.appspot.com/api/adminUsers/get-user-by-phone",
        { phone: phone },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        // console.log(response.data);
        var users = response.data;
        console.log(users, "users");
        if (users.length > 0) {
          // return users[0]
          this.setState({
            isSignedIn: !!user,
            currentUser: user,
            userData: users[0],
            type: "admin",
            isLoading: false,
          });
        } else {
          // return null
          axios
            .post(
              "https://jama-server.appspot.com/api/suppliers/get-user-by-phone",
              { phone: phone },
              { headers: { "Content-Type": "application/json" } }
            )
            .then((response) => {
              console.log(response.data);
              var users = response.data;
              if (users.length > 0) {
                this.setState({
                  isSignedIn: !!user,
                  currentUser: user,
                  userData: users[0],
                  type: "supplier",
                  isLoading: false,
                });
              } else {
                firebase.auth().signOut();
              }
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    firebase.auth().onAuthStateChanged(async (user) => {
      // console.log('user 0523321187 ', user);
      console.log("Logged in:", !!user);
      if (!!user === true) {
        var phone = user?.phoneNumber?.replace("+972", "0");
        await this.getTypeOfUserData(phone, user);
        // await this.getTypeOfUserData("0538037197", user);
      } else {
        this.setState({
          isSignedIn: !!user,
          currentUser: user,
          isLoading: false,
        });
      }
    });
  }

  // handleOnIdle(event) {

  //   if (this.state.userData) {

  //     firebase.auth().signOut();
  //     history.push('/');
  //   } else return

  // }

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <StylesProvider jss={jss}>
          <div className="App" dir={theme.direction}>
            {/* <IdleTimer
              ref={ref => { this.idleTimer = ref }}
              timeout={15 * 60 * 1000}

              onIdle={this.handleOnIdle}

              debounce={250}
            /> */}
            <CssBaseline>
              {this.state.isLoading === true ? (
                <div className="loading">
                  <CircularProgress
                    style={{ marginTop: "calc(50vh - 50px)" }}
                  />
                  <div>
                    <div
                      style={{
                        fontFamily: "arial",
                        fontSize: mobile ? 25 : 20,
                        padding: mobile ? 20 : 0,
                        color: "black",
                        marginTop: 20,
                        fontWeight: "bold",
                      }}
                    ></div>
                  </div>
                </div>
              ) : (
                <BrowserRouter forceRefresh={true}>
                  <Router history={history}>
                    {this.state.isSignedIn === false && (
                      <Redirect to={"/login"} />
                    )}
                    <Switch>
                      <Route path="/*">
                        {this.state.isSignedIn &&
                        this.state.userData !== null ? (
                          <Dashboard main={this} history={this.props.history} />
                        ) : (
                          <Login main={this} history={this.props.history} />
                        )}
                      </Route>
                      <Route path="/login">
                        <Login main={this} history={this.props.history} />
                      </Route>
                    </Switch>
                  </Router>
                </BrowserRouter>
              )}
            </CssBaseline>
          </div>
        </StylesProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
