import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import moment from "moment";
import { format } from "date-fns";

// Material
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider, withSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

// Components

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    fontFamily: "'Varela Round', sans-serif",
  },
  success: {
    backgroundColor: "#38DFA1",
    color: "white",
    textWeight: "bold",
  },
  dateInputContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 12,
    alignItems: "baseline",
  },
});

class Reports extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isLoading: false,
    open: false,
    errorActivityDateRange: false,
    errorActivityDate: false,
    maternaStart: null,
    maternaEnd: null,
    errorMaternaDateRange: false,
    maternaRangeLoading: false,
    activityStart: null,
    activityEnd: null,
    activityDate: null,
    errorSupplierDateRange: false,
    errorSupplierDate: false,
    supplierStart: null,
    supplierEnd: null,
    supplierDate: null,
    dateLoading: false,
    dateRangeLoading: false,
    activityDateRangeLoading: false,
    allUsersLoading: false,
    allActivitiesLoading: false,
    allSuppliersLoading: false,
    errorSupplierDateRange: false,
    errorSupplierDate: false,
    supplierDateLoading: false,
    supplierDateRangeLoading: false,
    errorActivityUserSignUpDateRange: false,
    activityUserSignupStart: null,
    activityUserSignupEnd: null,
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  getAllUsers = () => {
    this.setState({ allUsersLoading: true }, () => {});
    var fileTitle;
    var fileData;
    var headers;
    axios
      .get("https://jama-server.appspot.com/api/reports/users")
      .then((response) => {
        var users = response.data;
        var childCount = [];
        var fieldsCount = 8;

        users = users.map((user) => {
          var tempChildCount = [];
          var childCounter = 1;

          var tempChilds;

          try {
            tempChilds = user.childs.map((child) => {
              tempChildCount.push("ילד " + childCounter);
              childCounter++;
              if (
                !!!child.birthDate ||
                !child.birthDate ||
                child.birthDate === null ||
                child.birthDate === undefined
              )
                return "Invalid Date";
              return this.formatChildAge(child.birthDate);
            });
          } catch (error) {}

          if (tempChildCount.length > childCount.length) {
            childCount = tempChildCount;
          }

          var formattedUser = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phone: user.phone,
            id: user.id,
            childsNumber: 0,
            registeredAt:
              user.registeredAt && user.registeredAt._seconds
                ? this.formatDate(user.registeredAt._seconds)
                : "",
          };

          try {
            formattedUser.childsNumber = user.childs.length;
          } catch (error) {}

          try {
            formattedUser.location = user.location.address.replace(/,/g, "");
          } catch (error) {}

          for (let i = 0; i < tempChildCount.length; i++) {
            const element = tempChilds[i];
            formattedUser[tempChildCount[i]] = element;
          }
          return formattedUser;
        });

        headers = {
          0: "שם פרטי".replace(/,/g, ""), // remove commas to avoid errors
          1: "שם משפחה",
          2: "אימייל",
          3: "מספר טלפון נייד",
          4: "מספר זיהוי מערכת",
          5: "מיקום",
          6: "כמות ילדים",
          7: "תאריך הצטרפות",
        };
        for (let i = 0; i < childCount.length; i++) {
          const element = childCount[i];
          headers[fieldsCount] = element;
          fieldsCount++;
        }

        fileTitle = "דוח משתמשים כולל";
        fileData = users;

        this.exportCSVFile(headers, fileData, fileTitle);
        this.setState({ allUsersLoading: false }, () => {});
      })
      .catch((err) => {
        console.error(err);
        this.setState({ allUsersLoading: false }, () => {});
      });
  };

  getAllActivities = () => {
    this.setState({ allActivitiesLoading: true }, () => {});
    var fileTitle;
    var fileData;
    var headers;
    axios
      .get("https://jama-server.appspot.com/api/reports/activities")
      .then(async (response) => {
        var activities = response.data;

        activities = await Promise.all(
          activities.map(async (activity) => {
            var signedUsersNumber;
            var res = await axios.get(
              "https://jama-server.appspot.com/api/activities/" +
                activity.id +
                "/get-signups"
            );
            signedUsersNumber = res.data.length;
            return {
              publisherName: activity.publisher.name,
              title: activity.title,
              createdAt: this.formatDate(activity.createdAt._seconds),
              date: activity.withoutDate
                ? "בתיאום מראש"
                : this.formatDate(activity.date._seconds),
              location: activity.location.replace(/,/g, ""),
              id: activity.id,
              signedUsersNumber: signedUsersNumber,
            };
          })
        );
        headers = {
          publisherName: "שם המפעילה".replace(/,/g, ""), // remove commas to avoid errors
          title: "שם הפעילות",
          createdAt: "תאריך הקמה ראשוני",
          date: "תאריך הפעילות",
          location: "מיקום",
          id: "מספר זיהוי מערכת",
          signedUsersNumber: "כמות נרשמות",
        };
        fileTitle = "דוח פעילויות כולל";
        fileData = activities;
        this.exportCSVFile(headers, fileData, fileTitle);
        this.setState({ allActivitiesLoading: false }, () => {});
      })
      .catch((err) => {
        console.error(err);
        this.setState({ allActivitiesLoading: false }, () => {});
      });
  };

  handleDownloadCSV = (value) => {
    if (value === "all-users") {
      this.getAllUsers();
    }
    if (value === "all-activities") {
      this.getAllActivities();
    }
    if (value === "date-activities") {
      this.getActivitiesByDate();
    }
    if (value === "range-date-activities") {
      this.getActivitiesByDateRange();
    }
    if (value === "range-date-maternaUserSignUp") {
      this.getMaternaUsersByDateRange();
    }
    if (value === "date-suppliers") {
      this.getSuppliersByDate();
    }
    if (value === "range-date-suppliers") {
      this.getSuppliersByDateRange();
    }
    if (value === "all-suppliers") {
      this.getAllSuppliers();
    }
    if (value === "range-date-activitiesUserSignUp") {
      this.getActivitiesUserSignUpByDateRange();
    }
  };

  getActivitiesUserSignUpByDateRange = async () => {
    var fileTitle;
    var fileData;
    var headers;
    if (
      this.state.activityUserSignupStart === null ||
      !!!this.state.activityUserSignupStart ||
      this.state.activityUserSignupEnd === null ||
      !!!this.state.activityUserSignupEnd
    )
      return this.setState({
        errorActivityUserSignUpDateRange: true,
        isLoading: false,
      });
    this.setState({ activityDateRangeLoading: true }, () => {});

    const start = moment().format(
      this.state.activityUserSignupStart + " 00:00:01"
    );
    const end = moment().format(this.state.activityUserSignupEnd + " 23:59:59");

    // // const response = await axios.post('http://localhost:8080/api/reports/date-range-activities', { date1: start, date2: end }, { headers: { 'Content-Type': 'application/json' } });
    const response = await axios.post(
      "https://jama-server.appspot.com/api/reports/getActivityUsersSignup",
      { date1: start, date2: end },
      { headers: { "Content-Type": "application/json" } }
    );
    let usersActivitySignup = response.data;

    usersActivitySignup = await Promise.all(
      usersActivitySignup.map(async (activity) => {
        var res = await axios.post(
          "https://jama-server.appspot.com/api/activities/" +
            activity.activityId
        );
        let signedInAt = new Date(
          activity.signedInAt._seconds * 1000
        ).toLocaleDateString();
        let activityDate = new Date(
          res.data.date._seconds * 1000
        ).toLocaleDateString();
        return {
          activityId: activity.activityId,
          publisherName: res.data.publisher.name,
          title: res.data.title,
          signedInAt: signedInAt,
          activityDate: activityDate,
          userName: activity.userData.fullname
            ? activity.userData.fullname
            : activity.userData.firstName + " " + activity.userData.lastName,
          userPhone: activity.userData.phone,
          userEmail: activity.userData.email,
          item: activity.selectedProduct.item,
          price: activity.selectedProduct.price,
          status: activity.status,
        };
      })
    );

    headers = {
      activityId: "מספר זיהוי מערכת",
      publisherName: "שם המפעילה".replace(/,/g, ""), // remove commas to avoid errors
      title: "שם הפעילות",
      signedInAt: "תאריך שנרשמה",
      activityDate: "תאריך הפעילות",
      userName: "שם הנרשמת",
      userPhone: "פלאפון",
      userEmail: "איימל",
      item: "מוצר נבחר",
      price: "עלות המוצר",
      status: "סטאטוס",
    };

    var formattedStart = this.state.activityUserSignupStart.split("-");
    formattedStart =
      formattedStart[2] + "-" + formattedStart[1] + "-" + formattedStart[0];
    var formattedEnd = this.state.activityUserSignupEnd.split("-");
    formattedEnd =
      formattedEnd[2] + "-" + formattedEnd[1] + "-" + formattedEnd[0];
    fileTitle =
      "דוח נרשמות לפיעלויות לתאריכים" +
      " " +
      formattedStart +
      " " +
      formattedEnd;
    fileData = usersActivitySignup;
    this.exportCSVFile(headers, fileData, fileTitle);
    this.setState({ activityDateRangeLoading: false }, () => {});
  };

  getActivitiesByDateRange = async () => {
    var fileTitle;
    var fileData;
    var headers;
    if (
      this.state.activityStart === null ||
      !!!this.state.activityStart ||
      this.state.activityEnd === null ||
      !!!this.state.activityEnd
    )
      return this.setState({ errorActivityDateRange: true, isLoading: false });
    this.setState({ dateRangeLoading: true }, () => {});

    const start = moment().format(this.state.activityStart + " 00:00:01");
    const end = moment().format(this.state.activityEnd + " 23:59:59");

    // const response = await axios.post('http://localhost:8080/api/reports/date-range-activities', { date1: start, date2: end }, { headers: { 'Content-Type': 'application/json' } });
    const response = await axios.post(
      "https://jama-server.appspot.com/api/reports/date-range-activities",
      { date1: start, date2: end },
      { headers: { "Content-Type": "application/json" } }
    );
    let activities = response.data;

    activities = await Promise.all(
      activities.map(async (activity) => {
        var signedUsersNumber;
        var res = await axios.get(
          "https://jama-server.appspot.com/api/activities/" +
            activity.id +
            "/get-signups"
        );
        signedUsersNumber = res.data.length;
        return {
          publisherName: activity.publisher.name,
          title: activity.title,
          createdAt: this.formatDate(activity.createdAt._seconds),
          date: activity.withoutDate
            ? "בתיאום מראש"
            : this.formatDate(activity.date._seconds),
          location: activity.location.replace(/,/g, ""),
          signedUsersNumber: signedUsersNumber,
          price:
            activity.price === null || !activity.price
              ? activity.items[0].price
              : activity.price,
          activityURL: `https://app.jama.co.il/activity/${activity.id}`,
        };
      })
    );

    headers = {
      publisherName: "שם המפעילה".replace(/,/g, ""), // remove commas to avoid errors
      title: "שם הפעילות",
      createdAt: "תאריך הקמה ראשוני",
      date: "תאריך הפעילות",
      location: "מיקום",
      signedUsersNumber: "כמות נרשמות",
      price: "עלות הפעילות",
      activityURL: "קישור לפעילות",
    };

    var formattedStart = this.state.activityStart.split("-");
    formattedStart =
      formattedStart[2] + "-" + formattedStart[1] + "-" + formattedStart[0];
    var formattedEnd = this.state.activityEnd.split("-");
    formattedEnd =
      formattedEnd[2] + "-" + formattedEnd[1] + "-" + formattedEnd[0];
    fileTitle =
      "דוח פעילויות לתאריכים" + " " + formattedStart + " " + formattedEnd;
    fileData = activities;
    this.exportCSVFile(headers, fileData, fileTitle);
    this.setState({ dateRangeLoading: false }, () => {});
  };

  getMaternaUsersByDateRange = async () => {
    const { id } = this.props.userData;
    var fileTitle;
    var fileData;
    var headers;
    const newInterestsArr = [
      "relPregnancyBirth",
      "relNursing",
      "relBabyDev",
      "relFitNutrition",
      "relFunWithBaby",
      "relInfantNutrition",
      "relMeetNewFriends",
      "relTipsTools",
    ];
    if (
      this.state.maternaStart === null ||
      !!!this.state.maternaStart ||
      this.state.maternaEnd === null ||
      !!!this.state.maternaEnd
    )
      return this.setState({ errorMaternaDateRange: true, isLoading: false });
    this.setState({ maternaRangeLoading: true }, () => {});

    const start = moment().format(this.state.maternaStart + " 00:00:01");
    const end = moment().format(this.state.maternaEnd + " 23:59:59");
    axios
      .post(
        "https://jama-server.appspot.com/api/reports/date-range-materna",
        // 'http://localhost:8081/api/reports/date-range-materna',
        { date1: start, date2: end, userId: id },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        var users = response.data;
        var childCount = [];
        var interestCount = [];
        var fieldsCount = 9;

        users = users.map((user) => {
          var tempChildCount = [];
          var tempInterestCount = [];

          var childCounter = 1;
          // var interestsCounter = 1;

          var tempChilds;
          // var tempInterests;

          var formattedUser = {
            firstName: user.firstName ? user.firstName : "Not Available",
            lastName: user.lastName ? user.lastName : "Not Available",
            email: user.email ? user.email : "Not Available",
            phone: user.phone ? user.phone.toString() : "Not Available",
            registeredAt:
              user.registeredAt && user.registeredAt._seconds
                ? format(
                    new Date(user.registeredAt._seconds * 1000),
                    "MM/dd/yyyy"
                  )
                : "",
          };

          try {
            formattedUser.location = user.location.address.replace(/,/g, "");
          } catch (error) {}

          try {
            tempChilds = user.childs.map((child) => {
              tempChildCount.push(
                "(Date of Birth for Child) ילד " + childCounter
              );
              childCounter++;
              if (
                !!!child.birthDate ||
                !child.birthDate ||
                child.birthDate === null ||
                child.birthDate === undefined
              ) {
                return "Invalid Date";
              } else {
                return this.formatChildAge(child.birthDate);
              }
            });
          } catch (error) {}

          formattedUser.childsNumber = childCounter;

          // try {
          //   tempInterests = user.interests.map((interest) => {
          //     tempInterestCount.push("תחום עניין " + interestsCounter);
          //     interestsCounter++;
          //     return interest.name;
          //   });
          // } catch (error) {
          //   console.log(error);
          // }

          try {
            const userInterestArr = [];
            if (user.relBabyDev) userInterestArr.push("Baby Development");
            if (user.relFitNutrition)
              userInterestArr.push("Fitness and Nutrition");
            if (user.relFunWithBaby) userInterestArr.push("Baby Fun");
            if (user.relInfantNutrition)
              userInterestArr.push("Infant Nutrition");
            if (user.relMeetNewFriends)
              userInterestArr.push("Meeting New Friends");
            if (user.relNursing) userInterestArr.push("Nursing");
            if (user.relPregnancyBirth)
              userInterestArr.push("Pregnancy and Birth");
            if (user.relTipsTools)
              userInterestArr.push("Tips and Tools for Parents");
            formattedUser.newInterests = userInterestArr.join(" - ");
            // if(userInterestArr.length > 0) {
            //   console.log(userInterestArr)
            // }
          } catch (error) {
            console.log(error);
          }

          try {
            const userInterestArr = [];
            if (user.relBabyDev) userInterestArr.push("התפתחות הבייבי");
            if (user.relFitNutrition) userInterestArr.push("כושר ותזונה לאמא");
            if (user.relFunWithBaby) userInterestArr.push("כיף עם הבייבי");
            if (user.relInfantNutrition) userInterestArr.push("תזונת תינוקות");
            if (user.relMeetNewFriends)
              userInterestArr.push("להכיר חברות חדשות");
            if (user.relNursing) userInterestArr.push("הנקה");
            if (user.relPregnancyBirth)
              userInterestArr.push("הריון ולקראת לידה");
            if (user.relTipsTools) userInterestArr.push("טיפים וכלים להורים");
            formattedUser.newInterestsHE = userInterestArr.join(" - ");
            // if(userInterestArr.length > 0) {
            //   console.log(userInterestArr)
            // }
          } catch (error) {
            console.log(error);
          }

          if (tempChildCount.length > childCount.length) {
            childCount = tempChildCount;
          }

          // if (tempInterestCount.length > interestCount.length) {
          //   interestCount = tempInterestCount;
          // }

          try {
            formattedUser.childsNumber = user.childs.length;
          } catch (error) {}

          if (tempChilds) {
            for (let i = 0; i < tempChildCount.length; i++) {
              const element = tempChilds[i];
              formattedUser[tempChildCount[i]] = element;
            }
          }

          // for (let i = 0; i < tempInterestCount.length; i++) {
          //   const element = tempInterests[i];
          //   formattedUser[tempChildCount + tempInterestCount[i]] = element;
          // }

          return formattedUser;
        });

        headers = {
          0: "שם פרטי(First Name)",
          1: "שם משפחה(Last Name)",
          2: "אימייל(Email)",
          3: "מספר טלפון נייד(Telephone)",
          4: "תאריך הצטרפות(Date of Registration)",
          5: "מיקום(Address)",
          6: "כמות ילדים(Number of Children)",
          7: "Interests",
          8: "תחומי עניין",
        };

        for (let i = 0; i < childCount.length; i++) {
          const element = childCount[i];
          headers[fieldsCount] = element;
          fieldsCount++;
        }

        // for (let i = 0; i < interestCount.length; i++) {
        //   const element = interestCount[i];
        //   headers[fieldsCount] = element;
        //   fieldsCount++;
        // }

        fileTitle = "דוח משתמשות מטרנה";
        fileData = users;

        this.exportCSVFile(headers, fileData, fileTitle);
        this.setState({ allUsersLoading: false }, () => {});

        this.setState({ maternaRangeLoading: false }, () => {});
      });
  };

  getActivitiesByDate = async () => {
    var fileTitle;
    var fileData;
    var headers;
    if (this.state.activityDate === null || !!!this.state.activityDate)
      return this.setState({ errorActivityDate: true });
    this.setState({ dateLoading: true }, () => {});
    const dayNum = new Date(this.state.activityDate).getDay();
    // const response = await axios.post('http://localhost:8080/api/reports/date-activities', { date: this.state.activityDate }, { headers: { 'Content-Type': 'application/json' } });
    const response = await axios.post(
      "https://jama-server.appspot.com/api/reports/date-activities",
      { date: this.state.activityDate },
      { headers: { "Content-Type": "application/json" } }
    );
    let activities = response.data;
    activities = activities.filter((activity) => {
      var temp = new Date(activity.createdAt._seconds * 1000);
      return dayNum === temp.getDay();
    });

    activities = await Promise.all(
      activities.map(async (activity) => {
        var signedUsersNumber;
        var res = await axios.get(
          "https://jama-server.appspot.com/api/activities/" +
            activity.id +
            "/get-signups"
        );
        signedUsersNumber = res.data.length;
        return {
          publisherName: activity.publisher.name,
          title: activity.title,
          createdAt: this.formatDate(activity.createdAt._seconds),
          date: activity.withoutDate
            ? "בתיאום מראש"
            : this.formatDate(activity.date._seconds),
          location: activity.location.replace(/,/g, ""),
          id: activity.id,
          signedUsersNumber: signedUsersNumber,
        };
      })
    );

    headers = {
      publisherName: "שם המפעילה".replace(/,/g, ""), // remove commas to avoid errors
      title: "שם הפעילות",
      createdAt: "תאריך הקמה ראשוני",
      date: "תאריך הפעילות",
      location: "מיקום",
      id: "מספר זיהוי מערכת",
      signedUsersNumber: "כמות נרשמות",
    };
    var formattedDate = this.state.activityDate.split("-");
    formattedDate =
      formattedDate[2] + "-" + formattedDate[1] + "-" + formattedDate[0];
    fileTitle = "דוח פעילויות לתאריך" + " " + formattedDate;
    fileData = activities;
    this.exportCSVFile(headers, fileData, fileTitle);
    this.setState({ dateLoading: false }, () => {});
  };

  getSuppliersByDateRange = async () => {
    var fileTitle;
    var fileData;
    var headers;
    if (
      this.state.supplierStart === null ||
      !!!this.state.supplierStart ||
      this.state.supplierEnd === null ||
      !!!this.state.supplierEnd
    )
      return this.setState({ errorSupplierDateRange: true });
    this.setState({ supplierDateRangeLoading: true }, () => {});

    const start = moment().format(this.state.supplierStart + " 00:00:01");
    const end = moment().format(this.state.supplierEnd + " 23:59:59");

    const response = await axios.post(
      "https://jama-server.appspot.com/api/reports/date-range-suppliers",
      { date1: start, date2: end },
      { headers: { "Content-Type": "application/json" } }
    );
    // const response = await axios.post('http://localhost:8080/api/reports/date-range-suppliers', { date1: start, date2: end }, { headers: { 'Content-Type': 'application/json' } });
    let suppliers = response.data;

    suppliers = await Promise.all(
      suppliers.map(async (supplier) => {
        var data = { id: supplier.id };
        var supplierActivitiesNumber;
        var res = await axios.post(
          "https://jama-server.appspot.com/api/activities/get-by-supplier",
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        supplierActivitiesNumber = res.data.length;
        return {
          firstName: supplier.firstName,
          lastName: supplier.lastName,
          email: supplier.email,
          phone: supplier.phone,
          id: supplier.id,
          supplierActivitiesNumber: supplierActivitiesNumber,
          createdAt: this.formatDate(supplier.createdAt._seconds),
        };
      })
    );
    headers = {
      firstName: "שם פרטי".replace(/,/g, ""), // remove commas to avoid errors
      lastName: "שם משפחה",
      email: "אימייל",
      phone: "מספר טלפון נייד",
      id: "מספר זיהוי מערכת",
      supplierActivitiesNumber: "כמות פעילויות",
      createdAt: "תאריך הצטרפות",
    };
    var formattedStart = this.state.supplierStart.split("-");
    formattedStart =
      formattedStart[2] + "-" + formattedStart[1] + "-" + formattedStart[0];
    var formattedEnd = this.state.supplierEnd.split("-");
    formattedEnd =
      formattedEnd[2] + "-" + formattedEnd[1] + "-" + formattedEnd[0];
    fileTitle =
      "דוח ספקים לתאריכים" + " " + formattedStart + " " + formattedEnd;
    fileData = suppliers;
    this.exportCSVFile(headers, fileData, fileTitle);
    this.setState({ supplierDateRangeLoading: false }, () => {});
  };

  getSuppliersByDate = async () => {
    var fileTitle;
    var fileData;
    var headers;
    if (this.state.supplierDate === null || !!!this.state.supplierDate)
      return this.setState({ errorSupplierDate: true });
    this.setState({ supplierDateLoading: true }, () => {});
    const dayNum = new Date(this.state.supplierDate).getDay();
    // const response = await axios.post('http://localhost:8080/api/reports/date-suppliers', { date: this.state.supplierDate }, { headers: { 'Content-Type': 'application/json' } });
    const response = await axios.post(
      "https://jama-server.appspot.com/api/reports/date-suppliers",
      { date: this.state.supplierDate },
      { headers: { "Content-Type": "application/json" } }
    );
    let suppliers = response.data;
    suppliers = suppliers.filter((supplier) => {
      var temp = new Date(supplier.createdAt._seconds * 1000);
      return dayNum === temp.getDay();
    });
    suppliers = await Promise.all(
      suppliers.map(async (supplier) => {
        var data = { id: supplier.id };
        var supplierActivitiesNumber;
        var res = await axios.post(
          "https://jama-server.appspot.com/api/activities/get-by-supplier",
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        supplierActivitiesNumber = res.data.length;
        return {
          firstName: supplier.firstName,
          lastName: supplier.lastName,
          email: supplier.email,
          phone: supplier.phone,
          id: supplier.id,
          supplierActivitiesNumber: supplierActivitiesNumber,
          createdAt: this.formatDate(supplier.createdAt._seconds),
        };
      })
    );
    headers = {
      firstName: "שם פרטי".replace(/,/g, ""), // remove commas to avoid errors
      lastName: "שם משפחה",
      email: "אימייל",
      phone: "מספר טלפון נייד",
      id: "מספר זיהוי מערכת",
      supplierActivitiesNumber: "כמות פעילויות",
      createdAt: "תאריך הצטרפות",
    };
    var formattedDate = this.state.supplierDate.split("-");
    formattedDate =
      formattedDate[2] + "-" + formattedDate[1] + "-" + formattedDate[0];
    fileTitle = "דוח ספקים לתאריך" + " " + formattedDate;
    fileData = suppliers;

    this.exportCSVFile(headers, fileData, fileTitle);
    this.setState({ supplierDateLoading: false }, () => {});
  };

  getAllSuppliers = () => {
    this.setState({ allSuppliersLoading: true }, () => {});

    var headers, fileData, fileTitle;
    axios
      .get("https://jama-server.appspot.com/api/reports/suppliers")
      .then(async (response) => {
        var suppliers = response.data;

        suppliers = await Promise.all(
          suppliers.map(async (supplier) => {
            var data = { id: supplier.id };
            var supplierActivitiesNumber;
            var res = await axios.post(
              "https://jama-server.appspot.com/api/activities/get-by-supplier",
              data,
              {
                headers: { "Content-Type": "application/json" },
              }
            );
            supplierActivitiesNumber = res.data.length;
            return {
              firstName: supplier.firstName,
              lastName: supplier.lastName,
              email: supplier.email,
              phone: supplier.phone,
              id: supplier.id,
              supplierActivitiesNumber: supplierActivitiesNumber,
              createdAt: this.formatDate(supplier.createdAt._seconds),
            };
          })
        );

        headers = {
          firstName: "שם פרטי".replace(/,/g, ""), // remove commas to avoid errors
          lastName: "שם משפחה",
          email: "אימייל",
          phone: "מספר טלפון נייד",
          id: "מספר זיהוי מערכת",
          supplierActivitiesNumber: "כמות פעילויות",
          createdAt: "תאריך הצטרפות",
        };
        fileTitle = "דוח ספקים כולל";
        fileData = suppliers;
        this.exportCSVFile(headers, fileData, fileTitle);
        this.setState({ allSuppliersLoading: false }, () => {});
      })
      .catch((err) => {
        console.error(err);
        this.setState({ allSuppliersLoading: false }, () => {});
      });
  };

  formatDate = (timestamp) => {
    var a = new Date(timestamp * 1000);
    let hebDay;
    var year = a.getFullYear() % 100;
    var month = a.getMonth();
    var dayNum = a.getDate();
    var day = a.getDay();
    let date = `${dayNum}.${month + 1}.${year}`;
    switch (day) {
      case 0:
        hebDay = "א'";
        break;
      case 1:
        hebDay = "ב'";
        break;
      case 2:
        hebDay = "ג'";
        break;
      case 3:
        hebDay = "ד'";
        break;
      case 4:
        hebDay = "ה'";
        break;
      case 5:
        hebDay = "ו'";
        break;
      case 6:
        hebDay = "שבת";
        break;
      default:
    }

    let formatedDate = `יום ${hebDay}` + " " + date;

    var hour = a.getHours();
    var min = a.getMinutes();
    let tempMin = min.toString();
    if (tempMin.length === 1) {
      min = "0" + min;
    }
    var time = hour + ":" + min;
    return formatedDate;
    return formatedDate + " | " + time;
  };

  convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        var temp = array[i][index];
        if (line != "") line += ",";
        if (typeof array[i][index] === "object") {
          temp = JSON.stringify(temp);
        }

        line += temp;
        // line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  };

  exportCSVFile = (headers, items, fileTitle) => {
    if (headers) {
      items.unshift(headers);
    }

    //  Convert Object to JSON
    var jsonObject = JSON.stringify(items);

    var csv = this.convertToCSV(jsonObject);

    var universalBOM = "\uFEFF";

    csv = universalBOM + csv;

    var exportedFilenmae = fileTitle + ".csv" || "export.csv";

    var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    this.setState({ open: true }, () => {});
    // this.setState({ isLoading: false, open: true })
  };

  formatChildAge = (age) => {
    let newAge;
    let today = new Date();
    if (age._seconds) newAge = age._seconds * 1000;
    // if (age.seconds) newAge = age.seconds * 1000;
    // else newAge = age;
    // let res = this.calcDate(today, new Date(newAge));
    let res = format(new Date(newAge), "MM/dd/yyyy");

    if (today.getTime() < new Date(newAge).getTime()) {
      res.weeks = Math.abs(res.weeks);
      //preg
      return `${res.weeks} שבועות`;
    }
    return res;
  };

  monthFormat = (age) => {
    let years = age.years;
    let months = age.months - years * 12;
    let formatedYears, formatedMonths;
    switch (years) {
      case 0:
        formatedYears = "";
        break;
      case 1:
        formatedYears = "שנה";
        break;
      case 2:
        formatedYears = "שנתיים";
        break;
      case 3:
        formatedYears = "3 שנים";
        break;
      case 4:
        formatedYears = "4 שנים";
        break;
      case 5:
        formatedYears = "5 שנים";
        break;
      case 6:
        formatedYears = "6 שנים";
        break;
      case 7:
        formatedYears = "7 שנים";
        break;
      case 8:
        formatedYears = "8 שנים";
        break;
      case 9:
        formatedYears = "9 שנים";
        break;
      case 10:
        formatedYears = "10 שנים";
        break;
      case 11:
        formatedYears = "11 שנים";
        break;
      case 12:
        formatedYears = "12 שנים";
        break;
      default:
    }
    switch (months) {
      case 0:
        formatedMonths = "";
        break;
      case 1:
        formatedMonths = "חודש";
        break;
      case 2:
        formatedMonths = "חודשיים";
        break;
      case 3:
        formatedMonths = "3 חודשים";
        break;
      case 4:
        formatedMonths = "4 חודשים";
        break;
      case 5:
        formatedMonths = "5 חודשים";
        break;
      case 6:
        formatedMonths = "6 חודשים";
        break;
      case 7:
        formatedMonths = "7 חודשים";
        break;
      case 8:
        formatedMonths = "8 חודשים";
        break;
      case 9:
        formatedMonths = "9 חודשים";
        break;
      case 10:
        formatedMonths = "10 חודשים";
        break;
      case 11:
        formatedMonths = "11 חודשים";
        break;
      default:
    }
    if (years > 0) {
      if (months > 0) {
        return formatedYears + " ו" + formatedMonths;
      }
      return formatedYears;
    }
    return formatedMonths;
  };

  calcDate = (date1, date2) => {
    var diff = Math.floor(date1.getTime() - date2.getTime());
    var day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    var weeks = Math.floor(days / 7);
    var months = Math.floor(days / 31);
    var years = Math.floor(months / 12);

    return {
      years,
      months,
      weeks,
      days,
    };
  };

  handleDateChange = (e, name) => {
    if (name === "activity-date") {
      this.setState({ activityDate: e.target.value, errorActivityDate: false });
    }
    if (name === "activity-range-start") {
      this.setState({
        activityStart: e.target.value,
        errorActivityDateRange: false,
      });
    }
    if (name === "activity-range-end") {
      this.setState({
        activityEnd: e.target.value,
        errorActivityDateRange: false,
      });
    }
    if (name === "maternaStart-range-start") {
      this.setState({
        maternaStart: e.target.value,
        errorMaternaDateRange: false,
      });
    }
    if (name === "maternaStart-range-end") {
      this.setState({
        maternaEnd: e.target.value,
        errorMaternaDateRange: false,
      });
    }
    if (name === "supplier-date") {
      this.setState({ supplierDate: e.target.value, errorSupplierDate: false });
    }
    if (name === "supplier-range-start") {
      this.setState({
        supplierStart: e.target.value,
        errorSupplierDateRange: false,
      });
    }
    if (name === "supplier-range-end") {
      this.setState({
        supplierEnd: e.target.value,
        errorSupplierDateRange: false,
      });
    }
    if (name === "activity-UserSignup-range-start") {
      this.setState({
        activityUserSignupStart: e.target.value,
        errorActivityUserSignUpDateRange: false,
      });
    }
    if (name === "activity-UserSignup-range-end") {
      this.setState({
        activityUserSignupEnd: e.target.value,
        errorActivityUserSignUpDateRange: false,
      });
    }
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        {this.state.isLoading == true ? (
          <div className="loading">
            <CircularProgress style={{ marginTop: "calc(50vh - 50px)" }} />
            <div>
              <div
                style={{
                  fontFamily: "arial",
                  fontSize: mobile ? 25 : 20,
                  padding: mobile ? 20 : 0,
                  color: "black",
                  marginTop: 20,
                  fontWeight: "bold",
                }}
              ></div>
            </div>
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 26 }}
            >
              <div>הפקת דוחות כלליים:</div>
              <Grid container direction="row" style={{ marginTop: 12 }}>
                <Grid
                  item
                  sm={12}
                  md={4}
                  lg={4}
                  xl={3}
                  style={{ width: "100%" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => this.handleDownloadCSV("all-suppliers")}
                  >
                    {this.state.allSuppliersLoading ? (
                      <div>
                        <CircularProgress
                          style={{ marginTop: 10, width: 20, height: 20 }}
                        />
                      </div>
                    ) : (
                      <div>דוח כלל הספקים</div>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  lg={4}
                  xl={3}
                  style={{ width: "100%" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => this.handleDownloadCSV("all-users")}
                  >
                    {this.state.allUsersLoading ? (
                      <div>
                        <CircularProgress
                          style={{ marginTop: 10, width: 20, height: 20 }}
                        />
                      </div>
                    ) : (
                      <div>דוח כלל המשתמשים</div>
                    )}
                  </Button>
                </Grid>
                <Grid
                  item
                  sm={12}
                  md={4}
                  lg={4}
                  xl={3}
                  style={{ width: "100%" }}
                >
                  <Button
                    variant="outlined"
                    onClick={() => this.handleDownloadCSV("all-activities")}
                  >
                    {this.state.allActivitiesLoading ? (
                      <div>
                        <CircularProgress
                          style={{ marginTop: 10, width: 20, height: 20 }}
                        />
                      </div>
                    ) : (
                      <div>דוח כלל הפעילויות</div>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח פעילויות לפי תאריך הקמה:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="activity-date">תאריך:</label>
                  <input
                    id="activity-date"
                    type="date"
                    onChange={(e) => this.handleDateChange(e, "activity-date")}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() => this.handleDownloadCSV("date-activities")}
                >
                  {this.state.dateLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח פעילויות לפי תאריך הקמה</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorActivityDate && (
                <div>אנא הזינו תאריך על מנת להפיק את הדוח</div>
              )}
            </Grid>
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח נרשמות לפעילויות לפי תאריכים:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="start">מתאריך:</label>
                  <input
                    id="start"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(
                        e,
                        "activity-UserSignup-range-start"
                      )
                    }
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <label htmlFor="end">עד תאריך:</label>
                  <input
                    id="end"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "activity-UserSignup-range-end")
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    this.handleDownloadCSV("range-date-activitiesUserSignUp")
                  }
                >
                  {this.state.activityDateRangeLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח נרשמות לפעילויות לפי טווח תאריכים</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorActivityUserSignUpDateRange && (
                <div>אנא הזינו תאריכים על מנת להפיק את הדוח</div>
              )}
            </Grid>
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח נרשמות דרך מטרנה:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="start">מתאריך:</label>
                  <input
                    id="start"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "maternaStart-range-start")
                    }
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <label htmlFor="end">עד תאריך:</label>
                  <input
                    id="end"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "maternaStart-range-end")
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    this.handleDownloadCSV("range-date-maternaUserSignUp")
                  }
                >
                  {this.state.maternaRangeLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח נרשמות דרך מטרנה</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorMaternaDateRange && (
                <div>אנא הזינו תאריכים על מנת להפיק את הדוח</div>
              )}
            </Grid>
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח פעילויות לפי טווח תאריכים:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="start">מתאריך:</label>
                  <input
                    id="start"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "activity-range-start")
                    }
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <label htmlFor="end">עד תאריך:</label>
                  <input
                    id="end"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "activity-range-end")
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    this.handleDownloadCSV("range-date-activities")
                  }
                >
                  {this.state.dateRangeLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח פעילויות לפי טווח תאריכים</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorActivityDateRange && (
                <div>אנא הזינו תאריכים על מנת להפיק את הדוח</div>
              )}
            </Grid>

            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח ספקים לפי תאריך הצטרפות:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="supplier-date">תאריך:</label>
                  <input
                    id="supplier-date"
                    type="date"
                    onChange={(e) => this.handleDateChange(e, "supplier-date")}
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() => this.handleDownloadCSV("date-suppliers")}
                >
                  {this.state.supplierDateLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח ספקים לפי תאריך הצטרפות</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorSupplierDate && (
                <div>אנא הזינו תאריך על מנת להפיק את הדוח</div>
              )}
            </Grid>
            <Grid
              container
              spacing={mobile ? 2 : 4}
              direction="column"
              alignItems="baseline"
              style={{ marginTop: 46 }}
            >
              <div>הפקת דוח ספקים לפי טווח תאריכים:</div>
              <Grid
                item
                sm={12}
                md={4}
                lg={4}
                xl={3}
                style={{ width: "100%", display: "flex" }}
              >
                <div className={classes.dateInputContainer}>
                  <label htmlFor="start">מתאריך:</label>
                  <input
                    id="start"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "supplier-range-start")
                    }
                  />
                </div>
                <div className={classes.dateInputContainer}>
                  <label htmlFor="end">עד תאריך:</label>
                  <input
                    id="end"
                    type="date"
                    onChange={(e) =>
                      this.handleDateChange(e, "supplier-range-end")
                    }
                  />
                </div>
              </Grid>
              <Grid item sm={12} md={4} lg={4} xl={3} style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  onClick={() => this.handleDownloadCSV("range-date-suppliers")}
                >
                  {this.state.supplierDateRangeLoading ? (
                    <div>
                      <CircularProgress
                        style={{ marginTop: 10, width: 20, height: 20 }}
                      />
                    </div>
                  ) : (
                    <div>דוח ספקים לפי טווח תאריכים</div>
                  )}
                </Button>
              </Grid>
              {this.state.errorSupplierDateRange && (
                <div>אנא הזינו תאריכים על מנת להפיק את הדוח</div>
              )}
            </Grid>
            <Snackbar
              open={this.state.open}
              autoHideDuration={3000}
              onClose={this.handleClose}
            >
              <Alert onClose={this.handleClose} severity="success">
                דוח הופק בהצלחה
              </Alert>
            </Snackbar>
          </SnackbarProvider>
        )}
      </div>
    );
  }
}

Reports.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Reports));
