import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import { TimePicker, DatePicker } from 'antd';
import moment from 'moment';
import '../../../../node_modules/antd/dist/antd.css';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: '100%',
    textAlign: 'left',
    float: 'left',
    color: 'rgba(73, 155, 234, 1)',
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: 'bold',
    marginTop: 20,
    direction: 'ltr',
    marginBottom: 10
  },
}));

function createData(name, phone, email, signinAt, price, status, id) {
  return { name, phone, email, signinAt, status, price, id };
}

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [registrations, setRegistrations] = React.useState([]);
  const [date, setDate] = React.useState(0);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [time, setTime] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    // Update the document title using the browser API
  }, []);

  const handleClose = () => {
    props.setOpenCopy(false)
  };

  const copy = () => {
    var datetime = null
    if (date !== 0) {
      setLoading(true)
      var data = props.activityData
      // var datetime = date.replace(':0 ', ':00 ') + ':00.000+0300'
      datetime = date.replace(':0 ', ':00 ')
      data.date = datetime
      data.status = 'approved'
      delete data.id
      console.log(data)

      axios.post('https://jama-server.appspot.com/api/activities/duplicate', data, {
        headers: {
          'content-type': 'application/json' // do not forget this 
        }
      }).then((response) => {
        setLoading(false)
        props.getActivities()
        props.setOpenCopy(false)
        console.log(response.data)
      })
        .catch((error) => {
          console.log(error);
        });
    } else {

    }
  }

  const onchangeDateTime = (e) => {
    setDate(String(e._d))
    setFormattedDate(moment(e._d))
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf('day');
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth={'sm'}
        maxWidth={'sm'}
        open={props.openCopy}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'right' }}>שיכפול פעילות</DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>יום ושעה</div>
          {/* <input type="datetime-local" className="textinput" onChange={(e) => setDate(e.target.value) }/> */}
          <DatePicker
            renderExtraFooter={() => <div style={{ height: 0 }}><div style={{ backgroundColor: 'white', width: '50%', position: 'relative', top: 10, height: 50 }}></div></div>}
            minuteStep={15}
            // bordered={false}
            format={'DD/MM/YYYY HH:mm'}
            hideDisabledOptions={false}
            placeholder="יש לבחור תאריך ושעה"
            onChange={onchangeDateTime}
            clearIcon={(<div></div>)}
            disabledDate={disabledDate}
            popupStyle={{ zIndex: 99999, paddingTop: 300 }}
            showToday={false}
            showNow={false}
            value={formattedDate}
            id='date-and-time'
            disabledHours={() => [0, 1, 2, 3, 4, 5]}
            // style={{backgroundColor: '#ecf1fe', outline: 'none', fontSize: 20, width: '100%', paddingTop: 7, borderRadius: 28, height: 56, paddingLeft: 20, paddingRight: 20, border: '1px solid #DAE2FD',
            // "antd-input:hover:focus:select": {
            //   border: '1px solid #DAE2FD',
            //   outline: 'none',
            // },}}
            className="textinput"
            style={{ borderRadius: 25, border: '1px solid #438BEC' }}
            showTime />
          <center>
            <Button data-trans="signin-done-button" className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => copy()}>
              {loading ? <CircularProgress size={25} /> : 'פרסום פעילות'}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: '100%', textAlign: 'left' }}>
          <Button onClick={handleClose} style={{ float: 'left' }} color="primary">
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
