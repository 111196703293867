import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import MuiStylesFunction from "./EditArticle.styles";
import { names, ageRangeArray } from "./EditArticle.logic";
import { Editor } from "react-draft-wysiwyg";
import DeleteIcon from "@material-ui/icons/Delete";
import draftToHtml from "draftjs-to-html";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";

const useStyles = makeStyles(MuiStylesFunction);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function FullScreenDialog(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [editorState, setEditorState] = React.useState(
    props.data.components[0].hasOwnProperty("html")
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(props.data.components[0].html).contentBlocks,
            convertFromHTML(props.data.components[0].html).entityMap
          )
        )
      : EditorState.createEmpty()
  );
  const [tags, setTags] = React.useState(
    props.data.hasOwnProperty("interests") ? props.data.interests : []
  );
  const [ageRange, setAgeRange] = React.useState(
    props.data.hasOwnProperty("ages") ? props.data.ages : []
  );
  const [openTags, setOpenTags] = React.useState(
    props.data.hasOwnProperty("openTags") ? props.data.openTags : []
  );
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorTags, setErrorTags] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [update, setUpdate] = React.useState(false);
  const [uploadedImages, setImages] = React.useState([]);
  const [state, setState] = React.useState({
    title: props.data.hasOwnProperty("title") ? props.data.title : "",
    description: props.data.hasOwnProperty("description")
      ? props.data.description
      : "",
    imageTitle: props.data.hasOwnProperty("titleForImage")
      ? props.data.titleForImage
      : "",
  });
  const [loading, setLoading] = React.useState(false);
  const [articleBody, setArticleBody] = React.useState([]);

  console.log("article", props.data);

  React.useEffect(() => {
    console.log("articlebody", articleBody);
  }, [articleBody]);

  const handleChangeImage = (e, id) => {
    const data = articleBody;
    const files = e.target.files;
    data[id]["file"] = files[0];
    setArticleBody(data);
    setUpdate(!update);

    console.log("updatedData");
    console.log(data);
  };

  const handleChangeVideoLink = (e, id) => {
    const data = articleBody;
    data[id]["link"] = e.target.value;
    setArticleBody(data);
    setUpdate(!update);
  };

  const handleChangeImageTitle = (e, id) => {
    const data = articleBody;
    data[id]["imageTitle"] = e.target.value;
    setArticleBody(data);
    setUpdate(!update);
  };

  const deleteElement = (id) => {
    const data = articleBody;
    data.splice(id, 1);
    setArticleBody(data);
    setUpdate(!update);
  };

  const onEditorStateChangeById = (editorState, id) => {
    const data = articleBody;
    data[id]["editorState"] = editorState;
    setArticleBody(data);
    setUpdate(!update);
  };

  const uploadImageCallBack = async (file) => {
    console.log("reading file", file);
    let uploadedImagess = uploadedImages;
    const imageObject = {
      file: file,
      imageNumber: "$img" + String(uploadedImages.length + 1),
      localSrc: URL.createObjectURL(file),
    };
    uploadedImagess.push(imageObject);
    setImages(uploadedImagess);
    return new Promise((resolve, reject) => {
      resolve({ data: { link: imageObject.localSrc } });
    });
  };

  const addElement = (type) => {
    const data = articleBody;
    const newElementData = { type: type };
    if (type === "text") {
      newElementData["editorState"] = EditorState.createEmpty();
    }
    if (type === "video") {
      newElementData["link"] = null;
    }
    if (type === "image") {
      newElementData["file"] = null;
      newElementData["imageTitle"] = null;
    }
    data.push(newElementData);
    setArticleBody(data);
    setUpdate(!update);
  };

  // const handleChange = (event) => {
  //   setTags(event.target.value);
  // };

  // const handleChangeAgeRange = (event) => {
  //   setAgeRange(event.target.value);
  // };

  const edit = async () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorDescription(false);
    setErrorTags(false);
    setErrorAgeRange(false);

    var html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }

    if (Object.keys(interest).every((k) => !interest[k])) {
      setErrorTags(true);
      allowToContinue = false;
    }

    if (Object.keys(agesNew).every((k) => !agesNew[k])) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }

    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }

    if (allowToContinue) {
      setLoading(true);
      let data = {
        title: state.title,
        description: state.description,
        // ages: ageRange,
        // interests: tags,

        relNursing,
        relFitNutrition,
        relMeetNewFriends,
        relTipsTools,
        relFunWithBaby,
        relBabyDev,
        relInfantNutrition,
        relPregnancyBirth,

        AFForEveryone,
        AFForMomBaby,
        AFForMoms,
        AFNineToTwelve,
        AFPregnancy,
        AFSixToNine,
        AFThreeToSix,
        AFTwelveToTwentyFour,
        AFTwentyFourPlus,
        AFZeroToSix,
        AFZeroToThree,
        AFZeroToTwelve,
      };
      console.log(props.data);
      if (articleBody.length > 0) {
        var newFilesArray = [coverImage];
        const components = articleBody;
        var imagesCounter = 1;
        for (let index = 0; index < components.length; index++) {
          const element = components[index];
          const newElement = {};
          if (element.type === "image") {
            newFilesArray.push(element.file);
            newElement["type"] = "image";
            newElement["imageTitle"] = element.imageTitle;
            newElement["image"] = "$img" + String(imagesCounter);
            imagesCounter += 1;
          } else if (element.type === "text") {
            newElement["type"] = "text";
            newElement["html"] = draftToHtml(
              convertToRaw(element.editorState.getCurrentContent())
            );
          } else if (element.type === "video") {
            newElement["type"] = "video";
            newElement["link"] = element.link;
          }
          components[index] = newElement;
        }
        data["components"] = components;
      }

      if (coverImage) {
        var form = new FormData();
        form.append("photos", coverImage, coverImage.name);
        console.log(form);

        axios
          .post(
            "https://jama-server.appspot.com/api/articles/" +
              props.data.id +
              "/uploadPhoto",
            form,
            {
              headers: {
                "content-type": "multipart/form-data", // do not forget this
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      axios
        .post(
          "https://jama-server.appspot.com/api/articles/" +
            props.data.id +
            "/update",
          data,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          setLoading(false);
          props.getArticles();
          props.main.setState({ openEditArticle: false });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const [interest, setInterest] = React.useState({
    relNursing: false,
    relFitNutrition: false,
    relMeetNewFriends: false,
    relTipsTools: false,
    relFunWithBaby: false,
    relBabyDev: false,
    relInfantNutrition: false,
    relPregnancyBirth: false,
  });

  const {
    relNursing,
    relFitNutrition,
    relMeetNewFriends,
    relTipsTools,
    relFunWithBaby,
    relBabyDev,
    relInfantNutrition,
    relPregnancyBirth,
  } = interest;

  const handleChangeCheckBox = (event) => {
    setInterest({
      ...interest,
      [event.target.name]: event.target.checked,
    });
  };

  ///

  const [agesNew, setAgesNew] = React.useState({
    AFForEveryone: false,
    AFForMomBaby: false,
    AFForMoms: false,
    AFNineToTwelve: false,
    AFPregnancy: false,
    AFSixToNine: false,
    AFThreeToSix: false,
    AFTwelveToTwentyFour: false,
    AFTwentyFourPlus: false,
    AFZeroToSix: false,
    AFZeroToThree: false,
    AFZeroToTwelve: false,
  });

  const {
    AFForEveryone,
    AFForMomBaby,
    AFForMoms,
    AFNineToTwelve,
    AFPregnancy,
    AFSixToNine,
    AFThreeToSix,
    AFTwelveToTwentyFour,
    AFTwentyFourPlus,
    AFZeroToSix,
    AFZeroToThree,
    AFZeroToTwelve,
  } = agesNew;

  const handleChangeCheckBoxAges = (event) => {
    setAgesNew({
      ...agesNew,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen
        open={props.main.state.openEditArticle}
        onClose={() => props.main.setState({ openEditArticle: false })}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => props.main.setState({ openEditArticle: false })}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h5" className={classes.pageTitle}>
              עידכון כתבה קיימת
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          <Grid container spacing={2} justify="center">
            <Grid item sm={12} md={10} lg={8} xl={8}>
              <div className={classes.inputTitle}>העלאת תמונה ראשית</div>
              <input
                className="textinput"
                type="file"
                id="single"
                onChange={onChange}
                style={{ paddingTop: 13, paddingRight: 20 }}
              />
              {errorImage && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>כותרת</div>
              <input
                className="textinput"
                value={state.title}
                onChange={(e) => setState({ ...state, title: e.target.value })}
              />
              {errorTitle && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>תקציר</div>
              <textarea
                className="textarea_box"
                value={state.description}
                onChange={(e) =>
                  setState({ ...state, description: e.target.value })
                }
                cols="40"
                rows="5"
              />
              {errorDescription && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}
              <div className={classes.inputTitle}>תחומי עניין</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relNursing}
                      onChange={handleChangeCheckBox}
                      name="relNursing"
                    />
                  }
                  label="הנקה"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFitNutrition}
                      onChange={handleChangeCheckBox}
                      name="relFitNutrition"
                    />
                  }
                  label="כושר ותזונה לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relMeetNewFriends}
                      onChange={handleChangeCheckBox}
                      name="relMeetNewFriends"
                    />
                  }
                  label="להכיר חברות חדשות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relTipsTools}
                      onChange={handleChangeCheckBox}
                      name="relTipsTools"
                    />
                  }
                  label="טיפים וכלים להורים"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relFunWithBaby}
                      onChange={handleChangeCheckBox}
                      name="relFunWithBaby"
                    />
                  }
                  label="כיף עם הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relBabyDev}
                      onChange={handleChangeCheckBox}
                      name="relBabyDev"
                    />
                  }
                  label="התפתחות הבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relInfantNutrition}
                      onChange={handleChangeCheckBox}
                      name="relInfantNutrition"
                    />
                  }
                  label="תזונת תינוקות"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={relPregnancyBirth}
                      onChange={handleChangeCheckBox}
                      name="relPregnancyBirth"
                    />
                  }
                  label="הריון ולקראת לידה"
                />
              </FormGroup>
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={tags}
                  onChange={handleChange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "ltr",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {names.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, tags, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorTags && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
              <div className={classes.inputTitle}>טווחי גילאים</div>
              <FormGroup
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 110,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                  marginTop: 50,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForEveryone}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForEveryone"
                    />
                  }
                  label="לכולם"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMomBaby}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMomBaby"
                    />
                  }
                  label="לאמא ולבייבי"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFForMoms}
                      onChange={handleChangeCheckBoxAges}
                      name="AFForMoms"
                    />
                  }
                  label="לאמא"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFPregnancy}
                      onChange={handleChangeCheckBoxAges}
                      name="AFPregnancy"
                    />
                  }
                  label="לאמא בהריון"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToThree}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToThree"
                    />
                  }
                  label="0-3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToSix"
                    />
                  }
                  label="0-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFZeroToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFZeroToTwelve"
                    />
                  }
                  label="0-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFThreeToSix}
                      onChange={handleChangeCheckBoxAges}
                      name="AFThreeToSix"
                    />
                  }
                  label="3-6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFSixToNine}
                      onChange={handleChangeCheckBoxAges}
                      name="AFSixToNine"
                    />
                  }
                  label="6-9"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFNineToTwelve}
                      onChange={handleChangeCheckBoxAges}
                      name="AFNineToTwelve"
                    />
                  }
                  label="9-12"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwelveToTwentyFour}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwelveToTwentyFour"
                    />
                  }
                  label="12-24"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={AFTwentyFourPlus}
                      onChange={handleChangeCheckBoxAges}
                      name="AFTwentyFourPlus"
                    />
                  }
                  label="24+"
                />
              </FormGroup>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )}{" "}
              {/* <FormControl
                className={classes.formControl}
                style={{
                  border: "solid 1px rgba(73, 155, 234, 1)",
                  borderRadius: 30,
                  height: 55,
                  paddingLeft: 20,
                  paddingRight: 8,
                  paddingTop: 12,
                  width: "100%",
                }}
              >
                <Select
                  labelId="demo-mutiple-chip-label"
                  disableUnderline
                  id="demo-mutiple-chip"
                  multiple
                  value={ageRange}
                  onChange={handleChangeAgeRange}
                  input={
                    <Input
                      id="select-multiple-chip"
                      style={{
                        direction: "ltr",
                        marginTop: -10,
                        minHeight: 50,
                      }}
                    />
                  }
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => (
                        <Chip
                          key={value}
                          label={value}
                          className={classes.chip}
                        />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {ageRangeArray.map((step) => (
                    <MenuItem
                      key={step.value}
                      value={step.value}
                      style={getStyles(step.value, ageRangeArray, theme)}
                    >
                      {step.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {errorAgeRange && (
                <div className={classes.errorMessage}>שדה חובה</div>
              )} */}
              <div className={classes.inputTitle} style={{ fontSize: 22 }}>
                תוכן הכתבה
              </div>
              {articleBody.map((articleComponent, i) => (
                <div
                  style={{
                    minHeight: 30,
                    width: "100%",
                    backgroundColor: "white",
                    borderRadius: 25,
                    marginTop: 30,
                    border: "0px solid #438BEC",
                  }}
                >
                  {articleComponent.type === "text" && (
                    <div>
                      <Editor
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        wrapperStyle={{
                          backgroundColor: "white",
                          padding: 50,
                          marginTop: 0,
                          width: "100%",
                          marginLeft: -50,
                        }}
                        editorStyle={{
                          padding: 0,
                          backgroundColor: "rgb(250,251,254)",
                          marginTop: 0,
                          border: "1px solid rgb(230,230,230)",
                        }}
                        // editorState={articleComponent.editorState}
                        // editorState={editorState}
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          fontFamily: {
                            options: [
                              "Varela Round, sans-serif",
                              "Arial",
                              "Georgia",
                              "Impact",
                              "Tahoma",
                              "Times New Roman",
                              "Verdana",
                            ],
                          },
                          image: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            urlEnabled: true,
                            uploadEnabled: true,
                            alignmentEnabled: true,
                            uploadCallback: uploadImageCallBack,
                            previewImage: false,
                            inputAccept:
                              "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                            alt: { present: true, mandatory: true },
                            defaultSize: {
                              height: "auto",
                              width: "auto",
                            },
                          },
                        }}
                        onEditorStateChange={setEditorState}
                      />
                    </div>
                  )}
                  {articleComponent.type === "image" && (
                    <div>
                      <div style={{ padding: 0 }}>
                        <div className={classes.inputTitle}>העלאת תמונה</div>
                        <input
                          className="textinput"
                          type="file"
                          id="single"
                          onChange={async (e) => handleChangeImage(e, i)}
                          style={{ paddingTop: 13, paddingRight: 20 }}
                        />
                      </div>
                      <div style={{ padding: 0 }}>
                        <div className={classes.inputTitle}>קרדיט לתמונה</div>
                        <input
                          className="textinput"
                          onChange={(e) => handleChangeImageTitle(e, i)}
                        />
                      </div>
                    </div>
                  )}
                  {articleComponent.type === "video" && (
                    <div style={{ padding: 0 }}>
                      <div className={classes.inputTitle}>
                        יש להכניס לינק ליוטיוב
                      </div>
                      <input
                        className="textinput"
                        id="single"
                        onChange={(e) => handleChangeVideoLink(e, i)}
                        style={{ paddingTop: 13, paddingRight: 20 }}
                      />
                    </div>
                  )}
                  <Button
                    style={{
                      float: "left",
                      backgroundColor: "white",
                      border: "0px solid #438BEC",
                      borderRadius: 30,
                      marginTop: articleComponent.type === "text" ? -35 : 10,
                      marginLeft: -20,
                      padding: 0,
                    }}
                    onClick={() => deleteElement(i)}
                  >
                    <DeleteIcon />
                  </Button>
                </div>
              ))}
              <Grid item sm={12} md={12} lg={12} xl={12}>
                <center>
                  <Button
                    data-trans="signin-done-button"
                    className={"add-button"}
                    onClick={() => addElement("text")}
                    style={{ margin: 10 }}
                  >
                    הוסף טקסט
                  </Button>
                  <Button
                    data-trans="signin-done-button"
                    className={"add-button"}
                    onClick={() => addElement("image")}
                    style={{ margin: 10 }}
                  >
                    הוסף תמונה
                  </Button>
                  <Button
                    data-trans="signin-done-button"
                    className={"add-button"}
                    onClick={() => addElement("video")}
                    style={{ margin: 10 }}
                  >
                    הוסף וידאו
                  </Button>
                </center>
              </Grid>
              <Grid item sm={12} md={12} lg={12} xl={12}>
                <center>
                  <Button
                    data-trans="signin-done-button"
                    className={"add-button"}
                    onClick={() =>
                      props.main.openPreviewArticle(
                        { components: articleBody },
                        true
                      )
                    }
                    style={{ margin: 10 }}
                  >
                    תצוגה מקדימה
                  </Button>
                </center>
              </Grid>
            </Grid>
            <Grid item sm={12} md={12} lg={12} xl={12}>
              <center>
                <Button
                  data-trans="signin-done-button"
                  className={"done-button"}
                  onClick={() => edit()}
                >
                  {loading ? <CircularProgress size={25} /> : "עדכן כתבה"}
                </Button>
              </center>
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}
