import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from './Table';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function createData(name, phone, email, signinAt, price, status, id) {
  return { name, phone, email, signinAt, status, price, id };
}

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const [registrations, setRegistrations] = React.useState([]);

  React.useEffect(() => {
    // Update the document title using the browser API
    getActivities()
  },[]);

  const getActivities = () => {
    // axios.get('https://jama-server.appspot.com/api/activities/' + props.data.id + '/get-signups').then((response) => {
     axios.get('https://jama-server.appspot.com/api/activities/' + props.id + '/get-signups').then((response) => {
      //  console.log(response.data);
       var users = []
       response.data.forEach(signup => {
         // console.log(signup)
         var signinAt = signup.signedInAt
         if (signinAt === undefined) {
           signinAt = signup.timestamp
         }
         users.push(createData(signup.userData.firstName + ' ' + signup.userData.lastName, signup.userData.phone, signup.userData.email, signinAt, props.activityData.price, signup.status, signup.id))
       });
       setRegistrations(users)
      //  console.log(users)
   })
   .catch( (error) => {
     console.log(error);
   });
 }

  const handleClose = () => {
    props.setOpenTable(false)
  };

  const approveSignup = (id) => {
    axios.post('https://jama-server.appspot.com/api/activities/' + id + '/update', {status: 'approved'}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      getActivities()
    })
    .catch( (error) => {
      console.log(error);
    });
  }

  const cancelSignup = (id) => {
    axios.post('https://jama-server.appspot.com/api/activities/' + id + '/update', {status: 'canceled'}, {headers: {'Content-Type': 'application/json'}}).then((response) => {
      getActivities()
    })
    .catch( (error) => {
      console.log(error);
    });
  }


  return (
    <React.Fragment>
      <Dialog
        fullWidth={'lg'}
        maxWidth={'lg'}
        open={props.openTable}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{textAlign: 'right'}}>הרשמות לפעילות ({registrations.length})</DialogTitle>
        <DialogContent>
          <Table cancelSignup={cancelSignup} approveSignup={approveSignup} tableData={registrations}/>
        </DialogContent>
        <DialogActions style={{width: '100%', textAlign: 'left'}}>
          <Button onClick={handleClose} style={{float: 'left'}} color="primary">
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
