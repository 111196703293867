export default (theme) => ({
    appBar: {
      position: "relative",
      background: "linear-gradient(to right , #438BEC, #57CBF2)",
      boxShadow: "0px 8px 20px -7px rgba(0,0,0,0.30)",
    },
    root: {
      "& .MuiSelect-icon": {
        float: "right",
        direction: "ltr",
      },
      "& .MuiInputLabel-icon": {
        float: "right",
        direction: "ltr",
      },
      "& .MuiSelect-select": {
        direction: "ltr",
        float: "right",
        backgroundColor: "transparent",
      },
      "& MuiSelect-selectMenu": {
        direction: "ltr",
      },
      ".MuiTextField-root": {
        borderSize: 0,
        border: 1,
      },
      "& .MuiAutocomplete-tag": {
        marginLeft: 90,
      },
      deleteIcon: {
        marginLeft: 20,
      },
    },
    content: {
      padding: 30,
    },
    title: {
      fontFamily: "'Varela Round', sans-serif",
      float: "right",
    },
    publish: {
      backgroundColor: "white",
      color: "black",
      padding: 8,
      borderRadius: 5,
    },
    textField: {
      marginTop: 15,
      direction: "ltr",
    },
    pageTitle: {
      textAlign: "center",
      fontFamily: "'Varela Round', sans-serif",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    inputTitle: {
      width: "100%",
      textAlign: "left",
      float: "right",
      color: "rgba(73, 155, 234, 1)",
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: "bold",
      marginTop: 20,
      direction: "ltr",
      marginBottom: 10,
    },
    errorMessage: {
      width: "100%",
      textAlign: "center",
      float: "center",
      color: "red",
      fontFamily: "'Varela Round', sans-serif",
      fontWeight: "bold",
      marginTop: 10,
      direction: "ltr",
      marginBottom: 0,
    },
    formControl: {
      minWidth: 120,
      direction: "ltr",
      width: "100%",
    },
    publishButton: {
      margin: `${theme.spacing(1)}px 0`,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })