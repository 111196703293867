var Settings = {
  colors: {
    dark: {
      gradient1: 'rgb(54,64,74)',
      gradient2: 'rgb(54,64,74)',
      strongText: 'rgb(123,137,152)',
      lightText: 'rgb(169,183,196)',
      textOnBackground: 'rgb(169,183,196)',
      appBar: 'rgb(63,73,84)',
      box: 'rgb(54,64,74)',
      background: 'rgb(52,58,64)',
      title: 'rgb(255,255,255)',
      shadow: '0px 0px 14px 0px rgba(150,150,150,0.0)',
      mainButton: 'rgb(113,124,245)',
      inputText: 'white',
      regularText: 'rgb(169,183,196)'
    },
    light: {
      gradient1: '#ee9ca7',
      gradient2: '#ffdde1',
      strongText: '(171,182,194)',
      lightText: 'white',
      textOnBackground: '#ee9ca7',
      appBar: 'rgb(255,255,255)',
      box: 'rgb(255,255,255)',
      background: 'rgb(250,251,254)',
      title: 'rgb(110,116,124)',
      shadow: '0px 0px 14px 0px rgba(150,150,150,0.2)',
      mainButton: '#ee9ca7',
      inputText: 'black',
      regularText: '#ee9ca7'
    }
  }
}

export {Settings};
