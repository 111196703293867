import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import DeleteIcon from "@material-ui/icons/Delete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Link } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    textAlign: "left",
    boxShadow: "0px 0px 12px -8px rgba(0, 0, 0, 0.5)",
    fontFamily: "'Varela Round', sans-serif",
  },
  media: {
    height: 190,
  },
  registrationsButton: {
    color: "#438BEC",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  deleteButton: {
    color: "red",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  previewButton: {
    float: "left",
    fontFamily: "'Varela Round', sans-serif",
  },
  tag: {
    fontSize: 12,
    height: 25,
    marginTop: 10,
    marginRight: 5,
    fontFamily: "'Varela Round', sans-serif",
  },
  miniTitle: {
    marginTop: 10,
    fontSize: 14,
    textDecorationLine: "underline",
    fontWeight: "bold",
  },
  root: {
    fontFamily: "Varela Round",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
    "& .MuiFormControlLabel-root": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
  },
});

const getDate = (timestamp) => {
  var date = new Date(timestamp * 1000);
  var datevalues = [
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ];
  return (
    datevalues[2] +
    "/" +
    datevalues[1] +
    "/" +
    datevalues[0] +
    " " +
    datevalues[3] +
    ":" +
    datevalues[4]
  );
};

export default function MediaCard(props) {
  const jamaURL = "https://app.jama.co.il/";
  const classes = useStyles();

  const handleChangePromote = (event, field, data) => {
    props.main.updateArticle(data.id, field, event.target.checked);
  };

  const handleChangePublish = (event, field, data) => {
    props.main.updateArticle(data.id, field, event.target.checked);
  };

  const handleSendNotification = (event, field, data) => {
    if (props.data.sendNotification) return;
    props.main.sendNotification(data.id, field, event.target.checked);
  };

  let interestsArray = [];
  let agesArray = [];

  const pushToInterestsArray = (expression) => {
    switch (expression) {
      case 1:
        interestsArray.push("התפתחות הבייבי");
        break;
      case 2:
        interestsArray.push("כושר ותזונה לאמא");
        break;
      case 3:
        interestsArray.push("כיף עם הבייבי");
        break;
      case 4:
        interestsArray.push("תזונת תינוקות");
        break;
      case 5:
        interestsArray.push("להכיר חברות חדשות");
        break;
      case 6:
        interestsArray.push("הנקה");
        break;
      case 7:
        interestsArray.push("הריון ולקראת לידה");
        break;
      case 8:
        interestsArray.push("טיפים וכלים להורים");
        break;
    }
  };

  const pushToAgesArray = (expression) => {
    switch (expression) {
      case 1:
        agesArray.push("כולם");
        break;
      case 2:
        agesArray.push("לאמא ולבייבי");
        break;
      case 3:
        agesArray.push("לאמא");
        break;
      case 4:
        agesArray.push("9-12");
        break;
      case 5:
        agesArray.push("לאמא בהריון");
        break;
      case 6:
        agesArray.push("6-9");
        break;
      case 7:
        agesArray.push("3-6");
        break;
      case 8:
        agesArray.push("12-24");
        break;
      case 9:
        agesArray.push("24+");
        break;
      case 10:
        agesArray.push("0-6");
        break;
      case 11:
        agesArray.push("0-3");
        break;
      case 12:
        agesArray.push("0-12");
        break;
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={props.data.coverImage}
          title={props.data.titleImage}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            {props.data.title}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            {props.data.description}
          </Typography>
          <div className={classes.miniTitle}>
            {getDate(props.data.createdAt._seconds)}
          </div>
          <div className={classes.miniTitle}>תחומי עניין:</div>
          {props.data.relBabyDev ? pushToInterestsArray(1) : null}
          {props.data.relFitNutrition ? pushToInterestsArray(2) : null}
          {props.data.relFunWithBaby ? pushToInterestsArray(3) : null}
          {props.data.relInfantNutrition ? pushToInterestsArray(4) : null}
          {props.data.relMeetNewFriends ? pushToInterestsArray(5) : null}
          {props.data.relNursing ? pushToInterestsArray(6) : null}
          {props.data.relPregnancyBirth ? pushToInterestsArray(7) : null}
          {props.data.relTipsTools ? pushToInterestsArray(8) : null}
          <div>
            {interestsArray.map((title) => (
              <Chip label={title} className={classes.tag} />
            ))}
          </div>
          <div className={classes.miniTitle}>טווחי גילאים:</div>
          {props.data.AFForEveryone ? pushToAgesArray(1) : null}
          {props.data.AFForMomBaby ? pushToAgesArray(2) : null}
          {props.data.AFForMoms ? pushToAgesArray(3) : null}
          {props.data.AFNineToTwelve ? pushToAgesArray(4) : null}
          {props.data.AFPregnancy ? pushToAgesArray(5) : null}
          {props.data.AFSixToNine ? pushToAgesArray(6) : null}
          {props.data.AFThreeToSix ? pushToAgesArray(7) : null}
          {props.data.AFTwelveToTwentyFour ? pushToAgesArray(8) : null}
          {props.data.AFTwentyFourPlus ? pushToAgesArray(9) : null}
          {props.data.AFZeroToSix ? pushToAgesArray(10) : null}
          {props.data.AFZeroToThree ? pushToAgesArray(11) : null}
          {props.data.AFZeroToTwelve ? pushToAgesArray(12) : null}
          {agesArray.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle}>תגיות חופשיות:</div>
          {props.data.openTags.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle}>
            <a href={jamaURL + `post/${props.data.id}`} target="_blank">
              קישור
            </a>
          </div>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.publish}
                  onChange={(e) =>
                    handleChangePublish(e, "publish", props.data)
                  }
                  name="checkedA"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"פרסם"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.promote}
                  onChange={(e) =>
                    handleChangePromote(e, "promote", props.data)
                  }
                  name="checkedA"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"קדם על פני אחרים"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.sendNotification}
                  onChange={(e) =>
                    handleSendNotification(e, "notification", props.data)
                  }
                  name="checkedA"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={
                props.data.sendNotification ? "עידכון נשלח" : "שלח עידכון לכולם"
              }
            />
          </FormGroup>
        </CardContent>
        <CardActions style={{ width: "100%" }}>
          <Button
            size="medium"
            color="primary"
            className={classes.previewButton}
            onClick={() => props.setSelectedArticle(props.data, "preview")}
          >
            תצוגה מקדימה
          </Button>
          <Button
            size="medium"
            color="primary"
            className={classes.registrationsButton}
            onClick={() => props.setSelectedArticle(props.data, "edit")}
          >
            עריכה
          </Button>
          <Button
            size="medium"
            color="primary"
            className={classes.deleteButton}
            onClick={() =>
              props.main.setState({
                openDeleteDialog: true,
                currentArticleId: props.data.id,
              })
            }
          >
            מחיקה
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}
