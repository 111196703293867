// import * as firebase from 'firebase/app';
import * as firebase from "firebase";

import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// const config = {
//   apiKey: "AIzaSyDePypIiHjOD3iXhbd50MC-iEQtn6VmyFc",
//   authDomain: "jama-222314.firebaseapp.com",
//   databaseURL: "https://jama-222314.firebaseio.com",
//   projectId: "jama-222314",
//   storageBucket: "jama-222314.appspot.com",
//   messagingSenderId: "164016518655",
//   appId: "1:164016518655:web:e8cb304492cb888b3423ca"
// };

const config = {
  apiKey: "AIzaSyC5cHtnAtwEB1jDEGgIpxwoP8KyxxrIgrE",
  authDomain: "jamaapplication-44572.firebaseapp.com",
  databaseURL: "https://jamaapplication-44572.firebaseio.com",
  projectId: "jamaapplication-44572",
  storageBucket: "jamaapplication-44572.appspot.com",
  messagingSenderId: "395147255320",
  appId: "1:395147255320:web:5b0e68526dcc0b0e69ad6d",
  measurementId: "G-V5MNF79T8B",
};

firebase.initializeApp(config);

var database = firebase.database();
// console.log(firebase)
export default firebase;
