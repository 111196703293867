import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { Link } from "@material-ui/core";
import EditDialog from "./EditDialog";
import CopyDialog from "./CopyDialog";

const useStyles = makeStyles({
  card: {
    maxWidth: "100%",
    textAlign: "left",
    boxShadow: "0px 0px 12px -8px rgba(0, 0, 0, 0.5)",
    fontFamily: "'Varela Round', sans-serif",
  },
  media: {
    height: 190,
  },
  registrationsButton: {
    color: "#438BEC",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  approveButton: {
    color: "#23CE6B",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  rejectButton: {
    color: "#FF3366",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  deleteButton: {
    color: "red",
    float: "right",
    fontFamily: "'Varela Round', sans-serif",
  },
  previewButton: {
    float: "left",
    fontFamily: "'Varela Round', sans-serif",
  },
  tag: {
    fontSize: 12,
    height: 25,
    marginTop: 10,
    marginRight: 5,
    fontFamily: "'Varela Round', sans-serif",
  },
  miniTitle: {
    marginTop: 10,
    fontSize: 14,
  },
  root: {
    fontFamily: "Varela Round",
    "& .MuiFormControlLabel-label": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
    "& .MuiFormControlLabel-root": {
      fontFamily: "Varela Round",
      fontSize: 14,
    },
  },
});

export default function MediaCard(props) {
  const jamaURL = "https://app.jama.co.il/";
  const classes = useStyles();
  const [openCopy, setOpenCopy] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [isPublish, setIsPublish] = React.useState(props.data.publish);

  const handleChangePromote = (event, field, data) => {
    props.main.updateProduct(data, field, event.target.checked);
  };

  const handleChangePublish = (event, field, data) => {
    console.log("props.data.publish", props.data.publish);
    console.log("publishing", event.target.checked, field, data.id);
    setIsPublish(!isPublish);
    props.main.updateProduct(data, field, event.target.checked);
  };

  const approve = () => {
    props.main.updateProduct(props.data, "status", "approved");
  };

  const reject = () => {
    props.main.updateProduct(props.data.id, "status", "rejected");
  };

  return (
    <div className={classes.root}>
      {openEdit && (
        <EditDialog
          getProducts={props.getProducts}
          openEdit={openEdit}
          setOpenEdit={setOpenEdit}
          id={props.data.id}
          productData={props.data}
          suppliers={props.main.state.options}
        />
      )}
      {openCopy && (
        <CopyDialog
          getProducts={props.getProducts}
          openCopy={openCopy}
          setOpenCopy={setOpenCopy}
          id={props.data.id}
          productData={props.data}
        />
      )}
      <Card className={classes.card}>
        <CardMedia className={classes.media} image={props.data.images[0]} />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontFamily: "'Varela Round', sans-serif" }}
          >
            {props.data.title}
          </Typography>
          <div className={classes.miniTitle}>{props.data.description}</div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            מחיר:
          </div>
          <div className={classes.miniTitle}>{props.data.price} ש״ח</div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            לינק לרכישה:
          </div>
          <div className={classes.miniTitle}>{props.data.link}</div>
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            תחומי עניין:
          </div>
          {props.data.interests.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle} style={{ fontWeight: "bold" }}>
            גילאים:
          </div>
          {props.data.ages.map((title) => (
            <Chip label={title} className={classes.tag} />
          ))}
          <div className={classes.miniTitle}>
            <a href={jamaURL + `products/${props.data.id}`} target="_blank">
              קישור
            </a>
          </div>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={isPublish}
                  onChange={(e) =>
                    handleChangePublish(e, "publish", props.data)
                  }
                  name="publish"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"פרסם"}
            />
          </FormGroup>
          <FormGroup row style={{ marginTop: 10 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={props.data.promote}
                  onChange={(e) =>
                    handleChangePublish(e, "promote", props.data)
                  }
                  name="promote"
                />
              }
              style={{ fontFamily: "Varela Round, sans-serif" }}
              label={"קדם"}
            />
          </FormGroup>
        </CardContent>
        {props.data.status === "approved" ||
        props.data.status === "active" ||
        !props.data.status ? (
          <CardActions style={{ width: "100%" }}>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenCopy(true)}
            >
              שיכפול
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.deleteButton}
              onClick={() =>
                props.main.setState({
                  openDeleteDialog: true,
                  currentProductId: props.data.id,
                })
              }
            >
              מחיקה
            </Button>
          </CardActions>
        ) : (
          <CardActions style={{ width: "100%" }}>
            <Button
              size="medium"
              color="primary"
              className={classes.approveButton}
              onClick={() => approve()}
            >
              אישור
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.rejectButton}
              onClick={() => reject()}
            >
              דחייה
            </Button>
            <Button
              size="medium"
              color="primary"
              className={classes.registrationsButton}
              onClick={() => setOpenEdit(true)}
            >
              עריכה
            </Button>
          </CardActions>
        )}
      </Card>
    </div>
  );
}
