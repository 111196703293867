import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Button from '@material-ui/core/Button';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


import axios from 'axios';


const useStyles = makeStyles(theme => ({

    agreeButton: {
        width: 220,
        height: 30,
        background: 'linear-gradient(180deg, #FCA6BC 0%, #FF8FAC 100%)',
        borderRadius: 7,
        color: '#ffffff',
        fontFamily: 'Varela Round',
        padding: 0,
        margin: '20px auto'
    },
    '@global': {
        'span.MuiRadio-colorSecondary.Mui-checked': {
            color: 'rgb(67, 139, 236)'
        }
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContractPopup(props) {
    const theme = useTheme();
    const classes = useStyles();

    const [value, setValue] = React.useState('');
    const [displayCreditFields, setDisplayFields] = React.useState(false)
    const [errorFirstname, setErrorFirstname] = React.useState(false)
    const [errorLastname, setErrorLastname] = React.useState(false)
    const [errorEmail, setErrorEmail] = React.useState(false)
    const [errorPhone, setErrorPhone] = React.useState(false)
    const [errorLicense_num, setErrorLicense_num] = React.useState(false)
    const [errorBusiness_type, setErrorBusiness_type] = React.useState(false)
    const [state, setState] = React.useState({ firstname: '', lastname: '', email: '', phone: '', license_num: '', business_type: '' });
    const [userData, setUserData] = React.useState(null);
    const [acceptCreditCard, setCreditCard] = React.useState(false);
    const [acceptCash, setCash] = React.useState(false);

    React.useEffect(() => {
        const { userData } = props.main.props
        setState({ ...state, firstname: userData.firstName, lastname: userData.lastName, email: userData.email, phone: userData.phone })
        setUserData(userData)
    }, []);

    const handleChange = (event) => {
        setValue(event.target.value)
        if (event.target.value.includes('2')) {
            setDisplayFields(true)
            setCreditCard(true)
        } else {
            setDisplayFields(false)
            setCreditCard(false)
        }
        if (event.target.value.includes('1')) {
            setCash(true)
        } else {
            setCash(false)
        }
    };

    const handleClose = () => {
        if (!value) return;
        props.handleClose()
    };

    const handleSave = () => {

        var allowToContinue = true
        setErrorFirstname(false)
        setErrorLastname(false)
        setErrorEmail(false)
        setErrorPhone(false)
        setErrorLicense_num(false)
        setErrorBusiness_type(false)
        if (displayCreditFields) {
            if (state.firstname === '') { setErrorFirstname(true); allowToContinue = false }
            if (state.lastname === '') { setErrorLastname(true); allowToContinue = false }
            if (state.email === '') { setErrorEmail(true); allowToContinue = false }
            if (state.phone === '') { setErrorPhone(true); allowToContinue = false }
        }
        if (!allowToContinue) return;
        const data = state;
        if (state.business_type) {
            data.business_type = Number(data.business_type);
        }
        if (!data.license_num) {
            delete data.license_num
        }
        data.methodType = value;

        data.paymentOptions = {
            acceptCreditCard: acceptCreditCard,
            acceptCash: acceptCash,
            meshulamStatus: 'waiting for register',
            meshulamSupplierId: 'some id'
        }
        console.log(data);
        saveDataInDatabase(data);
        // sendToMeshulam(data);
    }

    const saveDataInDatabase = (data) => {
        const userId = userData.id;
        console.log(userId)
        console.log(data)
        delete data.firstname
        delete data.lastname
        delete data.email
        delete data.phone
        console.log(data)


        axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            // axios.post('http://localhost:8080/api/suppliers/' + userId + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            console.log(response.data);
            window.location.reload()
            handleClose();
        })
            .catch((error) => {
                console.log(error);
            });
    }

    const sendToMeshulam = (data) => {
        data.userId = userData.id;
        data.name = data.firstname + ' ' + data.lastname
        console.log(data)

        // axios.post('https://jama-server.appspot.com/api/suppliers/' + userId + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
        axios.post('http://localhost:8080/api/meshulam/createUser', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
            console.log(response.data);
            // window.location.reload()
            // handleClose();
        })
            .catch((error) => {
                console.log(error);
            });
    }


    const handleChangeDropdown = (event) => {
        console.log(event.target.value)
        setState({ ...state, business_type: event.target.value });
    };


    return (
        <div>
            <Dialog
                open={props.main.state.openPaymentMethod}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                fullWidth
                maxWidth={'sm'}
                style={{ direction: "rtl" }}
            >
                <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend" style={{ color: '#000000' }}>אני רוצה לקבל תשלום באמצעות מערכת משולם דרך Jama ישירות אלי (מזומן, אשראי, אפליקציית תשלום וכו')</FormLabel>
                        <RadioGroup name="payment" value={value} onChange={handleChange}>
                            <FormControlLabel value="1" control={<Radio />} label="מזומן" />
                            <FormControlLabel value="2" control={<Radio />} label="אשראי" />
                            <FormControlLabel value="12" control={<Radio />} label="מזומן ואשראי" />
                        </RadioGroup>
                    </FormControl>
                    {displayCreditFields &&
                        <div>
                            <div className={classes.inputTitle}>שם פרטי:</div>
                            <input className="textinput" value={state.firstname} onChange={(e) => setState({ ...state, firstname: e.target.value })} />
                            {errorFirstname && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div className={classes.inputTitle}>שם משפחה:</div>
                            <input className="textinput" value={state.lastname} onChange={(e) => setState({ ...state, lastname: e.target.value })} />
                            {errorLastname && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div className={classes.inputTitle}>אימייל:</div>
                            <input className="textinput" value={state.email} onChange={(e) => setState({ ...state, email: e.target.value })} />
                            {errorEmail && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div className={classes.inputTitle}>מספר טלפון:</div>
                            <input className="textinput" value={state.phone} onChange={(e) => setState({ ...state, phone: e.target.value })} />
                            {errorPhone && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div>
                                <FormControl className={classes.formControl}>
                                    <InputLabel>סוג עסק:</InputLabel>
                                    <Select
                                        onChange={handleChangeDropdown}
                                    >
                                        <MenuItem value={1}>עוסק מורשה</MenuItem>
                                        <MenuItem value={2}>חברה בע"מ</MenuItem>
                                        <MenuItem value={3}>עמותה</MenuItem>
                                        <MenuItem value={4}>עוסק פטור</MenuItem>
                                        <MenuItem value={5}>שותפות</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {errorBusiness_type && (<div className={classes.errorMessage}>שדה חובה</div>)}
                            <div className={classes.inputTitle}>מספר חשבון:</div>
                            <input className="textinput" onChange={(e) => setState({ ...state, license_num: e.target.value })} />
                            {errorLicense_num && (<div className={classes.errorMessage}>שדה חובה</div>)}
                        </div>
                    }
                </DialogContent>
                <DialogActions style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={handleSave} className={classes.agreeButton}>
                        עדכן שינויים
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
