import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import PaymentIcon from "@material-ui/icons/Payment";
import Avatar from "@material-ui/core/Avatar";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FormGroup } from "@material-ui/core";

import axios from "axios";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { DatePicker } from "antd";
import moment from "moment";
import firebase from "../../../Firebase";

import "../../../../node_modules/antd/dist/antd.css";
const names = [
  "הריון ולקראת לידה",
  "הנקה",
  "מוזיקה ותנועה",
  "התפתחות הבייבי",
  "כושר ותזונה לאמא",
  "כיף עם הבייבי",
  "טיפים וכלים להורים",
  "תזונת תינוקות",
  "להכיר חברות חדשות",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ageRangeArray = [
  { label: "0-3 חודשים", value: "0-3" },
  { label: "3-6 חודשים", value: "3-6" },
  { label: "6-9 חודשים", value: "6-9" },
  { label: "9-12 חודשים", value: "9-12" },
  { label: "12-24 חודשים", value: "12-24" },
  { label: "24+ חודשים", value: "24+" },
  { label: "לאמא בהריון", value: "הריון" },
  { label: "לאמא ולבייבי", value: "אמא ובייבי" },
  { label: "לאמא", value: "אמא" },
  { label: "כולם", value: "כולם" },
];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
}));

function getStyles(name, tags, theme) {
  return {
    fontWeight:
      tags.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function createData(name, phone, email, signinAt, price, status, id) {
  return { name, phone, email, signinAt, status, price, id };
}

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  const theme = useTheme();

  const [tags, setTags] = React.useState([]);
  const [errorTags, setErrorTags] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  // const [state, setState] = React.useState({
  //    title: "",
  //    description: "",
  //    items: [],
  //    interests: [],
  //    price: "",
  //    location: "",
  //    images: [],
  //    isLimited: true,
  //    limit: null,
  // });

  const [state, setState] = React.useState({
    title: props.activityData.title,
    description: props.activityData.description,
    items: props.activityData.prices,
    price: props.activityData.price,
    location: props.activityData.location,
    images: props.activityData.images,
    withLimitation: props.activityData.withLimitation,
    limit: props.activityData.limit,
  });

  const [date, setDate] = React.useState(0);
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [formattedDate, setFormattedDate] = React.useState(null);
  const [errorDate, setErrorDate] = React.useState(false);
  const [showFields, setShowFields] = React.useState(true);
  const [itemPrice, setItemPrice] = React.useState("");
  const [itemDescription, setItemDescription] = React.useState("");
  const [newId, setNewId] = React.useState(101);
  const [prices, setPrices] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [errorProducts, setErrorProducts] = React.useState(false);
  const [errorImage, setErrorImage] = React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);
  const [ageRange, setAgeRange] = React.useState([]);
  const [errorLocation, setErrorLocation] = React.useState(false);
  const [errorAgeRange, setErrorAgeRange] = React.useState(false);

  React.useEffect(() => {
    // Update the document title using the browser API
    console.log(props.activityData);
    setState({
      ...state,
      title: props.activityData.title,
      description: props.activityData.description,
      items: props.activityData.prices,
      interests: props.activityData.interests,
      price: props.activityData.price,
      location: props.activityData.location,
      images: props.activityData.images,
      withLimitation: props.activityData.withLimitation,
      limit: props.activityData.limit,
    });
    setFormattedDate(getDate(props.activityData.date));
    setAgeRange(props.activityData.ages);
    setTags(props.activityData.interests);
    if (props.activityData.price) {
      setPrices([{ price: props.activityData.price }]);
      setNewId(newId + 1);
    } else if (props.activityData.items) {
      setPrices(props.activityData.items);
      setNewId(newId + props.activityData.items.length);
    }
  }, []);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const handleShowFields = () => {
    setShowFields(true);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
  };

  const handleChange = (e, v) => {
    setTags(v);
    setState({ ...state, interests: v });
  };

  const addItem = () => {
    var tempPrices = prices;
    tempPrices.push({
      price: itemPrice,
      item: itemDescription,
      id: handleNewId(),
    });
    setPrices(tempPrices);
    setItemPrice("");
    setItemDescription("");
    setShowFields(false);
  };

  const handleNewId = () => {
    setNewId(newId + 1);
    return newId;
  };

  const deleteItemById = (id) => {
    var tempPrices = prices;
    tempPrices = tempPrices.filter((i) => i.id !== id);
    setPrices(tempPrices);
    setUpdate(true);
    if (tempPrices.length === 0) {
      setShowFields(true);
    }
  };

  const handleChangeAgeRange = (event) => {
    setAgeRange(event.target.value);
  };

  const handleChangeCheckbox = (event, pOrN) => {
    if (pOrN === "p") {
      setState({ ...state, [event.target.name]: event.target.checked });
    } else {
      setState({ ...state, [event.target.name]: !event.target.checked });
    }
  };

  const edit = async () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorTags(false);
    setErrorAgeRange(false);
    setErrorDate(false);
    setErrorProducts(false);
    setErrorDescription(false);
    setErrorLocation(false);

    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }
    if (Object.keys(interest).every((k) => !interest[k])) {
      setErrorTags(true);
      allowToContinue = false;
    }
    if (Object.keys(agesNew).every((k) => !agesNew[k])) {
      setErrorAgeRange(true);
      allowToContinue = false;
    }
    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }
    if (prices.length === 0) {
      setErrorProducts(true);
      allowToContinue = false;
    }
    if (state.withDateActivity) {
      if (date === 0) {
        setErrorDate(true);
        allowToContinue = false;
      } else {
      }
    }
    if (!state.onlineActivity) {
      if (state.location === "" || state.location.length < 2) {
        setErrorLocation(true);
        allowToContinue = false;
      } else {
      }
    }

    if (allowToContinue) {
      setLoading(true);
      let data = {
        title: state.title,
        description: state.description,
        items: state.prices,
        price: state.price,
        location: state.location,
        images: state.images,
        withLimitation: state.withLimitation,
        limit: state.limit,

        relNursing,
        relFitNutrition,
        relMeetNewFriends,
        relTipsTools,
        relFunWithBaby,
        relBabyDev,
        relInfantNutrition,
        relPregnancyBirth,

        AFForEveryone,
        AFForMomBaby,
        AFForMoms,
        AFNineToTwelve,
        AFPregnancy,
        AFSixToNine,
        AFThreeToSix,
        AFTwelveToTwentyFour,
        AFTwentyFourPlus,
        AFZeroToSix,
        AFZeroToThree,
        AFZeroToTwelve,
      };
      var tempDate = date;
      var datetime = null;
      if (!props.activityData.withoutDate && date) {
        datetime = tempDate.replace(":0 ", ":00 ");
        console.log(datetime);
        data.date = datetime;
      }
      data.items = prices;
      if (prices) {
        data.price = null;
      }
      if (state.onlineActivity) {
        data.type = "online";
        data.location = "Zoom";
      } else {
        data.type = "frontal";
        data.location = state.location;
        var response = await getCoordinates(state.location);
        if (response !== false) {
          data.locationData = response;
        }
      }

      data.ages = ageRange;

      if (coverImage) {
        var form = new FormData();
        form.append("file", coverImage, coverImage.name);

        axios
          .post(
            "https://jama-server.appspot.com/api/activities/" +
              props.id +
              "/uploadPhoto",
            form,
            {
              headers: {
                "content-type": "multipart/form-data", // do not forget this
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }

      console.log(data);

      axios
        .post(
          "https://jama-server.appspot.com/api/activities/" +
            props.id +
            "/update",
          data,
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((response) => {
          // axios.post('http://localhost:8080/api/activities/' + props.id + '/update', data, { headers: { 'Content-Type': 'application/json' } }).then((response) => {
          setLoading(false);
          props.getActivities();
          props.setOpenEdit(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const onchangeDateTime = (e) => {
    setDate(String(e._d));
    setFormattedDate(moment(e._d));
  };

  const getCoordinates = async (address) => {
    try {
      var link =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        encodeURI(address) +
        "&key=AIzaSyBhR-rD1My1pncnY-GcDBgd28AHcLWVVTY";
      const response = await axios.get(link);
      const resAddress = response.data.results[0];
      console.log(response);
      console.log(resAddress);
      if (response.data.results.length > 0) {
        console.log("yes! Good address!");
        const userAddress = {
          lat: response.data.results[0].geometry.location.lat,
          lng: response.data.results[0].geometry.location.lng,
          formattedAddress: response.data.results[0].formatted_address,
        };
        console.log(userAddress);
        return userAddress;
      } else {
        return false;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getDate = (timestamp) => {
    var date = new Date(timestamp._seconds * 1000);
    return moment(date);
  };

  const formatPrice = (price) => {
    let trimmed = price.trim();
    if (trimmed === "0" || trimmed === "חינם") return "חינם";
    return `${price} שקלים`;
  };

  const onChange = async (e) => {
    const files = Array.from(e.target.files);
    setCoverImage(files[0]);
  };

  const handleChangeLimitCheckbox = (event, pOrN) => {
    if (pOrN === "p") {
      setState({ ...state, [event.target.name]: !event.target.checked });
    } else {
      setState({
        ...state,
        limit: null,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const [interest, setInterest] = React.useState({
    relNursing: false,
    relFitNutrition: false,
    relMeetNewFriends: false,
    relTipsTools: false,
    relFunWithBaby: false,
    relBabyDev: false,
    relInfantNutrition: false,
    relPregnancyBirth: false,
  });

  const {
    relNursing,
    relFitNutrition,
    relMeetNewFriends,
    relTipsTools,
    relFunWithBaby,
    relBabyDev,
    relInfantNutrition,
    relPregnancyBirth,
  } = interest;

  const handleChangeCheckBox = (event) => {
    setInterest({
      ...interest,
      [event.target.name]: event.target.checked,
    });
  };

  ///

  const [agesNew, setAgesNew] = React.useState({
    AFForEveryone: false,
    AFForMomBaby: false,
    AFForMoms: false,
    AFNineToTwelve: false,
    AFPregnancy: false,
    AFSixToNine: false,
    AFThreeToSix: false,
    AFTwelveToTwentyFour: false,
    AFTwentyFourPlus: false,
    AFZeroToSix: false,
    AFZeroToThree: false,
    AFZeroToTwelve: false,
  });

  const {
    AFForEveryone,
    AFForMomBaby,
    AFForMoms,
    AFNineToTwelve,
    AFPregnancy,
    AFSixToNine,
    AFThreeToSix,
    AFTwelveToTwentyFour,
    AFTwentyFourPlus,
    AFZeroToSix,
    AFZeroToThree,
    AFZeroToTwelve,
  } = agesNew;

  const handleChangeCheckBoxAges = (event) => {
    setAgesNew({
      ...agesNew,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={props.openEdit}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right" }}>
          עריכת פעילות
        </DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>העלאת תמונה ראשית:</div>
          <div style={{ direction: "rtl", paddingBottom: 16, fontSize: 12 }}>
            <div>האותיות הקטנות:</div>
            <div>* תמונות איכותיות מתוך הפעילות שלך יתקבלו באהבה!</div>
            <div>* בבקשה ללא טשטושי פנים (זה הרבה פחות מזמין)</div>
            <div>* בבקשה לא לצרף לוגו עם טלפון או מייל</div>
            <div>* אם אין ברשותך תמונות איכותיות צרי קשר ואנחנו נסייע לך</div>
          </div>
          <input
            className="textinput"
            type="file"
            id="single"
            onChange={onChange}
            style={{ paddingTop: 13, paddingRight: 20 }}
          />
          {errorImage && <div className={classes.errorMessage}>שדה חובה</div>}

          <div className={classes.inputTitle}>כותרת</div>
          <input
            className="textinput"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          {errorTitle && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>תיאור הפעילות בקצרה</div>
          <textarea
            className="textarea_box"
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            cols="40"
            rows="5"
          />
          {errorDescription && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <div className={classes.inputTitle}>סוג פעילות</div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={!state.onlineActivity}
                  onChange={(e) => handleChangeCheckbox(e, "מ")}
                  name="onlineActivity"
                />
              }
              label="פעילות פרונטלית"
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={state.onlineActivity}
                  onChange={(e) => handleChangeCheckbox(e, "p")}
                  name="onlineActivity"
                />
              }
              label="פעילות אונליין"
            />
          </div>
          <div className={classes.inputTitle}>
            מיקום: מקום להכניס כתובת | מקום לסמן אם פעילות אונליין
          </div>
          {!state.onlineActivity ? (
            <input
              className="textinput"
              placeholder="כתובת"
              onChange={(e) => setState({ ...state, location: e.target.value })}
              value={state.location}
            />
          ) : (
            <input className="textinput" disabled value="אונליין" />
          )}
          {errorLocation && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <div className={classes.inputTitle}>הגבלת משתמשים</div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={state.withLimitation}
                  onChange={(e) => handleChangeLimitCheckbox(e, "l")}
                  name="withLimitation"
                />
              }
              label="עם הגבלה"
            />
          </div>
          <div style={{ width: "100%" }}>
            <FormControlLabel
              style={{ textAlign: "right", direction: "rtl", float: "right" }}
              control={
                <Checkbox
                  checked={!state.withLimitation}
                  onChange={(e) => handleChangeLimitCheckbox(e, "p")}
                  name="withLimitation"
                />
              }
              label="ללא הגבלה"
            />
          </div>
          <div className={classes.inputTitle}>כמות משתתפות מקסימלי</div>
          {state.withLimitation ? (
            <input
              type="number"
              min="0"
              className="textinput"
              placeholder="מספר משתתפות"
              onChange={(e) => setState({ ...state, limit: e.target.value })}
              value={state.limit ? state.limit : ""}
            />
          ) : (
            <input className="textinput" disabled value="ללא הגבלה" />
          )}
          {errorLocation && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          {!props.activityData.withoutDate && (
            <div>
              <div className={classes.inputTitle}>יום ושעה</div>
              <DatePicker
                renderExtraFooter={() => (
                  <div style={{ height: 0 }}>
                    <div
                      style={{
                        backgroundColor: "white",
                        width: "50%",
                        position: "relative",
                        top: 10,
                        height: 50,
                      }}
                    ></div>
                  </div>
                )}
                minuteStep={15}
                format={"DD/MM/YYYY HH:mm"}
                hideDisabledOptions={true}
                placeholder="יש לבחור תאריך ושעה"
                onChange={onchangeDateTime}
                clearIcon={<div></div>}
                disabledDate={disabledDate}
                popupStyle={{ zIndex: 99999 }}
                showToday={false}
                showNow={false}
                value={formattedDate}
                id="date-and-time"
                disabledHours={() => [0, 1, 2, 3, 4, 5]}
                className="textinput"
                style={{ borderRadius: 25, border: "1px solid #438BEC" }}
                showTime
              />
            </div>
          )}
          {errorDate && <div className={classes.errorMessage}>שדה חובה</div>}

          {showFields && (
            <>
              <div className={classes.inputTitle}>
                מחיר פעילות/שירות: מקום להכניס מחיר | מקום לסמן אם הפעילות חינם
              </div>

              {/* value={state.description}
              onChange=
              {(e) => setState({ ...state, description: e.target.value })} */}

              {console.log(state)}
              <input
                value={itemPrice ? itemPrice : "מחיר"}
                type="text"
                className="textinput"
                onChange={(e) => setItemPrice(e.target.value)}
              />
              <div className={classes.inputTitle}>
                תיאור מחיר (לדוגמה - מחיר ליחיד/לזוג, מחיר למספר מפגשים וכו)
              </div>
              <input
                value={itemDescription}
                type="text"
                className="textinput"
                onChange={(e) => setItemDescription(e.target.value)}
              />
              {prices.length > 0 && (
                <Button
                  className={classes.cancelAddItemButton}
                  style={{ marginTop: 12, marginRight: 12 }}
                  onClick={() => setShowFields(false)}
                >
                  ביטול הוספת מחיר
                </Button>
              )}
              <Button
                className={"done-button"}
                style={{ marginTop: 12 }}
                onClick={() => addItem()}
              >
                הוספת מחיר
              </Button>
            </>
          )}
          <div className={classes.inputTitle}>מחירים שהתווספו</div>
          {prices.length === 0 ? (
            <div style={{ textAlign: "right", width: "100%", fontSize: 18 }}>
              אין מחירים
            </div>
          ) : (
            <div>
              <List
                style={{ direction: "rtl", textAlign: "right", width: "100%" }}
              >
                {prices.map((item, index) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <PaymentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.item}
                      secondary={formatPrice(item.price)}
                    />
                    <IconButton
                      onClick={() => deleteItemById(item.id)}
                      edge="start"
                      aria-label="delete"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </div>
          )}
          {!showFields && (
            <div style={{ height: 80 }}>
              <Button
                className={"done-button"}
                style={{ marginTop: 12, float: "right" }}
                onClick={handleShowFields}
              >
                הוספת מחיר נוסף
              </Button>
            </div>
          )}
          {errorProducts && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}

          <div className={classes.inputTitle}>תחומי עניין</div>
          <FormGroup
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 165,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={relNursing}
                  onChange={handleChangeCheckBox}
                  name="relNursing"
                />
              }
              label="הנקה"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relFitNutrition}
                  onChange={handleChangeCheckBox}
                  name="relFitNutrition"
                />
              }
              label="כושר ותזונה לאמא"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relMeetNewFriends}
                  onChange={handleChangeCheckBox}
                  name="relMeetNewFriends"
                />
              }
              label="להכיר חברות חדשות"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relTipsTools}
                  onChange={handleChangeCheckBox}
                  name="relTipsTools"
                />
              }
              label="טיפים וכלים להורים"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relFunWithBaby}
                  onChange={handleChangeCheckBox}
                  name="relFunWithBaby"
                />
              }
              label="כיף עם הבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relBabyDev}
                  onChange={handleChangeCheckBox}
                  name="relBabyDev"
                />
              }
              label="התפתחות הבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relInfantNutrition}
                  onChange={handleChangeCheckBox}
                  name="relInfantNutrition"
                />
              }
              label="תזונת תינוקות"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={relPregnancyBirth}
                  onChange={handleChangeCheckBox}
                  name="relPregnancyBirth"
                />
              }
              label="הריון ולקראת לידה"
            />
          </FormGroup>
          {errorTags && <div className={classes.errorMessage}>שדה חובה</div>}
          {/* <Autocomplete
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
            multiple
            id="tags-filled"
            options={names.map((option) => option)}
            freeSolo
            value={state.interests}
            onChange={handleChange}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  style={{ padding: 8 }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                className="textinput"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                label=""
                placeholder="הוספת תגיות (ניתן לבחור מהרשימה או להוסיף ידנית אחרים)"
                style={{ minHeight: 55, borderSize: 0, marginTop: -5 }}
              />
            )}
          />
          {errorTags && <div className={classes.errorMessage}>שדה חובה</div>} */}

          <div className={classes.inputTitle}>טווחי גילאים</div>
          <FormGroup
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 165,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
              marginTop: 50,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForEveryone}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForEveryone"
                />
              }
              label="לכולם"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForMomBaby}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForMomBaby"
                />
              }
              label="לאמא ולבייבי"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFForMoms}
                  onChange={handleChangeCheckBoxAges}
                  name="AFForMoms"
                />
              }
              label="לאמא"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFPregnancy}
                  onChange={handleChangeCheckBoxAges}
                  name="AFPregnancy"
                />
              }
              label="לאמא בהריון"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToThree}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToThree"
                />
              }
              label="0-3"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToSix}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToSix"
                />
              }
              label="0-6"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFZeroToTwelve}
                  onChange={handleChangeCheckBoxAges}
                  name="AFZeroToTwelve"
                />
              }
              label="0-12"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFThreeToSix}
                  onChange={handleChangeCheckBoxAges}
                  name="AFThreeToSix"
                />
              }
              label="3-6"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFSixToNine}
                  onChange={handleChangeCheckBoxAges}
                  name="AFSixToNine"
                />
              }
              label="6-9"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFNineToTwelve}
                  onChange={handleChangeCheckBoxAges}
                  name="AFNineToTwelve"
                />
              }
              label="9-12"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFTwelveToTwentyFour}
                  onChange={handleChangeCheckBoxAges}
                  name="AFTwelveToTwentyFour"
                />
              }
              label="12-24"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={AFTwentyFourPlus}
                  onChange={handleChangeCheckBoxAges}
                  name="AFTwentyFourPlus"
                />
              }
              label="24+"
            />
          </FormGroup>
          {errorAgeRange && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}
          {/* <FormControl
            className={classes.formControl}
            style={{
              border: "solid 1px rgba(73, 155, 234, 1)",
              borderRadius: 30,
              height: 55,
              paddingLeft: 20,
              paddingRight: 8,
              paddingTop: 12,
              width: "100%",
            }}
          >
            <Select
              labelId="demo-mutiple-chip-label"
              disableUnderline
              id="demo-mutiple-chip"
              multiple
              value={ageRange}
              onChange={handleChangeAgeRange}
              input={
                <Input
                  id="select-multiple-chip"
                  style={{ direction: "rtl", marginTop: -10, minHeight: 50 }}
                />
              }
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {ageRangeArray.map((step) => (
                <MenuItem
                  key={step.value}
                  value={step.value}
                  style={getStyles(step.value, ageRangeArray, theme)}
                >
                  {step.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errorAgeRange && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )} */}

          <center>
            <Button
              className={"done-button"}
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => edit()}
            >
              {loading ? <CircularProgress size={25} /> : "עריכת פעילות"}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: "100%", textAlign: "left" }}>
          <Button
            onClick={handleClose}
            style={{ float: "left" }}
            color="primary"
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
