import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AlertDialog(props) {

  const handleClose = () => {
    props.main.setState({openDeleteDialog: false})
  };

  return (
    <div>
      <Dialog
        open={props.main.state.openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={'xs'}
        maxWidth={'xs'}
      >
        <DialogTitle id="alert-dialog-title" style={{color: 'red', direction: 'rtl', textAlign: 'right', fontWeight: 'bold'}}>{"מחיקה"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ direction: 'rtl', textAlign: 'right' }}>
            זהירות! לאחר מחיקה לא יהיה ניתן לשחזר
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <center>
            <Button onClick={handleClose} color="primary" autoFocus>
              ביטול
            </Button>
            <Button onClick={() => props.main.deleteActivity()} color="primary" style={{color: 'red', marginRight: 10}}>
              {props.main.state.deleting ? <CircularProgress size={25} /> : 'אני רוצה למחוק'}
            </Button>
          </center>
        </DialogActions>
      </Dialog>
    </div>
  );
}