import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
   table: {
      minWidth: "100%",
      dir: "ltr",
      direction: "ltr",
      textAlign: "right",
   },
});

export default function SimpleTable(props) {
   const classes = useStyles();

   const getDate = (timestamp) => {
      // var date = new Date(timestamp._seconds * 1000)

      var datevalues = [
         timestamp.getFullYear(),
         timestamp.getMonth() + 1,
         timestamp.getDate(),
         timestamp.getHours(),
         timestamp.getMinutes(),
         timestamp.getSeconds(),
      ];
      return datevalues[2] + "/" + datevalues[1] + "/" + datevalues[0] + " " + datevalues[3] + ":" + datevalues[4];
   };

   return (
      <TableContainer component={Paper}>
         <Table className={classes.table} aria-label="simple table">
            <TableHead>
               <TableRow>
                  <TableCell>שם</TableCell>
                  <TableCell align="left">מספר נייד</TableCell>
                  <TableCell align="left">אימייל</TableCell>
                  <TableCell align="left">נרשמה בתאריך</TableCell>
                  <TableCell align="left">מחיר</TableCell>
                  <TableCell align="left">מוצר</TableCell>
                  <TableCell align="left">סטטוס</TableCell>
                  <TableCell align="left">פעולה</TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {props.tableData.map((row) => (
                  <TableRow key={row.name}>
                     <TableCell component="th" scope="row">
                        {row.name}
                     </TableCell>
                     <TableCell align="left">{row.phone}</TableCell>
                     <TableCell align="left">{row.email}</TableCell>
                     <TableCell align="left">{getDate(row.signinAt)}</TableCell>
                     {row.selectedProduct ? (
                        <TableCell align="left">{row.selectedProduct.selectedProductPrice}</TableCell>
                     ) : (
                        <TableCell align="left">{row.price}</TableCell>
                     )}
                     {row.selectedProduct && row.selectedProduct.selectedProductName !== "חינם" ? (
                        <TableCell align="left">{row.selectedProduct.selectedProductName}</TableCell>
                     ) : (
                        <TableCell align="left"></TableCell>
                     )}
                     <TableCell align="left">
                        {row.status === "pending" && <div style={{ color: "orange" }}>{row.status}</div>}
                        {row.status === "approved" && <div style={{ color: "#23CE6B" }}>{row.status}</div>}
                        {row.status === "canceled" && <div style={{ color: "#FF3366" }}>{row.status}</div>}
                     </TableCell>
                     <TableCell align="left">
                        {(row.status === "pending" || row.status === "canceled") && (
                           <Button
                              onClick={() => props.approveSignup(row.id)}
                              style={{
                                 paddingLeft: 14,
                                 paddingRight: 14,
                                 fontSize: 16,
                                 fontWeight: "bold",
                                 backgroundColor: "#23CE6B",
                                 color: "white",
                                 height: 40,
                                 borderRadius: 20,
                              }}
                           >
                              אישור
                           </Button>
                        )}
                        {(row.status === "pending" || row.status === "approved") && (
                           <Button
                              onClick={() => props.cancelSignup(row.id)}
                              style={{
                                 paddingLeft: 14,
                                 paddingRight: 14,
                                 fontSize: 16,
                                 fontWeight: "bold",
                                 backgroundColor: "#FF3366",
                                 color: "white",
                                 height: 40,
                                 marginRight: row.status === "pending" ? 24 : 0,
                                 borderRadius: 20,
                              }}
                           >
                              סירוב
                           </Button>
                        )}
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
      </TableContainer>
   );
}
