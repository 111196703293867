import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import chromeIcon from '../../../Assets/Images/chrome.jpg';
import iphoneIcon from '../../../Assets/Images/iphone.jpg';
import androidIcon from '../../../Assets/Images/android.jpg';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SaveAsApp = ({ isOpen }) => {
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        if (isOpen) setOpen(true);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        // setShowPopup(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            style={{ direction: 'rtl' }}
        >
            <DialogTitle id="alert-dialog-slide-title">{"לנוחיות מיטבית שימרי אותנו כאפליקציה"}</DialogTitle>
            <DialogContent>
                <div>
                    <div style={{ marginTop: 8 }}>
                        <div style={{ color: '#000000', fontSize: 18 }}>למשתמשות באייפון</div>
                        <p style={{ marginTop: 8 }}>
                            1. לחצי על האייקון עם החץ בתחתית המסך
                        </p>
                        <img src={iphoneIcon} height="30px" />
                        <p style={{ marginTop: 8 }}>
                            2. בחרי "הוסף למסך הבית"
                        </p>
                    </div>
                    <div>
                        <div style={{ color: '#000000', fontSize: 18 }}>למשתמשות בכרום</div>
                        <p style={{ marginTop: 8 }}>
                            1. לחצי על האייקון עם שלוש הנקודות בתפריט העליון
                        </p>
                        <img src={chromeIcon} height="30px" />
                        <p style={{ marginTop: 8 }}>
                            2. בחרי "הוסף למסך הבית"
                        </p>
                    </div>
                    <div style={{ marginTop: 8 }}>
                        <div style={{ color: '#000000', fontSize: 18 }}>למשתמשות באנדרואיד</div>
                        <p style={{ marginTop: 8 }}>
                            1. לחצי על האייקון עם שלוש הפסים בתחתית המסך
                        </p>
                        <img src={androidIcon} height="30px" />
                        <p style={{ marginTop: 8 }}>
                            2. לחצי על כפתור "הוסף ל" ולאחר מכן על כפתור "מסך הבית"
                        </p>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    תודה
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SaveAsApp;