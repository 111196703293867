import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import Select from '@material-ui/core/Select';
import Table from './UsersTable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

// Components

import EditUser from './EditDialog';

const mobile = (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1)

const styles = theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  root: {
    width: '100vw',
    height: '100vh',
    fontFamily: "'Varela Round', sans-serif",
  },
  success: { backgroundColor: '#38DFA1', color: 'white', textWeight: 'bold' },
  staticsBox: {
    backgroundColor: 'white',
    minWidth: '100%',
    height: '100%',
    boxShadow: '0px 0px 12px -8px rgba(0, 0, 0, 0.5)',
    borderRadius: 20,
    minHeight: 150,
    paddingTop: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  boxTitle: {
    textAlign: 'left',
    fontSize: '2em'
  },
  number: {
    fontSize: '4em',
    color: '#99a1a9'
  },
  formControl: {
    marginTop: theme.spacing(4),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    minWidth: 120,
    float: 'left',
    textAlign: 'left'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  refreshICon: {
    float: 'right',
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(6.5)
  }
});

class Articles extends React.Component {

  state = {
    isLoading: true,
    users: [],
    sortedUsers: [],
    formattedUsers: [],
    sortBy: '',
    sortData: '',
    selectedUser: null,
    openUserEdit: false
  }

  componentDidMount() {
    this.getUsers()
  }

  handleChangeText = name => event => {
    this.setState({ [name]: event.target.value })
    if (name === 'sortData') { this.sortData(event.target.value) }
  };

  handleChangeSortBy = (event) => {
    this.setState({ sortBy: event.target.value, sortData: '', sortedUsers: this.state.formattedUsers })
  };

  sortData = (text) => {
    if (text === '') {
      this.setState({ sortedUsers: this.state.formattedUsers })
      return
    }
    const sortTypeText = this.state.sortBy
    var sortId = ''
    if (sortTypeText === 'שם') {
      sortId = 'name'
    } else if (sortTypeText === 'אימייל') {
      sortId = 'email'
    } else if (sortTypeText === 'טלפון') {
      sortId = 'phone'
    }
    const data = this.state.formattedUsers
    const sortedArray = []
    for (var i = 0; i < data.length; i++) {
      const field = typeof data[i][sortId] === 'string' ? data[i][sortId].toLowerCase() : toString(data[i][sortId])
      if (field.includes(text.toLowerCase())) {
        sortedArray.push(data[i])
      }
    }
    this.setState({ sortedUsers: sortedArray })
  }

  createData = (profileImage, name, phone, email, status, details) => {
    return { profileImage, name, phone, email, status, details };
  }

  getUsers = () => {
    if (localStorage.getItem('users') === undefined || localStorage.getItem('users') === null) {
      axios.get('https://jama-server.appspot.com/api/users').then((response) => {
        const users = response.data
        var sortedUsers = []
        for (var i = 0; i < users.length; i++) {
          var obj = users[i]
          sortedUsers.push(this.createData(obj.profileImg, obj.firstName + ' ' + obj.lastName, obj.phone, obj.email, obj.status, 'details'))
        }
        this.setState({ 'users': users, 'sortedUsers': sortedUsers, 'formattedUsers': sortedUsers, 'isLoading': false });
        localStorage.setItem("users", JSON.stringify(users));
      })
        .catch((error) => {
          this.setState({ 'isLoading': false });
          console.log(error);
        });
    } else {
      const suppliers = JSON.parse(localStorage.getItem("users"))
      const users = suppliers
      var sortedUsers = []
      for (var i = 0; i < users.length; i++) {
        var obj = users[i]
        sortedUsers.push(this.createData(obj.profileImg, obj.firstName + ' ' + obj.lastName, obj.phone, obj.email, obj.status, 'details'))
      }
      this.setState({ 'users': users, 'sortedUsers': sortedUsers, 'formattedUsers': sortedUsers, 'isLoading': false });
    }
  }

  refreshData = () => {
    this.setState({ isLoading: true })
    axios.get('https://jama-server.appspot.com/api/users').then((response) => {
      const users = response.data
      var sortedUsers = []
      for (var i = 0; i < users.length; i++) {
        var obj = users[i]
        sortedUsers.push(this.createData(obj.profileImg, obj.firstName + ' ' + obj.lastName, obj.phone, obj.email, obj.status, 'details'))
      }
      this.setState({ 'users': users, 'sortedUsers': sortedUsers, 'formattedUsers': sortedUsers, 'isLoading': false });
      localStorage.setItem("users", JSON.stringify(users));
    })
      .catch((error) => {
        this.setState({ 'isLoading': false });
        console.log(error);
      });
  }

  handleSelectedUser = (user) => {
    console.log(user)
    axios.post('https://jama-server.appspot.com/api/users/get-user-by-email', { email: user.email },
    // axios.post('http://localhost:8080/api/users/get-user-by-email', { email: user.email },
      { headers: { 'Content-Type': 'application/json' } }).then((response) => {
        console.log(response.data)
        var users = response.data
        if (users.length > 0) {
          this.setState({ selectedUser: users[0], openUserEdit: true })
        }
      })
  }


  handleCloseEdit = () => {
    this.setState({ selectedUser: null, openUserEdit: false })
  }

  cleanSearch = () => {
    this.setState({ sortData: '', sortBy: '' })
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        {this.state.isLoading === true ? (
          <div className="loading">
            <CircularProgress style={{ marginTop: 'calc(50vh - 50px)' }} size={30} />
            <div><div style={{ fontSize: mobile ? 20 : 15, padding: mobile ? 20 : 0, color: 'black', marginTop: 20, fontWeight: 'bold' }}>מוריד נתונים עדכניים</div></div></div>
        ) : (
            <SnackbarProvider maxSnack={10} classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}>
              <Grid container spacing={mobile ? 2 : 4} alignItems="center">
                <div style={{ width: '100%', marginTop: 0, marginBottom: 0 }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label">מיין לפי</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.sortBy}
                      onChange={this.handleChangeSortBy}
                    >
                      <MenuItem value={'שם'}>שם</MenuItem>
                      <MenuItem value={'טלפון'}>טלפון</MenuItem>
                      <MenuItem value={'אימייל'}>אימייל</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField id="standard-basic" label={this.state.sortBy === '' ? 'בחר ערך' : this.state.sortBy} className={classes.formControl} onChange={this.handleChangeText('sortData')} value={this.state.sortData} />
                  <IconButton aria-label="refresh" className={classes.refreshICon} onClick={() => this.refreshData()}>
                    <CachedIcon />
                  </IconButton>
                </div>
                <Grid item sm={12} md={12} lg={12} xl={12} style={{ width: '100%' }}>
                  <Table main={this} />
                </Grid>
                <EditUser openEdit={this.state.openUserEdit} main={this} selectedUser={this.state.selectedUser} />
              </Grid>
            </SnackbarProvider>
          )}
      </div>
    );
  }
}

Articles.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Articles));
