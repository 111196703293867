import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "fit-content",
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  inputTitle: {
    width: "100%",
    textAlign: "left",
    float: "left",
    color: "rgba(73, 155, 234, 1)",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 20,
    direction: "ltr",
    marginBottom: 10,
  },
  errorMessage: {
    width: "100%",
    textAlign: "center",
    float: "center",
    color: "red",
    fontFamily: "'Varela Round', sans-serif",
    fontWeight: "bold",
    marginTop: 10,
    direction: "ltr",
    marginBottom: 0,
  },
}));

function createData(name, phone, email, signinAt, price, status, id) {
  return { name, phone, email, signinAt, status, price, id };
}

export default function MaxWidthDialog(props) {
  const classes = useStyles();
  //   const [registrations, setRegistrations] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    title: "",
    description: "",
    link: "",
  });
  const [errorTitle, setErrorTitle] = React.useState(false);
  const [errorDescription, setErrorDescription] = React.useState(false);
  const [errorLink, setErrorLink] = React.useState(false);

  console.log(props);

  React.useEffect(() => {
    // Update the document title using the browser API
    setState({
      ...state,
      title: props.videoData.title,
      description: props.videoData.description,
      link: props.videoData.link,
    });
  }, []);

  const handleClose = () => {
    props.setOpenEdit(false);
  };

  const edit = () => {
    var allowToContinue = true;
    setErrorTitle(false);
    setErrorDescription(false);
    setErrorLink(false);

    if (state.description === "") {
      setErrorDescription(true);
      allowToContinue = false;
    }
    if (state.title === "") {
      setErrorTitle(true);
      allowToContinue = false;
    }
    if (state.link === "") {
      setErrorLink(true);
      allowToContinue = false;
    }

    if (allowToContinue) {
      setLoading(true);
      axios
        .post(
          "https://jama-server.appspot.com/api/videos/" +
            props.videoData.id +
            "/update",
          state,
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          // axios.post('http://localhost:8080/api/videos/' + props.videoData.id + '/update', state, {headers: {'Content-Type': 'application/json'}}).then((response) => {
          setLoading(false);
          props.getVideos();
          props.setOpenEdit(false);
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={"sm"}
        maxWidth={"sm"}
        open={props.openEdit}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title" style={{ textAlign: "right" }}>
          עריכת וידאו
        </DialogTitle>
        <DialogContent>
          <div className={classes.inputTitle}>כותרת</div>
          <input
            className="textinput"
            value={state.title}
            onChange={(e) => setState({ ...state, title: e.target.value })}
          />
          {errorTitle && <div className={classes.errorMessage}>שדה חובה</div>}
          <div className={classes.inputTitle}>תיאור הוידאו</div>
          <textarea
            className="textarea_box"
            value={state.description}
            onChange={(e) =>
              setState({ ...state, description: e.target.value })
            }
            cols="40"
            rows="5"
          />
          {errorDescription && (
            <div className={classes.errorMessage}>שדה חובה</div>
          )}
          <div className={classes.inputTitle}>לינק</div>
          <textarea
            className="textinput"
            value={state.link}
            onChange={(e) => setState({ ...state, link: e.target.value })}
            cols="40"
            rows="5"
          />
          {errorLink && <div className={classes.errorMessage}>שדה חובה</div>}
          <center>
            <Button
              data-trans="signin-done-button"
              className={"done-button"}
              style={{ marginTop: 20, marginBottom: 20 }}
              onClick={() => edit()}
            >
              {loading ? <CircularProgress size={25} /> : "עריכת וידאו"}
            </Button>
          </center>
        </DialogContent>
        <DialogActions style={{ width: "100%", textAlign: "left" }}>
          <Button
            onClick={handleClose}
            style={{ float: "left" }}
            color="primary"
          >
            סגור
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
