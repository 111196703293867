import React from "react";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Material
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SnackbarProvider, useSnackbar } from "notistack";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import Zoom from "@material-ui/core/Zoom";
import Fab from "@material-ui/core/Fab";
import Badge from "@material-ui/core/Badge";

// Components
import Activity from "./Activity";
import NewActivity from "./NewActivity";
import Skeleton from "./Skeleton";
import DeleteDialog from "./DeleteDialog";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import InfiniteScroll from "react-infinite-scroller";

const mobile =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;

const styles = (theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  root: {
    width: "100vw",
    height: "100vh",
  },
  success: { backgroundColor: "#38DFA1", color: "white", textWeight: "bold" },
  h2: {
    float: "left",
    textWeight: "bold",
    display: "inline-block",
    textAlign: "left",
    color: "rgb(100,100,100)",
    fontSize: 20,
  },
  NewActivity: {
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    color: "white",
  },
  addButtonContainer: {
    bottom: 40,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 60,
    // left:30
  },
  fab: {
    position: "fixed",
    background: "linear-gradient(to right , #438BEC, #57CBF2)",
    // bottom: theme.spacing(4),
    bottom: 40,
    right: theme.spacing(4),
  },
  openDialogButton: {
    color: "#438BEC",
    cursor: "pointer",
    fontWeight: "bold",
  },
});

class Activities extends React.Component {
  state = {
    isLoading: true,
    openNewActivity: false,
    activities: [],
    openPreviewActivity: false,
    openEditActivity: false,
    openDeleteDialog: false,
    currentActivity: { components: [] },
    currentActivityId: null,
    deleting: false,
    adding: false,
    updating: false,
    previewLocal: false,
    openRegistrations: true,
    options: [],
    alreadyUpdated: null,
    type: 0,
    status: 0,
    activitiesToShow: [],
    activitiesCounter: 0,
    pending: [],
    approved: [],
    rejected: [],
    withDate: [],
    withoutDate: [],
    passed: [],
  };

  handleChangeStatus = (event, newValue) => {
    this.setState({ status: newValue });
    if (newValue === 0) {
      this.setState({ activities: this.state.approved });
    }
    if (newValue === 1) {
      this.setState({ activities: this.state.pending });
    }
    if (newValue === 2) {
      this.setState({ activities: this.state.rejected });
    }
  };

  handleChangeType = (event, newValue) => {
    this.setState({
      type: newValue,
      activitiesToShow: [],
      activitiesCounter: 0,
    });
    if (newValue === 0) {
      this.setState({ activities: this.state.withDate });
    } else if (newValue === 1) {
      this.setState({ activities: this.state.withoutDate });
    } else {
      this.setState({ activities: this.state.passed });
    }
  };

  getActivities = () => {
    var pending = [];
    var approved = [];
    var rejected = [];

    var withDate = [];
    var withoutDate = [];
    var passed = [];

    if (this.state.alreadyUpdated !== null) return;
    var data = { id: this.props.userData.id };

    console.log(data);
    axios
      .post(
        "https://jama-server.appspot.com/api/activities/get-by-supplier",
        data,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        var activities = response.data;
        console.log(activities);

        activities.sort(
          (a, b) =>
            new Date(b.createdAt._seconds * 1000).getTime() -
            new Date(a.createdAt._seconds * 1000).getTime()
        );
        activities.reverse();
        activities.forEach((element) => {
          if (element.status === "rejected") {
            rejected.push(element);
          } else if (element.status === "pending") {
            pending.push(element);
          } else {
            approved.push(element);
          }
        });
        approved.forEach((element) => {
          if (element.withoutDate) {
            withoutDate.push(element);
          } else {
            if (
              new Date().getTime() <=
              new Date(element.date._seconds * 1000).getTime()
            ) {
              withDate.push(element);
            } else {
              passed.push(element);
            }
          }
        });
        passed.reverse();
        this.setState({
          approved: approved,
          rejected: rejected,
          pending: pending,
          activities: withDate,
          withDate: withDate,
          withoutDate: withoutDate,
          passed: passed,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  componentDidMount() {
    this.getActivities();
    this.checkDidFirstUpdate();
  }

  checkDidFirstUpdate = () => {
    if (this.props.history.location && this.props.history.location.state) {
      if (this.props.history.location.state.firstUpdateCompleted) {
        this.setState({ openNewActivity: true });
        this.props.history.push({
          state: {
            firstUpdateCompleted: null,
          },
        });
      }
    }
  };

  openPreviewActivity = (data, local) => {
    this.setState({ currentActivity: data, previewLocal: local }, () => {
      console.log(data);
      this.setState({ openPreviewActivity: true });
    });
  };

  closePreviewActivity = () => {
    this.setState({ openPreviewActivity: false, previewLocal: false });
  };

  deleteActivity = () => {
    this.setState({ deleting: true });
    console.log(this.state.currentActivityId);
    // axios.post('https://jama-server.appspot.com/api/Activities/' + this.state.currentActivityId + '/delete').then((response) => {
    axios
      .post(
        "https://jama-server.appspot.com/api/Activities/" +
          this.state.currentActivityId +
          "/delete"
      )
      .then((response) => {
        console.log(response.data);
        this.setState({ deleting: false, openDeleteDialog: false });
        this.getActivities();
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  updateActivity = (id, field, value) => {
    const activities = this.state.activities;
    activities.forEach((activity, index) => {
      if (activity.id === id) {
        activities[index][field] = value;
        this.setState({ activities: activities });
      }
    });
    axios
      .post(
        "https://jama-server.appspot.com/api/activities/" + id + "/update",
        { [field]: value },
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        console.log(error);
      });
  };

  setSelectedActivity = (data, typeOfAction) => {
    if (typeOfAction === "preview") {
      this.setState({ openPreviewActivity: true, currentActivity: data });
    } else {
      this.setState({ openEditActivity: true, currentActivity: data });
    }
  };

  loadMoreActivities = () => {
    if (!this.state.activitiesToShow) return;
    this.setState({
      activitiesToShow: [
        ...this.state.activitiesToShow,
        ...this.state.activities.slice(
          this.state.activitiesCounter,
          this.state.activitiesCounter + 10
        ),
      ],
      activitiesCounter: this.state.activitiesCounter + 10,
    });
  };

  render() {
    const { classes, theme } = this.props;
    return (
      <div className="root">
        {this.state.openNewActivity && (
          <NewActivity main={this} userData={this.props.userData} />
        )}
        <DeleteDialog main={this} />
        {this.state.isLoading === true ? (
          <div>
            <Skeleton />
          </div>
        ) : (
          <SnackbarProvider
            maxSnack={10}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
          >
            <Paper
              className={classes.root}
              elevation={0}
              style={{
                background: "none",
                marginBottom: 20,
                flexGrow: 1,
                minWidth: 100,
                width: "100%",
                height: 50,
              }}
            >
              <Tabs
                value={this.state.status}
                onChange={this.handleChangeStatus}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab
                  label={
                    <Badge
                      badgeContent={this.state.approved.length}
                      color="primary"
                    >
                      <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                        מאושר
                      </div>
                    </Badge>
                  }
                />
                <Tab
                  label={
                    <Badge
                      badgeContent={this.state.pending.length}
                      color="primary"
                    >
                      <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                        ממתין לאישור
                      </div>
                    </Badge>
                  }
                />
                <Tab
                  label={
                    <Badge
                      badgeContent={this.state.rejected.length}
                      color="primary"
                    >
                      <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                        לא מאושר
                      </div>
                    </Badge>
                  }
                />
              </Tabs>
            </Paper>
            {this.state.status === 0 && (
              <Paper
                className={classes.root}
                elevation={0}
                style={{
                  background: "none",
                  marginBottom: 20,
                  flexGrow: 1,
                  minWidth: 100,
                  width: "100%",
                  height: 50,
                }}
              >
                <Tabs
                  value={this.state.type}
                  onChange={this.handleChangeType}
                  indicatorColor="primary"
                  textColor="primary"
                  centered
                >
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.withDate.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          עם תאריך ושעה
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.withoutDate.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          בתיאום מראש
                        </div>
                      </Badge>
                    }
                  />
                  <Tab
                    label={
                      <Badge
                        badgeContent={this.state.passed.length}
                        color="primary"
                      >
                        <div style={{ paddingLeft: 8, fontWeight: "bold" }}>
                          פעילויות שעבר זמנן
                        </div>
                      </Badge>
                    }
                  />
                </Tabs>
              </Paper>
            )}
            {this.state.activities.length > 0 ? (
              <InfiniteScroll
                pageStart={this.state.activitiesCounter}
                loadMore={this.loadMoreActivities}
                hasMore={
                  this.state.activitiesCounter < this.state.activities.length
                }
                style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
                loader={<div className="loader"></div>}
              >
                <Grid container spacing={mobile ? 2 : 4}>
                  <div style={{ width: "100%" }}></div>

                  {this.state.type === 0 &&
                    this.state.status === 0 &&
                    this.state.activitiesToShow.map((activity, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Activity
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                        />
                      </Grid>
                    ))}
                  {this.state.type === 1 &&
                    this.state.status === 0 &&
                    this.state.activitiesToShow.map((activity, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Activity
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                        />
                      </Grid>
                    ))}
                  {this.state.type === 2 &&
                    this.state.status === 0 &&
                    this.state.activitiesToShow.map((activity, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Activity
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                        />
                      </Grid>
                    ))}

                  {this.state.status === 1 &&
                    this.state.pending.map((activity, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Activity
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                        />
                      </Grid>
                    ))}
                  {this.state.status === 2 &&
                    this.state.rejected.map((activity, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Activity
                          getActivities={this.getActivities}
                          data={activity}
                          setSelectedActivity={this.setSelectedActivity}
                          main={this}
                        />
                      </Grid>
                    ))}
                </Grid>
              </InfiniteScroll>
            ) : (
              <div
                style={{
                  paddingTop: 120,
                  textAlign: "right",
                  maxWidth: 300,
                  margin: "0 auto",
                  fontSize: 18,
                }}
              >
                {`אין פעילויות חדשות :(`}
                <br />
                {`ליצירת פעילות חדשה`}&nbsp;
                <span
                  onClick={() => this.setState({ openNewActivity: true })}
                  className={classes.openDialogButton}
                >
                  לחצי כאן
                </span>
                {`, או על הכפתור הכחול עם הפלוס.`}
              </div>
            )}
          </SnackbarProvider>
        )}
        <div className={classes.addButtonContainer} style={{ left: 30 }}>
          <span style={{ marginLeft: 70 }}>להוספת פעילות חדשה/ שירות חדש</span>
          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() => this.setState({ openNewActivity: true })}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    );
  }
}

Activities.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(withRouter(Activities));
