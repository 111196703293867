import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    inputTitle: {
        width: '100%',
        textAlign: 'left',
        float: 'left',
        color: 'rgba(73, 155, 234, 1)',
        fontFamily: "'Varela Round', sans-serif",
        fontWeight: 'bold',
        marginTop: 20,
        direction: 'ltr',
        marginBottom: 10
    },
}));


export default function MaxWidthDialog(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);

    const handleClose = () => {
        props.setOpenCopy(false)
    };

    const copy = () => {
        setLoading(true)
        var data = props.productData

        delete data.id
        console.log(data)

        // axios.post('http://localhost:8080/api/products/duplicate', data, {
        axios.post('https://jama-server.appspot.com/api/products/duplicate', data, {
            headers: {
                'content-type': 'application/json' // do not forget this 
            }
        }).then((response) => {
            setLoading(false)
            props.getProducts()
            props.setOpenCopy(false)
            console.log(response.data)
        })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        <React.Fragment>
            <Dialog
                fullWidth={'sm'}
                maxWidth={'sm'}
                open={props.openCopy}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
            >
                <DialogTitle id="max-width-dialog-title" style={{ textAlign: 'right' }}>שיכפול מוצר</DialogTitle>
                <DialogContent>
                    <center>
                        <Button data-trans="signin-done-button" className={'done-button'} style={{ marginTop: 20, marginBottom: 20 }} onClick={() => copy()}>
                            {loading ? <CircularProgress size={25} /> : 'פרסום מוצר'}
                        </Button>
                    </center>
                </DialogContent>
                <DialogActions style={{ width: '100%', textAlign: 'left' }}>
                    <Button onClick={handleClose} style={{ float: 'left' }} color="primary">
                        סגור
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
